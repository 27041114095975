<template>
  <spinner :loading="loading" />

  <div id="shopAdmin" class="w-95 m-auto">
    <span style="text-align: center;">
      <h1>Shop Admin</h1>
    </span>


    <b-card no-body>
      <b-tabs card v-on:activate-tab="changeTab">
        <b-tab title="Management" active>
          <!-- Shop Management -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col><b-card-text>상점을 선택하세요.</b-card-text></b-col>
              <b-col><b-form-select v-model="selected" :options="nameState?.options"
                  v-on:change="getShopInfo"></b-form-select></b-col>
            </b-row>
            <b-row>
              <span><b-card-text>진행상태 :

                  <div v-if="formObject.state === 'P'">
                    <h5><b-badge variant="warning">승인대기중..</b-badge></h5>
                  </div>
                  <div v-else-if="formObject.state === 'S'">
                    <h5><b-badge variant="success">승인완료</b-badge></h5>
                  </div>
                  <div v-else-if="formObject.state === 'D'">
                    <h5><b-badge variant="danger">승인반려</b-badge></h5>
                  </div>
                  <div v-else></div>

                </b-card-text>
              </span>
            </b-row>
          </b-container>
          <!-- Shop Management Div -->
          <div v-if="isShopId">

            <b-form>
              <b-form-group id="input-group-1" label="점포명 :" label-for="input-1" ref="shopName"
                description="점표명은 메인에 표기됩니다." valid-feedback="입력 되었습니다." required :state="isValid(formObject.shopName)">

                <b-form-input id="input-1" v-model="formObject.shopName" placeholder="점포명을 입력하세요"
                  :state="isValid(formObject.shopName)"></b-form-input>
              </b-form-group>


              <b-form-group id="input-group-2" label="카테고리" label-for="input-2" description="" valid-feedback="입력 되었습니다."
                required ref="" :state="isValid(formObject.shopCategory)">

                <b-form-select id="input-2" v-model="formObject.shopCategory" placeholder="상점 카테고리를 선택해주세요."
                  :state="isValid(formObject.shopCategory)">
                  <option value="" selected> 선택해주세요.</option>
                  <option value="1">미식</option>
                  <option value="2">카페</option>
                  <option value="3">서비스</option>
                  <option value="4">스포츠</option>
                  <option value="5">숙박</option>
                  <option value="6">여행</option>
                  <option value="7">의료</option>
                  <option value="8">온라인</option>
                  <option value="9">기타</option>
                </b-form-select>
              </b-form-group>

              <b-form-group id="input-group-3" label="점포 주소(실제 지도주소)" label-for="input-3" valid-feedback="입력 되었습니다."
                required :state="isValid(formObject.shopAddress)">
                <div class="d-flex">
                  <b-form-input id="input-3" v-model="formObject.shopAddress" placeholder="점포 주소를 입력하세요"
                    :state="isValid(formObject.shopAddress)" class="flex-grow-1"></b-form-input>
                  <b-button @click="onFindAddr" size="sm">주소찾기</b-button>
                </div>
                <address-vue ref="addressVue" @setAddr="handleAddr" />


              </b-form-group>

              <b-form-group id="input-group-4" label="점포 소재지 :" label-for="input-4">

                <b-form-input id="input-4" v-model="formObject.shopLocation" placeholder="소재지 (예: 여의도) 입력하세요"></b-form-input>
                <b-form-valid-feedback id="input-feedback-2" class="valid-feedback">
                  입력 되었습니다.
                </b-form-valid-feedback>
              </b-form-group>

              <b-form-group id="input-group-5" label="홈페이지 주소 :" label-for="input-5">
                <b-form-input id="input-5" v-model="formObject.urlHomepage"
                  placeholder="공식 매장 홈페이지 주소를 입력하세요"></b-form-input>
              </b-form-group>


              <b-form-group id="input-group-6" label="매장 네이버 URL 주소 :" label-for="input-6">
                <b-form-input id="input-6" v-model="formObject.urlMap" placeholder="매장 네이버 URL 주소를 입력하세요"></b-form-input>
              </b-form-group>

              <div>
                <label for="tags-separators">NFT 지원태그 </label>
                <b-form-tags input-id="tags-separators" v-model="hashValue" separator=" ,;"
                  placeholder="태그를 입력후 스페이스 또는 콤마, 세미콜론을 입력하면 추가됩니다." no-add-on-enter></b-form-tags>
              </div>


              <b-form-group id="input-group-8" label="매장 대표메뉴 :" label-for="input-8" valid-feedback="입력 되었습니다." required>
                <b-form-input id="input-8" v-model="formObject.itemName"
                  placeholder="예) 아메리카노, 리버 뷰 숙박권, 한식 반상, 헤어 펌, 헬스 등록권 등"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-9" label="대표메뉴 가격" label-for="input-9" valid-feedback="입력 되었습니다.">
                <b-form-input id="input-9" v-model="formObject.itemPrice" placeholder="예) 4500"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-10" label="대표메뉴 할인율" label-for="input-10" valid-feedback="입력 되었습니다.">
                <b-form-input id="input-10" v-model="formObject.itemDiscount"
                  placeholder="예) 10% -> 10 입력"></b-form-input>
              </b-form-group>


              <div>
                지원하는 NFT컨트랙 주소 (최대 3개 입력 가능)
                <div class="form-floating">
                  <input type="email" class="form-control" id="contract1" placeholder="" v-model="formObject.shopNft1">
                  <label for="floatingInput">NFT Contract #1</label>
                </div>
              </div>
              <p></p>
              <div>
                <div class="form-floating">
                  <input type="email" class="form-control" id="contract2" placeholder="" v-model="formObject.shopNft2">
                  <label for="floatingInput">NFT Contract #2</label>
                </div>
              </div>

              <p></p>


              <div>
                <div class="form-floating">
                  <input type="email" class="form-control" id="contract3" placeholder="" v-model="formObject.shopNft3">
                  <label for="floatingInput">NFT Contract #3</label>
                </div>
              </div>

              <p></p>

              <b-form-group id="input-group-6" label="대표 이미지 :" label-for="input-6">
                <span></span>

                <b-img class="uploadimg" thumbnail fluid v-bind:src="thumbSrc" alt="Fluid image" id="thumbImg" ></b-img>
                <input class="form-control" type="file" id="formFileMultiple"
                  @change.prevent="handleFileChange($event)" />
                <div class="valid-feedback" v-if="editorInfo.isThumbUploaded">
                  썸네일 업로드 완료!!
                </div>
                <p v-if="editorInfo.isThumbUploaded" style="color: red;">썸네일 업로드 완료!!</p>

              </b-form-group>
            </b-form>

            <ckeditor :editor="editor" v-model="editorData" @ready="onReady" :config="config" name="testCK">
            </ckeditor>
            <div style="text-align: center;">
              <b-button @click.once="updateShopInfo" variant="info">수정하기</b-button>
              <b-button @click.once="deleteShopInfo" variant="danger">삭제하기</b-button>
            </div>

          </div>
          <!-- if no shop -->
          <div v-else class="d-flex justify-content-center">
            <b-card-text><h3>선택된 상점이 없습니다.</h3></b-card-text>
          </div>
          <!-- ENDOF Shop admin  -->
        </b-tab>

        <b-tab title="Activity">
          <!-- Benefit Management -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col><b-card-text>상점을 선택하세요.</b-card-text></b-col>
              <b-col><b-form-select v-model="selected" :options="nameState?.options"
                  v-on:change="getActivityInfo"></b-form-select></b-col>
            </b-row>
          </b-container>
          <!-- Begin Shop Activity  -->
          <div v-if="isActivityId">
            <b-table striped hover :items="tbItems" style="font-size: 8px;" caption-top>
              <template #table-caption>상점별 NFT 사용자</template>
            </b-table>
          </div>
          <!-- if no isActivityId -->
          <div v-else class="d-flex justify-content-center">
            <b-card-text><h3>선택된 상점이 없습니다.</h3></b-card-text>
          </div>
          <!-- ENDOF Shop Activity  -->
        </b-tab>

      </b-tabs>
    </b-card>

  </div>
  <!-- End of shopAdmin-->
</template>


<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';
import UploadAdapter from '../common/UploadAdapter';
import AddressVue from "../components/kakaoAddress.vue";
import useAxiosPrivate from "../common/AxiosPrivate";
import router from "../router/index";
import { mapState } from 'vuex';
import { reactive } from "vue";
import logo from "@/assets/no-pictures.png";
import noShopImg from "@/assets/ChooseYourShop.png";

const sampleText = '<p>1. 특전 : <br>2. 이용조건 : <br> 3. 사용가능 횟수 : </p>';

export default {
  name: 'app',
  components: { AddressVue , 'ckeditor': CKEditor.component },
  data() {
    return {
      editor: ClassicEditor,
      editorData: sampleText,
      config: {
        //language: 'ko',
        toolbar: {
          items: [
            'heading',
            '|',
            'fontFamily',
            'fontColor',
            'fontSize',
            '|',
            'bold',
            'italic',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            //'todoList',
            'link',
            'blockQuote',
            'imageUpload',
            'insertTable',
            '|',
            'undo',
            'redo'
          ]
        },
        image: {
          resize: true,
          toolbar: ['imageStyle:alignLeft', 'imageStyle:alignRight', 'imageStyle:inline', 'imageStyle:side']
        },
      },

      extraPlugins: [UploadAdapter],
      // file upload
      editorInfo: {
        isThumbUploaded: false
      },
      hashValue: []
      ,
      // form upload
      formObject: {
        shopId: ''
        , email: ''
        , shopCategory: ''
        , shopName: ''
        , shopContent: ''
        , shopLocation: ''
        , shopAddress: ''
        , urlHomepage: ''
        , urlMapl: ''
        , thumbnailMain: ''
        , thumbnailSub: ''
        , itemName: ''
        , itemPrice: ''
        , itemDiscount: ''
        , shopHashtag: ''
        , inppId: ''
        , shopNft1: ''
        , shopNft2: ''
        , shopNft3: ''
        , shopNft4: ''
        , shopNft5: ''
        , state: ''
      }
      , isShopId: false
      , isActivityId: false
      , selectedId: ''
      , thumbSrc: logo
      , noShopSrc: noShopImg
      // end of form upload
      // selector
      , selected: null
      // table
      , tbItems: [
        // { 사용자: '', nftContract: '', 사용NFT: '', 사용일자: '' }
        { 사용자: '', 사용NFT: '', 사용일자: '' }
      ]
    }
  },

  // computed
  computed: {
    state() {
      return this.formObject?.shopName?.length > 0
    },
    invalidFeedback() {
      if (this.formObject.shopName.length > 0) {
        return "감사합니다"
      } else {
        return "점포명을 입력해주세요"
      }
    },
    ...mapState(['loading'])
  }
  ,
  // shop Id 조회
  setup() {

    const url = '/data/shopNameList';
    const nameState = reactive({
      options: [
        { value: null, text: '상점을 선택해주세요.' }
      ]
    })

    useAxiosPrivate.get(url)
      .then((resonse) => {

        nameState.options = [
          ...nameState.options,
          ...resonse.data.map(item => ({ value: item.shopId, text: item.shopName }))
        ];

      })
      .catch((error) => {
        const errorObj = JSON.parse(error.message);
        console.log(errorObj);
        if (errorObj?.status === 403) {
          alert('상점 관리 권한이 없습니다. 관리자에게 문의하세요.');
        } else {
          alert('ShopAdmin은 로그인이 필요합니다.');
        }

        // home으로 이동
        router.push('/');

      })

    return { nameState }
  },
  methods: {
    onReady(editor) {
      // UploadAdapter 생성자호출
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      }
    },

    /**************************************************
     * 사용자 이벤트 함수 ( 버튼클릭, 탭 이동 등)
     *************************************************/
    //clear display
    clear() {
      this.editorData = '';
      this.hashValue = [];
      this.tbItems = [];
      this.formObject = {};
    },
    // find addr
    onFindAddr() {
      this.$refs.addressVue.isOpenEvent();
    },
    // set address
    handleAddr(addrObj) {
      console.log(addrObj);
      this.formObject.shopAddress = `${addrObj.roadAddress} ${addrObj.buildingName}`;
    },

    // 탭 이동
    changeTab(event) {
      this.selected = null;
      this.isShopId = false;
      this.formObject.state = '';
    },
    // select박스 선택 (activity)
    async getActivityInfo(event) {
      // clearData
      this.clear();

      if (event !== null) {
        try {
          const url = '/data/shopActivities/' + event;
          this.selectedId = event;
          const response = await useAxiosPrivate.get(url);

          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              let data = response.data[i];
              this.tbItems.push({
                사용자: data.email,
                //nftContract: data.nftContract,
                사용NFT: data.nftTokenId,
                사용일자: data.createdAt
              });
            }
          }
          // set others
          this.isActivityId = true;

        } catch (error) {
          console.error(error);
        }
      }else{
        this.isActivityId = false;

      }
    },
    // select박스 선택 (shop데이터)
    getShopInfo(event) {
      // clearData
      this.clear();

      if (event !== null) {
        const url = '/data/shopInfo/' + event;
        this.selectedId = event;

        useAxiosPrivate.get(url)
          .then(response => {
            // clearData
            this.clear();
            // set formObject
            this.formObject = response.data;
            this.editorData = response.data.shopContent;
            this.thumbSrc = response.data.thumbnailMain;
            // set others
            this.isShopId = true;

            if (response.data.shopHashtag) {
              const temp = response.data.shopHashtag.split(", ");
              this.hashValue = temp.map(str => str.slice(1));
            }


          })
          .catch(error => {
            console.error(error);
          })
      } else {
        // set others
        this.isShopId = false;
      }
    },
    // 상점정보 업데이트
    updateShopInfo() {
      const url = '/data/shopInfo/' + this.selectedId;
      const shopContent = this.editorData;
      const shopObject = this.formObject;
      const prefix = '#';
      // set object
      shopObject.shopContent = shopContent;
      if (this.hashValue.length === 1) {
        shopObject.shopHashtag = prefix + this.hashValue.join("");
      } else if (this.hashValue.length > 1) {
        let tempArr = this.hashValue.map(item => prefix + item);
        shopObject.shopHashtag = tempArr.join(", ");
      } else {
        shopObject.shopHashtag = this.hashValue.join("");
      }

      useAxiosPrivate.put(url, shopObject, { headers: { "Content-Type": 'application/json' } })
        .then(function (response) {
          alert('수정 되었습니다.');
          // 새로고침
          window.location.reload();

        }).catch(function (error) {
          alert('수정중 에러가 발생했습니다.');
          console.log(error);
        })
    },
    // 상점정보 삭제
    deleteShopInfo() {
      const url = '/data/shopInfo/' + this.selectedId;
      const shopObject = new FormData();

      useAxiosPrivate.delete(url, { headers: { "Content-Type": 'application/json' } })
        .then(function (response) {
          alert('삭제 되었습니다.');
          // 새로고침
          window.location.reload();

        }).catch(function (error) {
          alert('삭제중 에러가 발생했습니다.');
          console.error(error);
        })
    },

    /**************************************************
     * 기타 함수 ( 파일업로드 등)
     *************************************************/
    // 썸네일 파일 업로드 이벤트
    handleFileChange(event) {
      event.preventDefault;
      const url = '/data/uploadThumbFile';
      if (event.target.files && event.target.files[0]) {
        // 썸네일 이미지 검증 후, 서버 전송
        const file = event.target.files[0],
          fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          fileExtention = file.name.split(".").pop(),
          fileName = file.name.split(".").shift(),
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

        console.log(fileSize, fileExtention, fileName, isImage);

        if( !isImage) {
          alert('이미지 파일이 아닙니다.');
          return false;
        }

        if (fileSize > 3 ){
          alert('파일사이즈가 너무 큽니다. (파일 사이즈 제한: 3MB) \n 현 파일 사이즈 : '
              + fileSize + " MB");
          return false;
        }else {
          // update thumb file
          const form = new FormData();

          form.append("file", file);
          useAxiosPrivate.post(url, form, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((res) => {

            this.editorInfo.isThumbUploaded = true;
            this.formObject.thumbnailMain = res.data[0].downloadUrl;
            this.thumbSrc = res.data[0].downloadUrl;

            console.log(res.data[0].downloadUrl);

          }).catch((error) => {
            this.editorInfo.isThumbUploaded = false;
            console.log(error);
          })

        }
      }

    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {

        return 'This field is required';
      }

      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return 'This field must be a valid email';
      }

      // All is good
      return true;
    },

    isValid(value) {
      return value?.length > 0;
    }


  }// end of method

}
</script>

<style>
#shopAdmin {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  width: 80%;
  color: #2c3e50;
  padding-left: 0%;
}

@media only screen and (max-width: 600px) {
  #shopAdmin {
    width: 95%;
  }
  .uploadimg{
    width: 100%;
  }
}

.img-thumbnail {
  width: 100%;
  height: auto;
}
.ck-editor__editable_inline {
  min-height: 200px
}
</style>