import axios from 'axios';
import { useCookies } from 'vue3-cookies'
import router from "@/router";
import store from "@/scripts/store";
import jwt_decode from 'jwt-decode';

/**
 *  AxiosInterceptors
 *  toekn 처리를 위한 axios interceptor 별도구현
 *  == 23.01.29 ==
 *  for no role api
 *
 */

//api객체로 관리
const instance = axios.create({
    // baseURL: "http://localhost:8081/",
    baseURL: process.env.VUE_APP_BASE_URL,

    headers: {
        "content-type": "application/json;charset=UTF-8"
    },
    timeout: 10000,
    withCredentials: true
});
//use cookie
useCookies.set
const { cookies } = useCookies();


// REQUEST
// accessToken 쿠키에 존재한다면 header에 세팅
instance.interceptors.request.use((config) => {
    console.log('=== begin AxiosInterceptors request');
    console.log(cookies);


    store.commit('setLoading', true) // loading 값을 true로 설정
    // jwt expired 되었을시 logout
    if(!getTokenExpireDate()){
        store.dispatch('storeLogout');
    }

    //  setting cookie hedaer & store
    if (!cookies.get('x-auth-token') && !cookies.get('oauth-email')) {
        return config;
    } else {
        config.headers['x-auth-token'] = cookies.get('x-auth-token');
        //store.dispatch('storeLogin', cookies.get('oauth-email'));
        return config;
    }

}, async (error) => {
    // 2xx 외 에러 리턴
    store.commit('setLoading', false) // loading 값을 false로 설정
    return Promise.reject(error);
});

//응답
instance.interceptors.response.use((response) => {
    console.log(response);
    // 2xx 범위 정상 리턴
    // 응답정상일경우+로그인페이지만 쿠키 설정
    if (response.status === 200) {
        if (response.config.url === '/user/login') {
            console.log('login pass====');
            console.log('login pass====');
            const authToken = response.headers['x-auth-token'];
            if( authToken ){
                //keyName ,value, expireTimes, path, secure, sameSiteg
                cookies.set('x-auth-token', response.headers['x-auth-token'], "1d", "/", ".theholderz.com", true, "None");
            }
        }
    }
    store.commit('setLoading', false) // loading 값을 false로 설정
    return response;

}, async (error) => {
    // 2xx 외 에러 리턴
    store.commit('setLoading', false) // loading 값을 false로 설정
    return Promise.reject(error);
});


// 토큰 만료일자를 가져와서 체크
const getTokenExpireDate = () => {
    try {
        const tokenExpireDateString = cookies?.get('x-auth-token').replace('Bearer ', '');
        let decodedJwt, expireDate = '';
        if (tokenExpireDateString) {
            decodedJwt = jwt_decode(tokenExpireDateString);
            expireDate = new Date(decodedJwt.exp * 1000);
        }

        console?.log(expireDate);

        return new Date() < expireDate ? true : false;
    } catch (error) {
        //console?.log(error);
        //alert('다시 로그인 부탁 드립니다 :) ' + error.message);
    }
}

export default instance;
