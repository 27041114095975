<template>
    <div id="adminNftList" class="w-100 m-auto" >
      <!-- <div class="d-flex justify-content-center"> -->
        <b-row class="align-items-center justify-content-center" style="margin-left: 10px;">
          <b-col cols="2" class="align-items-start" align-self="start">
            <div class="mb-3">
                <label for="nft-name-input" class="form-label">NFT EngName</label>
                <input type="text" class="form-control" id="nft-name-input" v-model="newNftName" />
              </div>
              <div class="mb-3">
                <label for="nft-address-input" class="form-label">NFT KorName</label>
                <input type="text" class="form-control" id="nft-address-input" v-model="newNftKorName" />
              </div>
              <div class="mb-3">
                <label for="nft-address-input" class="form-label">NFT Address</label>
                <input type="text" class="form-control" id="nft-address-input" v-model="newNftAddress" />
              </div>
              <div class="d-grid">
                <button class="btn btn-primary" type="button" @click="addNft">Add NFT</button>
                <button class="btn btn-danger" type="button" @click="saveNfts">Save</button>
              </div>
              <p></p>
              <div class="mb-3" v-if="showEditForm" >
                  <label for="nft-name-input" class="form-label">NFT EngName</label>
                  <input type="text" class="form-control" id="nft-name-input" v-model="selectedNft.nftEng" />
                </div>
                <div class="mb-3" v-if="showEditForm" >
                  <label for="nft-address-input" class="form-label">NFT KorName</label>
                  <input type="text" class="form-control" id="nft-address-input" v-model="selectedNft.nftKor" />
                </div>
                <div class="mb-3" v-if="showEditForm">
                  <label for="nft-address-input" class="form-label">NFT Address</label>
                  <input type="text" class="form-control" id="nft-address-input" v-model="selectedNft.nftAddr" />
                </div>
                <div class="d-grid" v-if="showEditForm">
                  <button class="btn btn-primary" type="button" @click="updateNft">Update NFT</button>
                  <button class="btn btn-secondary" type="button" @click="cancelEdit">Cancel</button>
                </div>
            </b-col>
            <b-col cols="10" align-self="center">
              <div class="col-sm-10">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>NFT EngName</th>
                    <th>NFT KorName</th>
                    <th>NFT Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(nft, index) in nftList" :key="nft.id">
                    <td>{{ nft.nftEng }}</td>
                    <td>{{ nft.nftKor }}</td>
                    <td>{{ nft.nftAddr }}</td>
                    <td>
                      <button type="button" class="btn btn-sm btn-primary" @click="editNft(index)">Edit</button>
                      <button type="button" class="btn btn-sm btn-danger" @click="deleteNft(index)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </b-col>
          </b-row>
    </div>
  </template>


<script>
import useAxiosPrivate from "../common/AxiosPrivate";


export default {
  data() {
    return {
      nftList: [ ],
      newNftName: "",
      newNftKorName: "",
      newNftAddress: "",
      imageUrl: "",
      selectedNft: { nftEng: "", nftKor: "", nftAddr: "", imageUrl: "" },
      showEditForm: false
    };
  },
  methods: {
    /**************************************************
    * 서버 이벤트 함수
    *************************************************/
    // NFT 목록 조회
    getNftList() {
      useAxiosPrivate.get('/data/nftlist')
        .then((response) => {
          console.log('getNftList');
          console.log(response);
          this.nftList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // NFT 목록 저장
    saveNfts() {
      useAxiosPrivate.post('/data/nftlist', this.nftList)
        .then((response) => {
          alert('저장이 완료 되었다!');
          console.log(response);
        })
        .catch((error) => {
          alert('ERROR!');
          console.log(error);
        });
    },

    /**************************************************
    * 버튼 이벤트 함수
    *************************************************/
    // NFT 추가
    addNft() {
      if( this.newNftName === "" || this.newNftKorName === "" ) {
        alert("NFT 정보를 입력해주세요.");
        return;
      }
      const newNft = {
        nftEng: this.newNftName,
        nftKor: this.newNftKorName,
        nftAddr: this.newNftAddress,
        imageUrl: ''
      };
        this.nftList.push(newNft);
        this.newNftName     = "";
        this.newNftKorName  = "";
        this.newNftAddress  = "";
        this.imageUrl       = "";

    },
    deleteNft(index) {
      if (confirm("정말 삭제하시겠습니까?")) {
        let nftEngName = "";
        if( this.nftList[index].nftEng === "" || this.nftList[index].nftKor === "" ) {
          alert("NFT 정보를 입력해주세요.");
          return;
        }else{
          nftEngName =  this.nftList[index].nftEng;
        }

        useAxiosPrivate.delete('/data/nftlist?nftEngName='+nftEngName)
        .then((response) => {
          alert('삭제가 완료 되었다!');
          this.nftList.splice(index, 1);
          console.log(response);
        })
        .catch((error) => {
          alert('ERROR!');
          console.log(error);
        });

      }
    },
    editNft(index) {
      this.selectedNft = { ...this.nftList[index] };
      this.showEditForm = true;
    },
    updateNft() {
      const index = this.nftList.findIndex((nft) => nft.nftEng === this.selectedNft.nftEng);
      if (index >= 0) {
        this.nftList[index].nftAddr = this.selectedNft.nftAddr;
        this.nftList[index].nftKor = this.selectedNft.nftKor;
        this.showEditForm = false;
      }
    }
  },
  // 컴포넌트가 생성되자마자 실행되는 함수
  mounted() {
    this.getNftList();
  }
};
</script>

<style scoped>
  #adminNftList {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: left;
      width: 1200px;
      color: #2c3e50;
      padding-left: 0%;
  }

  </style>