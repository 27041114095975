<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col class="mx-auto">
          <b-card>
            <b-card-header class="text-center font-weight-bold">
              ID / Password 찾기
            </b-card-header>
            <b-card-body>
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="email">Email 입력해주세요.</label>
                  <input type="email" class="form-control" id="email" v-model="email" @input="resetFlags" required>
                </div>
                <span>
                  <p></p>
                <button type="submit" class="btn btn-primary btn-block">확인</button>
                  </span>
              </form>
<!--              foundUser {{ foundUser }}-->
              <div v-show="foundUser && isSubmitted" class="text-center mt-3">
                <PRE> {{ this.email }} 회원정보를 찾았습니다. </PRE>
                <P></P>
                <p>
                  <button @click="resetPassword" class="btn btn-danger" :disabled="isButtonDisabled">비밀번호 변경(찾기)</button>
                </p>
              </div>

              <div v-show="!foundUser && isSubmitted" class="text-center mt-3">
                {{ this.email }} 아이디는 정보가 존재 하지 않습니다.
              </div>
              <div v-if="resetPasswordSuccess" class="text-center mt-3">
                {{ this.email }} 비밀번호 찾기 요청이 전송되었습니다.
              </div>
              <div v-if="resetPasswordError" class="text-center mt-3">
                비밀번호 찾기 요청 전송 중 에러가 발생하였습니다.
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "../common/AxiosInterceptors";

export default {
  data() {
    return {
      email: '',
      foundUser: false,
      resetPasswordSuccess: false,
      resetPasswordError: false,
      isSubmitted: false,
      isButtonDisabled: false, // 버튼의 활성/비활성 상태를 나타내는 변수
      buttonDisableTimeout: null, // 버튼 비활성화 해제를 위한 타임아웃을 관리하는 변수
    }
  },
  methods: {
    created() {
      this.email = '';
    },
    resetFlags() {
      this.isSubmitted = false;
      this.foundUser = false;
    },
    submitForm() {
      this.isSubmitted = true;
      // 이메일 주소 유효성 검사 등의 로직 구현
      // 이메일 주소가 유효하다면, 백엔드 API 호출 등의 로직 구현
      axios.get(`/user/check-email?email=${this.email}`)
          .then(response => {
            // 응답 받은 데이터를 처리하는 로직 구현
            if (response.data) {
              // 아이디 찾기 성공 시 foundUser 변수를 true로 설정
              this.foundUser = true;
            } else {
              // 아이디 찾기 실패 시 foundUser 변수를 false로 설정
              this.foundUser = false;
            }
          })
          .catch(error => {
            // 에러 처리 로직 구현
            console.error(error);
          });
    },
    sendPasswordResetEmail() {
      // const requestData = {email: this.email};
      axios.get(`/user/send-password-reset-email?email=${this.email}`)
          .then(response => {
            alert('비밀번호 찾기 이메일을 발송했습니다. 이메일을 확인해주세요.');

          })
          .catch(error => {
            console.error(error);
            alert('비밀번호 찾기 이메일 발송에 실패했습니다. 잠시 후 다시 시도해주세요.');
          })
          .finally(() => {
            // 타임아웃이 남아있는 경우에는 취소
            if (this.buttonDisableTimeout) {
              clearTimeout(this.buttonDisableTimeout);
              this.buttonDisableTimeout = null;
            }

            // 버튼 활성화
            this.isButtonDisabled = false;
          });
    },
    resetPassword() {
      if (this.isButtonDisabled) {
        return; // 버튼이 이미 비활성화된 상태인 경우에는 중복 클릭을 막습니다.
      }

      // 버튼 비활성화
      this.isButtonDisabled = true;

      // 타임아웃 설정
      this.buttonDisableTimeout = setTimeout(() => {
        // 버튼 활성화
        this.isButtonDisabled = false;
        this.buttonDisableTimeout = null;
      }, 10000); // 원하는 시간(밀리초) 설정

      // 비밀번호 찾기 요청을 보냅니다.
      this.sendPasswordResetEmail();
      // 또는 비밀번호 찾기 페이지로 이동합니다.
      // this.goToPasswordReset();
    },
    goToPasswordReset() {
      // 이메일을 비밀번호 찾기 페이지로 전달
      this.$router.push({ name: 'password-reset', params: { email: this.email } });
    },
  }
}
</script>