<template>


  <div class="wrap w-100">
    <div class="row">
      <div class="col" v-for="(item, idx) in nftData" :key="idx">
        <div>
          <p class="text-center"> {{ item.name }} </p>
<!--          {{ item.contractAddresses }}-->
<!--          {{ item.imgUrl }}-->
        </div>
        <div>
          <img :src="getImageUrl(item.imgUrl)" class="rounded-4" style="width: 120px; cursor:pointer;"
               @click="itemClick(item.name, getImageUrl(item.imgUrl), item.contractAddresses, item.shopId, item.emailId)">
        </div>
      </div>
    </div>

    <div>
      <b-modal v-model="modalShow1" id="modal-1" :title=qrName ok-only ok-title="닫기">
        <div class="wrap d-flex justify-content-center align-content-center">
          <img :src="qrImg" style="width:300px;" class="rounded-4">
        </div>
        <img :src="qrCodeUrl" style="width:250px;"/>
        <div class="d-flex justify-content-center">
          <div class="badge bg-primary text-wrap" style="width: 13rem;">
            카메라로 QR를 인증해주세요.
          </div>

        </div>
        <p> 인식이 잘 안되신다면 아래의 사용하기 버튼을 눌러주세요.</p>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-outline-primary" @click="shopActivity(qrName, qrAddress, qrShopId, qrEmailId)"> 인증 사용하기 버튼</button>
        </div>

        <p class="lh-1">
          <br>[유의사항]
          ※ 본 인증은 홀더 인증으로 등록한 Wallet 기준입니다.
          ※ 직원이 사용하기 버튼을 처리한 경우만 인정됩니다. 홀더 인증 화면을 매니저/직원에게 제시해주시고
          사용하기 버튼 또는 QR인증으로 기록을 남기세요..</p>
      </b-modal>
    </div>


  </div>


</template>

<script>
import {reactive} from "vue";
import axios from "../common/AxiosInterceptors";
import metamask from "../components/metamask.vue";
import Web3 from 'web3'
import UploadAdapter from "@/common/UploadAdapter";
import modal from "bootstrap/js/src/modal";

export default {
  name: "Verify"
  ,
  props: {
    nftData: {
      type: Array,
      required: true,
    }
  },
  computed: {
    metamask() {
      return metamask
    }

  },
  data() {
    return {
      qrName: "",
      qrImg: "",
      qrAddress: "",
      qrShopId: "",
      qrEmailId: "",
      modalShow1: false,
      modalImage: "",
      imageTitle: "",
      qrCodeUrl: "",
      results: [],
      contractAddress: []
    };
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    itemClick(name, img, address, shopId, emailId) {
      console.log(name)
      console.log(img)
      this.qrName = name;
      this.qrImg = img;
      this.qrAddress = address;
      this.qrShopId = shopId;
      this.qrEmailId = emailId;
      // this.$emit.$bvModal.show('modal-1')
      this.generateQRCode(name, address, shopId, emailId);
      this.modalShow1 = true;
      this.$emit('close-parent-modal');
      // this.$refs['my-modal'].show();

    },
    async shopActivity(name, address, shopId, emailId) {
      console.log(name);
      const encodedName = encodeURIComponent(name);
      const res = await axios.get(`/data/shopActivity?shopId=${shopId}&emailId=${emailId}&address=${address}&name=${encodedName}`);
      // const res = await axios.get(`http://localhost:8081/shop/data/shopActivity?shopId=${shopId}&emailId=${emailId}&address=${address}&name=${name}`);
      // const data = res;


      const data = res.data;
      console.log(data);

      if (data === "Shop Activity Success") {
        console.log(data);
        alert("혜택사용 완료되었습니다.");
        this.modalShow1 = false;
      } else {
        alert("당일 중복 사용 혜택은 불가능합니다.");
      }
    },
    generateQRCode(name, address, shopId, emailId) {
      const data = `http://localhost:8081/shop/data/shopActivity?q=${shopId}&${emailId}&${address}&${name}`;
      this.qrCodeUrl = `https://chart.googleapis.com/chart?cht=qr&chl=${encodeURIComponent(data)}&chs=200x200&choe=UTF-8`;
    }
  },
  setup(props) {
    const nftData = [];
    const state = reactive({
      items: []
    })

    console.log("props12345");
    console.log(props);
    // console.log(data);
    // state.items = data;
    console.log("seo==>" + props.send);
    const contractAddresses = [props.send];
    console.log("contractAddresses->>> " + contractAddresses);
    // console.log("data.data-3>>> " + data.toStirng);
    // axios.get("https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTs?owner=0x0fBD0e7656F5FA57C1bA3aA3a553179F8e7E6254").then(({data}) => {
    //
    //
    // });

    function getImageUrl(imageUrl) {
      if (!imageUrl) {
        return "/default.jpg"; // 대체 이미지 경로
      }
      return imageUrl.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/");
    }


    return {state, getImageUrl}

  }
}

</script>

<style scoped>
.wrap {
  max-width: 100%;
  padding: 0px;
  margin: 0 auto 0 auto;
}

</style>
