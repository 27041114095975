<template>
  <div>
    <div class="wrap">
      aa
      <IMG SRC="https://ariproject.s3.ap-northeast-2.amazonaws.com/img/img/7698.png" style="width:300px;"/>
    </div>
    <img :src="qrCodeUrl"/>
    <DIV>

      <div class="badge bg-primary text-wrap" style="width: 13rem;">
        카메라로 QR를 인증해주세요!
      </div>


      인식이 잘 안되신다면 아래의 사용하기 버튼을 눌러주세요.
    </DIV>

    <p class="lh-1">[유의사항]
      ※ 본 인증은 홀더 인증으로 등록한 Wallet 기준입니다.
      ※ 직원이 사용하기 버튼을 처리한 경우만 인정됩니다. 홀더 인증 화면을 직원에게 제시해주시고
      사용하기 버튼 또는 QR인증으로 기록을 남기세요..</p>

    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary"> 인증 사용하기 버튼</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      qrCodeUrl: ''
    };
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    generateQRCode() {
      const data = "http://localhost:8081/shop/data/qrCheckIn";
      this.qrCodeUrl = `https://chart.googleapis.com/chart?cht=qr&chl=${encodeURIComponent(data)}&chs=200x200&choe=UTF-8`;
    }
  }
};
</script>3