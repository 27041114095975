<template>
  <div id="myEditor" class="w-90 m-auto">

    <div>
      <b-form>
        <!-- MEETUP_DCD -->
        <b-form-group id="input-group-2" label="밋업구분" label-for="input-2" description="" valid-feedback="입력 되었습니다."
                      required ref="" :state="isValid(formObject.meetupDcd)">

          <b-form-select id="input-2" v-model="formObject.meetupDcd" placeholder="모임구분을 선택해주세요."
                         :state="isValid(formObject.meetupDcd)">
            <option value="" selected> 선택해주세요.</option>
            <option value="1">오프라인</option>
            <option value="2">온라인</option>
            <option value="3">온&오프라인</option>
            <option value="4">기타</option>
          </b-form-select>
        </b-form-group>

        <!-- TITLE -->
        <b-form-group id="input-group-1" label="밋업(모임명) :" label-for="input-1" ref="shopName"
                      description="모임명은 메인에 표시됩니다." valid-feedback="입력 되었습니다." required
                      :state="isValid(formObject.title)">

          <b-form-input id="input-1" v-model="formObject.title" placeholder="밋업명을 입력하세요"
                        :state="isValid(formObject.title)"></b-form-input>
        </b-form-group>


        <!-- NUM_HOLDERS 홀더 참가인원수 -->
        <b-form-group id="input-group-1" label="홀더인원수 :" label-for="input-1" ref="shopName"
                      description="홀더수는 메인에 표시됩니다." valid-feedback="입력 되었습니다." required
                      :state="isValid(formObject.numHolders)">

          <b-form-input id="input-1" v-model="formObject.numHolders" placeholder="참여 홀더인원수를 입력하세요"
                        :state="isValid(formObject.numHolders)"></b-form-input>
        </b-form-group>


        <!-- STDT -->
        밋업시작일시 :
        <VueDatePicker v-model="formObject.stdt" ></VueDatePicker>

<!--        <b-form-group id="input-group-1" label="밋업 시작일시:" label-for="input-1" ref="shopName"-->
<!--                      description="시작일시는 메인에 표시됩니다." valid-feedback="입력 되었습니다." required-->
<!--                      :state="isValid(formObject.stdt)">-->

<!--          <b-form-input id="input-1" v-model="formObject.stdt" placeholder="밋업명을 입력하세요"-->
<!--                        :state="isValid(formObject.stdt)"></b-form-input>-->
<!--        </b-form-group>-->

<p></p>
        <!-- EDDT -->
        밋업종료일시 :
        <VueDatePicker v-model="formObject.eddt" ></VueDatePicker>
<!--        <b-form-group id="input-group-1" label="밋업 종료일시:" label-for="input-1" ref="shopName"-->
<!--                      description="종료일시는 메인에 표시됩니다." valid-feedback="입력 되었습니다." required-->
<!--                      :state="isValid(formObject.eddt)">-->

<!--          <b-form-input id="input-1" v-model="formObject.eddt" placeholder="밋업명을 입력하세요"-->
<!--                        :state="isValid(formObject.eddt)"></b-form-input>-->
<!--        </b-form-group>-->

<!--        &lt;!&ndash; DATE &ndash;&gt;-->
<!--        <b-form-group id="input-group-1" label="밋업 시간:" label-for="input-1" ref="shopName"-->
<!--                      description="시간은 메인에 표시됩니다." valid-feedback="입력 되었습니다." required-->
<!--                      :state="isValid(formObject.date)">-->

<!--          <b-form-input id="input-1" v-model="formObject.date" placeholder="밋업명을 입력하세요"-->
<!--                        :state="isValid(formObject.date)"></b-form-input>-->
<!--        </b-form-group>-->

        <p></p>
        <!-- PLACE -->
        <b-form-group id="input-group-3" label="밋업 장소(실제 지도주소)" label-for="input-3" valid-feedback="입력 되었습니다."
                      required :state="isValid(formObject.place)">
          <div class="d-flex">
            <b-form-input id="input-3" v-model="formObject.place" placeholder="밋업장소를 입력하세요"
                          :state="isValid(formObject.place)" class="flex-grow-1"></b-form-input>
            <b-button @click="onFindAddr" size="sm">주소찾기</b-button>
          </div>
          <address-vue ref="addressVue" @setAddr="handleAddr"/>
        </b-form-group>

        <!-- MAP_URL-->
        <b-form-group id="input-group-4" label="밋업장소 지도URL :" label-for="input-4">

          <b-form-input id="input-4" v-model="formObject.mapUrl" placeholder="밋업장소 주소URL을 입력하세요"></b-form-input>
          <b-form-valid-feedback id="input-feedback-2" class="valid-feedback">
            입력 되었습니다.
          </b-form-valid-feedback>
        </b-form-group>

        <!--오픈톡 주소 -->
        <b-form-group id="input-group-6" label="오픈톡 URL 주소 :" label-for="input-6">
          <b-form-input id="input-6" v-model="formObject.openTalk"
                        placeholder="오픈톡 URL 주소를 입력하세요"></b-form-input>
        </b-form-group>

        <div>
          <label for="tags-separators">NFT 지원태그 </label>
          <b-form-tags input-id="tags-separators" v-model="hashValue" separator=" ,;"
                       placeholder="태그를 입력후 스페이스 또는 콤마, 세미콜론을 입력하면 추가됩니다." no-add-on-enter></b-form-tags>
        </div>

        <p></p>
        <div>
          밋업주최 NFT 컨트랙 주소를 입력해주세요.
          <div>
            <div class="pl-1">
              NFT Contract Address :  <span v-if="selectednft1">{{ selectednft1.nftAddr }}</span>
            </div>
            <vue-typeahead-bootstrap
                class="mb-4"
                v-model="nftInput"
                :data="nftTempList"
                :serializer="items => items.nftEng"
                placeholder="NFT Contract #1"
                highlightClass="special-highlight-class"
                @hit="onClickNft($event, 1)"
                @input="searchNft($event, 1)"
                inputClass="special-input-class"
                @keyup.enter="handleEnter"
            >
              <template v-slot="{ nftTempList }">
                <div class="d-flex align-items-center">
                  <p>{{ nftTempList.nftEng }} {{ nftTempList.nftAddr }}</p>
                </div>
              </template>

            </vue-typeahead-bootstrap>
          </div>
        </div>

        <b-form-group id="input-group-6" label="대표 이미지 :" label-for="input-6">
          <b-img class="uploadimg" thumbnail fluid v-bind:src="thumbSrc" alt="Fluid image" id="thumbImg"
                 width="300%"></b-img>
          <input class="form-control" type="file" id="formFileMultiple"
                 @change.prevent="handleFileChange($event)"/>
          <div class="valid-feedback" v-if="editorInfo.isThumbUploaded">
            썸네일 업로드 완료!!
          </div>
          <p v-if="editorInfo.isThumbUploaded" style="color: red;">썸네일 업로드 완료!!</p>

        </b-form-group>
      </b-form>
    </div>


  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VueTypeaheadBootstrap from 'vue-bootstrap-typeahead';
import AddressVue from "../components/kakaoAddress.vue"
import useAxiosPrivate from "../common/AxiosPrivate";
import {reactive, watch, debounce} from "vue";
import logo from "@/assets/no-pictures.png";


export default {
  name: 'editorFileds',
  components: {
    VueTypeaheadBootstrap, AddressVue, VueDatePicker
  },
  setup() {
    const nftList = reactive({
      items: []
    });

    const fetchData = async () => {
      console.log("editor fetchdata--->");
      let url = '/data/nftlist';

      const {data} = await useAxiosPrivate.get(url);

      nftList.items = data;
      console.log(nftList);

    };

    fetchData().catch((e) => {
      console.log(e);
    });

    return {nftList};
  },

  data() {
    return {
      // file upload
      date: null,
      editorInfo: {
        isThumbUploaded: false
      }
      , hashValue: []

      // form upload
      , formObject: {
        meetupId: ''
        , email: ''
        , meetupDcd: ''
        , title: ''
        , numHolders: ''
        , stdt: ''
        , eddt: ''
        , place: ''
        , content: ''
        , thumbnail: ''
        , mapUrl: ''
        , nft1: ''
        , nft2: ''
        , nft3: ''
        , openTalk: ''
        , inqCount: ''
        , inppId: ''
        , createdAt: ''
        , updatedAt: ''
      }
      , thumbSrc: logo

      , nftTempList: []
      , nftInput: ''
      , selectednft1: []
      , selectednft2: []
      , selectednft3: []
    }
  },
  methods: {
    /**************************************************
     * 서버 이벤트 함수
     *************************************************/
    // 썸네일 파일 업로드 이벤트
    handleFileChange(event) {
      event.preventDefault;
      const url = '/data/uploadThumbFile';
      if (event.target.files && event.target.files[0]) {
        // 썸네일 이미지 검증 후, 서버 전송
        const file = event.target.files[0],
            fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
            fileExtention = file.name.split(".").pop(),
            fileName = file.name.split(".").shift(),
            isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

        console.log(fileSize, fileExtention, fileName, isImage);

        if (fileSize > 1 || !isImage) {
          alert('파일사이즈가 너무 크거나 이미지 파일이 아닙니다. (파일 사이즈 제한: 1MB) \n 현 파일 사이즈 : '
              + fileSize + " MB");
          return false;
        } else {
          // update thumb file
          const form = new FormData();

          form.append("file", file);
          form.append("Filetype", "THUMB_");
          useAxiosPrivate.post(url, form, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((res) => {

            this.editorInfo.isThumbUploaded = true;
            this.formObject.thumbnail = res.data[0].downloadUrl;
            this.thumbSrc = res.data[0].downloadUrl;

            console.log(res.data[0].downloadUrl);

          }).catch((error) => {
            this.editorInfo.isThumbUploaded = false;
            console.log(error);
          })

        }
      }

    },

    /**************************************************
     * 사용자 이벤트 함수 ( 버튼클릭, 탭 이동 등)
     *************************************************/
    // validate form data
    validateInput() {

      const tartgetArr = ['title'];
      // const tartgetArr = ['title', 'shopCategory', 'shopContent', 'shopAddress'];

      for (let key in this.formObject) {
        if (tartgetArr.includes(key)) {
          if (!this.isValid(this.formObject[key])) {
            console.log(`no value for ${key}`);
            return false;
          }
        }
      }

      // All is good
      return true;
    },


    isValid(value) {
       return value.length > 0;
     },
    // call kakaoAddr
    onFindAddr() {
      this.$refs.addressVue.isOpenEvent();
    },
    // set address
    handleAddr(addrObj) {
      console.log(addrObj);
      this.formObject.place = `${addrObj.roadAddress} ${addrObj.buildingName}`;
    },

    onComplete(result) {
      this.addrObject = result;
      this.isOpen = false;
      this.$emit("setAddr", this.addrObject, 'from kakao');
    },

    getChildObject() {
      let returnObj = {};
      returnObj.hashValue = [];
      returnObj.hashValue = this.hashValue;
      returnObj.formObject = this.formObject;
      this.$emit("setChildObject", returnObj, 'from child');
    },

    handleEnter() {
      console.log("handleEnter");
    },
    // search nft
    searchNft(event, value) {
      this.nftTempList = this.nftList.items.filter((item) => item.nftEng.includes(this.nftInput));
      console.log(value);
      console.log(event.target.value);
      if (value === 1) {
        this.formObject.nft1 = event.target.value;
        this.selectednft1.nftAddr = event.target.value;
      } else if (value === 2) {
        this.formObject.nft2 = event.target.value;
        this.selectednft2.nftAddr = event.target.value;
      } else if (value === 3) {
        this.formObject.nft3 = event.target.value;
        this.selectednft3.nftAddr = event.target.value;
      }
      // after filtering, set the temp list to the original list
      this.nftTempList = this.nftList.items;

    },
    // onclick event
    onClickNft(item, value) {
      console.log('item' + " " + value);
      console.log(item);
      if (value === 1) {
        this.formObject.nft1 = item.nftAddr;
        this.selectednft1.nftAddr = item.nftAddr;
      } else if (value === 2) {
        this.formObject.nft2 = item.nftAddr;
        this.selectednft2.nftAddr = item.nftAddr;
      } else if (value === 3) {
        this.formObject.nft3 = item.nftAddr;
        this.selectednft3.nftAddr = item.nftAddr;
      }
    }

  }
}
</script>

<style scoped></style>