<template>
  <spinner :loading="loading"/>

  <div id="myCarousel" class="carousel slide rounded-4 m-2" data-bs-ride="carousel" v-touch:tap="onTapItem">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img :src="state?.items[0]?.thumbnailMain" class="card-img-top" alt="...">
        <div class="container">
          <div class="carousel-caption text-start">
            <h1> {{ state?.items[0]?.shopName }} </h1>
            <p> {{ state?.items[0]?.shopName }} </p>
            <!-- <p><b-button variant="primary" :href="`/#/pages/${state?.items[0]?.shopId}`">View</b-button></p> -->
            <p><a class="btn btn-sm btn-primary" :href="`/#/pages/${state?.items[0]?.shopId}`">View</a></p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img :src="state?.items[1]?.thumbnailMain" class="card-img-top" alt="...">

        <div class="container">
          <div class="carousel-caption">
            <h1> {{ state?.items[1]?.shopName }} </h1>
            <p> {{ state?.items[1]?.shopName }} </p>
            <!-- <p><b-button variant="primary" :href="`/#/pages/${state?.items[1]?.shopId}`">View</b-button></p> -->
            <p><a class="btn btn-sm btn-primary" :href="`/#/pages/${state?.items[1]?.shopId}`">View</a></p>            
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img :src="state?.items[2]?.thumbnailMain" class="card-img-top" alt="...">
        <div class="container">
          <div class="carousel-caption text-end">

            <h1> {{ state?.items[2]?.shopName }} </h1>
            <p> {{ state?.items[2]?.shopName }} </p>
            <!-- <p><b-button variant="primary" :href="`/#/pages/${state?.items[2]?.shopId}`">View</b-button></p> -->
            <p><a class="btn btn-sm btn-primary" :href="`/#/pages/${state?.items[2]?.shopId}`">View</a></p>
          </div>
        </div>
      </div>
    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>

  </div>


  <br>
  <h4 class="m-2" ><B> HOLDERZ TRENDS </B></h4>
  <hr class="featurette-divider" >
  <div class="m-2" id="v-step-1">
    <trends/>
  </div>

  <div class="album py-1 bg-light rounded-4 mx-auto">
    <h4 class="m-2" ><B> HOLDERZ SHOP </B></h4>
    <hr class="featurette-divider">

    <p></p>

<!--    :icon='{-->
<!--    url: state?.items[m.id]?.imageUrl1,-->
<!--    scaledSize: {width: 30, height: 30},-->
<!--    labelOrigin: {x: 15, y: 0}-->
<!--    }'-->
<!--    -->
<!--    {{ state.markers }}-->
<!--    {{ state.items }}-->
    <div class="m-2 p-2" id="v-step-2">
      <GMapMap
          :center="center"
          :zoom="9"
          map-type-id="terrain"
          style="width: 100%; height: 20rem"
          ref="myMarker"
      >
        <GMapMarker
            :key="index"
            v-for="(m, index) in state.markers"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            @click="openMarker(m.id)"
            @closeclick="openMarker(null)"
            :icon='{
            url: m.imageUrl1,
            scaledSize: {width: 35, height: 35},
            labelOrigin: {x: 15, y: 0}
            }'
        >
          <GMapInfoWindow
              :options=" {
                    maxWidth: 800,
                    maxHeight: 400,
             }"
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id"
          >
            <div>
<!--              {{ m.id }}-->
              {{ m.category }}<br>
              <b>{{ m.name }}</b>

              <a :href="`/#/pages/${m.id}`"><img :src="m.thumbnail" style="width:120px; height: 50px;" alt="..."> </a>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>
  </div>
  <p></p>


  <!--  @click="center = m.position"-->
  <div class="album py-1 bg-light rounded-4 mx-auto">
<!--    <h4 class="m-2"><B> HOLDERZ SHOP </B></h4>-->
<!--    <hr class="featurette-divider">-->

    <p></p>
<!--    <hr class="featurette-divider">-->
    <div class="container-fluid">
      <!--      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">-->
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3" id="v-step-3">
        <div class="col" v-for="(item, idx) in state.items" :key="idx">
          <card :item="item"/> 
        </div>
      </div>
    </div>
  </div>
  <p></p>
  

   <div>
    <h4 class="m-2"><B> HOLDERZ CHAT </B></h4>
    <hr class="featurette-divider">

    <!-- Show the chat container when the user is logged in
    <div v-if="!this.$store.getters.isLogin" class="chat-container">
      <div class="chat-messages" ref="chatMessages">
        <div v-for="message in messages" :key="message.id" class="message">
          <div class="message-header">{{ message.sender }}:</div>
          <div class="message-text">{{ message.text }}</div>
        </div>
      </div>
      <div class="input-container">
        <input v-model="newMessage" id="chatInput" placeholder="Type your message" class="message-input" />
        <button @click="sendMessage" class="send-button">Send</button>
      </div>
    </div>
   -->

    <!-- Show a message when the user is not logged in -->
    <!-- <div v-else class="login-message"> -->
    <div class="login-message">
      <p>채팅 서버 점검중입니다.</p> 
      <!-- You can also add a login button or a link to your login page here -->
    </div>
  </div>

  
 <!--  <p></p>-->
<!--  &lt;!&ndash;  <pre> {{ state.items_meetup}}</pre>&ndash;&gt;-->
<!--  <div class="album py-1 bg-light rounded-4 mx-auto">-->
<!--    <h4 class="m-2"><B> HOLDERZ MEETUP </B></h4>-->
<!--    <hr class="featurette-divider">-->
<!--    <div class="container-fluid">-->
<!--      &lt;!&ndash;      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">&ndash;&gt;-->
<!--      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">-->
<!--        <div class="col" v-for="(items_meetup, idx) in state.items_meetup" 149="idx">-->
<!--          <cardTrip :items_meetup="items_meetup"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</template>

<script>

import axios from "../common/AxiosInterceptors";
import {mapState, useStore} from 'vuex';
import {useCookies} from 'vue3-cookies';
import {GMapMarker} from '@fawmi/vue-google-maps';
import io from 'socket.io-client';
import Stomp from 'webstomp-client';
import SockJS from 'sockjs-client';
import {mounted, onMounted, reactive, watch, ref} from "vue";
import Card from '../components/card.vue';
import CardTrip from '../components/cardTrip.vue';
import Trends from "@/components/trends.vue";



import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

export default {
  name: 'app',
  data() {
    return {
      msg: "theholderz",
      isLoading: true,
      openedMarkerID: null,
      center: {lat: 37.56667, lng: 126.97806},
      markers : null,
      messages : [],
            // markers: [
      //   {
      //     id: "",
      //     position: {
      //       lat: 0,
      //       lng: 0,
      //     }
      //   }
        // {
        //   id: 1,
        //   position: {
        //     lat: 37.56667,
        //     lng: 126.97806,
        //   },
        // },
        // {
        //   id: 2,
        //   position: {
        //     lat: 35.17944,
        //     lng: 129.07556,
        //   },
        // },
        // {
        //   id: 3,
        //   position: {
        //     lat: 37.45639,
        //     lng: 126.70528,
        //   },
        // },
        // {
        //   id: 4,
        //   position: {
        //     lat: 35.87222,
        //     lng: 128.60250,
        //   },
        // },
      // ],
      
     
    };
  },
  created() {    
     //alert(cookies.get('x-auth-token'));
  },
  mounted() {    
    // login check
    if( !this.$store.getters.isLogin ){        
      //this.stompConnect();
    }
    
  },
  components: {
    Card,
    Trends,    
    // CardTrip
  }
  ,
  computed: {
    ...
        mapState(['loading'])
  }
  ,

  setup() {
    const store = useStore();
    const {cookies} = useCookies();  // VueCookies 사용

    const categoryMap = {
      1: '미식',
      2: '카페',
      3: '서비스',
      4: '스포츠',
      5: '숙박',
      6: '여행',
      7: '의료',
      8: '온라인',
      9: '기타'
    };

    const meetupMap = {
      1: '오프라인',
      2: '온라인',
      3: '온&오프라인',
      4: '기타'
    };

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const state = reactive({
      items: [],
      items_meetup: [],
      form: {
        email: "",        
        userName: ""        
      }
  
    });

    const fetchData = async () => {
      console.log("fetchdata--->" + store  );
      let url = '/home/shops';
      let url_meetup = '/meetup/infos';
      if (store.state.account.email) {
        url += `?email=${store.state.account.email}`;
        url_meetup += `?email=${store.state.account.email}`;       
      }

      const jwtToken = cookies.get("jwt_token"); 
      const emailToken = cookies.get("email");

      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT 토큰을 헤더에 추가
          Cookie: `email=${emailToken}` // 쿠키를 헤더에 추가
        }
      };

      const { data } = await axios.get(url);
      state.items = shuffleArray(data);

// markers 배열 초기화
      state.markers = [];

// 데이터를 markers 배열에 추가
      state.items.forEach((item) => {
        const { shopId, lat, lng, shopName, thumbnailMain, shopCategory, imageUrl1 } = item;
        if (!lat || !lng) {
          return; // lat 또는 lng 값이 없는 경우 패스
        }
        const marker = {
          id: shopId,
          name : shopName,
          thumbnail : thumbnailMain,
          category : shopCategory,
          imageUrl1 : imageUrl1,
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        };
        state.markers.push(marker);
      });

// state.items의 shopId 값과 state.markers의 id 값을 일치시키기
      state.items.forEach((item) => {
        const { shopId } = item;
        const marker = state.markers.find((marker) => marker.id === shopId);
        if (marker) {
          item.markerId = marker.id;
        }
      });

    };

    const fetchData2 = async () => {
      let url_meetup = '/meetup/infos';

      if (store.state.account.email) {
        url_meetup += `?email=${store.state.account.email}`;
      }

      const jwtToken = cookies.get("jwt_token");
      const emailToken = cookies.get("email");

      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT 토큰을 헤더에 추가
          Cookie: `email=${emailToken}` // 쿠키를 헤더에 추가
        }
      };

      const {data} = await axios.get(url_meetup);
      state.items_meetup = data;
    };


    watch(() => state.items, () => {
      for (const item of state.items) {
        const category = item.shopCategory;
        item.shopCategory = categoryMap[category];
      }
    });

    watch(() => state.markers, () => {
      for (const item of state.markers) {
        const category = item.category;
        item.category = categoryMap[category];
      }
    });

    watch(() => state.items_meetup, () => {
      for (const item of state.items_meetup) {
        const category = item.meetupDcd;
        item.meetupDcd = meetupMap[category];
      }
    });
        
    const getCookie = (name) => {
      console.log('show cookie');
      console.log(cookies);
      const cookieValue = cookies.get(name);
      return cookieValue ? cookieValue : '';
    };
    
    onMounted(async () => {
     
      // 쿠키에서 JWT 토큰을 가져옵니다.
      // const jwtToken = getCookie("jwt_token");
      // const emailToken = getCookie("email");

      const jwtToken = getCookie('jwt_token');
      const emailToken = getCookie('email');

      // // JWT 토큰을 Vuex에 저장합니다.
      // if (jwtToken) {
      //   console.log("jwtTokenjwtTokenjwtTokenjwtTokenjwtToken");
      //   store.commit("setToken", jwtToken);
      // }
      
      // JWT 토큰을 Vuex에 저장합니다.
      if (emailToken) {      
        store.dispatch('storeLogin', emailToken);        
      }      
      
      await fetchData();
      await fetchData2();

    });
        
    return {state};
  }
  ,
  methods: {
    openMarker(id) {
      this.openedMarkerID = id;
    },
    showPosition(event, two) {
      //console.log(event.latLng);
    },
    //페이지 새로고침 메서드
    refreshPage() {
      location.reload();
    }
    ,
    onTapItem(mouseEvent) {
    }
    ,
    stompConnect(){
      //const serverUrl = "http://localhost:8081/ws";
      const serverUrl = process.env.VUE_APP_BASE_URL + "/ws";
      let socket = new SockJS(serverUrl);
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          origin: '*'
        },
        frame => {          
                    
          if( this.messages.length == 0 ){              
              this.getWholeMessage(frame.command);
            }

          this.stompClient.subscribe("/send", res => {                        
            
            if( this.messages.length > 0 ){
              this.messages.push(JSON.parse(res.body));
            }
             // 스크롤 조작
            this.$nextTick(() => {
                this.scrollToBottom();
              });
          })
        },
        error => {
          console.log("socketError => " + error.reason);
          console.log(error);
        }
      )

    },
    sendMessage() {
      // WebSocket 메시지 전송      
      const chatMsg = {
          sender: this.$store?.state.account.userName
        , text:  this.newMessage  
        , id: Date.now().toString() // 간단한 ID 생성 방식 (현실에선 더 복잡하게)
      };
          
      if (this.stompClient?.connected && chatMsg.text?.trim()) {
        this.stompClient.send("/receive", JSON.stringify(chatMsg) );
        // 메시지 배열에 새 메시지 추가( get msg from server )
        //this.messages.push(chatMsg);
      }else{
        alert('서버와 연결이 끊겼거나, 전송할 메시지가 없습니다 ㅠ');
      }

      // 초기화
      this.newMessage = "";
      document.getElementById("chatInput").value = "";
     
    },
    
    scrollToBottom() {
      let chatMessages = this.$refs.chatMessages;
      if (chatMessages) {
        chatMessages.scrollTop = chatMessages.scrollHeight;
      }
      
    },

    // 전체 chat message load
    getWholeMessage(){
      axios.get('/kafka/wholeChatMessage').then((response)=>{
        this.messages = response.data;
      });
    },

  }
}

</script>


<style>

/* 채팅 컨테이너 스타일 */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 20px;
}

/* 채팅 메시지 영역 스타일 */
.chat-messages {
  flex: 1;
  overflow-y: auto;
}

/* 개별 메시지 스타일 */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

/* 입력창 및 버튼 영역 스타일 */
.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.message-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

img {
  display: block;
  margin: 0px auto;
}

.card-img-top {
  position: center;
  width: 100%;
  height: auto;

  object-fit: cover;
  object-position: 55%;
}

.element {
  touch-action: none;
}

.carousel {
  border-radius: 55px 55px 55px 55px;
  overflow: hidden;

}

.custom-row {
  display: flex;
  justify-content: center;
  text-align: center;
}

.login-message {
  text-align: center;
  font-size: 18px;
  color: #777; /* Adjust the color as needed */
  margin-top: 20px; /* Add margin for spacing */
}

body {
  margin: 0;
}


</style>