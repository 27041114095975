<template>
  <spinner :loading="loading" />

  <div class="pages w-100">
    <div class="card m-2 rounded-4">
      <img :src="results[0]?.data[0]?.thumbnailMain" class="card-img-top rounded-4 img-fluid" alt="...">
      <div class="card-body-top">
        <h4 class="card-main-title m-2">{{ results[0]?.data[0]?.shopName }}</h4>
        <p class="card-main-title m-2">{{ results[0]?.data[0]?.itemName }}</p>
        <p class="card-text m-2" >{{ results[0]?.data[0]?.shopLocation }}</p>
<!--        <div class="pagesButton d-flex justify-content-start">-->
<!--          <a :href="results[0]?.data[0]?.urlMap" class="btn btn-primary m-2"  target="_blank"> Naver 정보보기 </a>-->
<!--        </div>-->
        <br>
<!--        shopID {{ shopId }} {{ replyType }}-->



        <div class="card m-2" style="width: auto;">
          <div class="card-header">
            Manager
          </div>
          <ul class="list-group list-group-flush d-flex justify-content-center align-items-center">
            <li class="list-group-item">
              <img :src="results[0]?.data[0]?.shopManagerPfp" class="rounded-4 img-fluid" style="max-width: 75px;">
            </li>
            <li class="list-group-item text-center">
              {{ results[0]?.data[0]?.shopManager }} ({{ results[0]?.data[0]?.email }})
            </li>
          </ul>
        </div>

        <div class="m-3">
          <small > <b> NFT를 선택 후 인증하세요! 해당 샵에서 제공하는 혜택을 받을수 있습니다.</b>  </small>
        </div>
        <div class="m-2" >
          <b-card-group deck class="row-cols-3">
            <b-card no-body v-if="isNftOwner1" class="col rounded-4"
                    img-alt="Image" img-top
                    @click="itemClick1(nftData[0]?.name, nftData[0]?.contractAddresses, results[1], shopId, emailId)"
                    style="cursor:pointer; "
                    >
              <img :src="nft_results[0]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100 p-0">
              <h4 class="card-title m-2 p-1">{{ nft_results[0]?.contractMetadata?.openSea?.collectionName }}</h4>
                  <b-card-text class="m-3">
                    {{ nftData[0]?.count }} HOLDER

                  </b-card-text>
<!--                  <template #footer>-->
<!--                    <small class="text-muted">-->
<!--                      <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                    </small>-->
<!--                  </template>-->
                </b-card>
              <b-card no-body v-if="!isNftOwner1" class="col rounded-4"
                      img-alt="Image" img-top
                      style="filter: grayscale(100); "
                      >
                <img :src="nft_results[0]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100 p-0">
                <h4 class="card-title m-2 p-1">{{ nft_results[0]?.contractMetadata?.openSea?.collectionName }}</h4>
                <b-card-text class="m-3">
                NO HOLDER
                </b-card-text>
<!--                <template #footer>-->
<!--                  <small class="text-muted">-->
<!--                    <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                  </small>-->
<!--                </template>-->
              </b-card>

              <b-card no-body v-if="isNftOwner2" class="col rounded-4"
                      img-alt="Image" img-top
                      @click="itemClick1(nftData[1]?.name, nftData[1]?.contractAddresses, results[1], shopId, emailId)"
                      style="cursor:pointer; width: 50% !important;"
                      >

                <img :src="nft_results[1]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100">
                <h4 class="card-title m-2 p-1">{{ nft_results[1]?.contractMetadata?.openSea?.collectionName }}</h4>
                <b-card-text class="m-3">
                  {{ nftData[1]?.count }} HOLDER

                </b-card-text>
<!--                <template #footer>-->
<!--                  <small class="text-muted">-->
<!--                    <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                  </small>-->
<!--                </template>-->
            </b-card>
            <b-card no-body v-if="!isNftOwner2" class="col rounded-4"
                    img-alt="Image" img-top
                    style="filter: grayscale(100);"
                    >
              <b-card-text>

                <img :src="nft_results[1]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100">
                <h4 class="card-title m-2 p-1">{{nft_results[1]?.contractMetadata?.openSea?.collectionName}}</h4>
                <b-card-text class="m-3">
                  NO HOLDER
                </b-card-text>
              </b-card-text>
<!--              <template #footer>-->
<!--                <small class="text-muted">-->
<!--                  <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                </small>-->
<!--              </template>-->
            </b-card>


            <b-card no-body v-if="isNftOwner3" class="col rounded-4"
                    img-alt="Image" img-top
                    @click="itemClick1(nftData[2]?.name, nftData[2]?.contractAddresses, results[1],shopId, emailId)"
                    style="cursor:pointer;"
                    >

              <img :src="nft_results[2]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100">
              <h4 class="card-title m-2 p-1">{{ nft_results[2]?.contractMetadata?.openSea?.collectionName }}</h4>
              <b-card-text class="m-3">
                {{ nftData[2]?.count }} HOLDER

              </b-card-text>
<!--              <template #footer>-->
<!--                <small class="text-muted">-->
<!--                  <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                </small>-->
<!--              </template>-->
            </b-card>
            <b-card no-body v-if="!isNftOwner3" class="col rounded-4"
                    img-alt="Image" img-top
                    style="filter: grayscale(100);"
                    >

              <img :src="nft_results[2]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 w-100">
              <h4 class="card-title m-2 p-1">{{ nft_results[2]?.contractMetadata?.openSea?.collectionName }}</h4>
              <b-card-text class="m-3">
                  NO HOLDER
              </b-card-text>
<!--              <template #footer>-->
<!--                <small class="text-muted">-->
<!--                  <a :href="'https://etherscan.io/address/' + nft_results[1]?.contract?.address" target="_blank"> contract address </a>-->
<!--                </small>-->
<!--              </template>-->
            </b-card>
          </b-card-group>
        </div>


        <p class="card-text m-3"><small class="text-muted">Last updated {{ formatDate(results[0]?.data[0]?.updatedAt) }}</small></p>
        <p>
        </p>
      </div>
    </div>




    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        혜택안내
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item m-0 p-0">
          <div id="targetContent"></div>
          <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" :disabled="true"
                    name="testCK"></ckeditor>
        </li>
      </ul>
    </div>

    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        위치안내
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item"> {{ results[0]?.data[0]?.shopAddress }}</li>
      </ul>
    </div>

    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        홈페이지
      </div>
      <ul class="list-group list-group-flush">

        <li class="list-group-item"><a :href="results[0]?.data[0]?.urlHomepage "> {{
            results[0]?.data[0]?.urlHomepage
          }} </a></li>
      </ul>
    </div>

    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        # 해쉬태그
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item"> {{ results[0]?.data[0]?.shopHashTag }}</li>
      </ul>
    </div>

    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        상점리뷰
        <reply :item = shopId :type = replyType />
      </div>

      <!--      <ul class="list-group list-group-flush">-->
      <!--        <li class="list-group-item"> {{ results[0]?.data[0]?.shopAddress }}</li>-->
      <!--      </ul>-->
    </div>

    <div class="card m-2" style="width: auto;">
      <div class="card-header">
        구글지도
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">

          <iframe
              width="100%"
              height="600"
              style="border:0"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=18&q=' + results[0]?.data[0]?.shopAddress + results[0]?.data[0]?.shopName">
          </iframe>

        </li>
      </ul>
    </div>
  </div>


  <div class="d-flex justify-content-center">
    <b-modal v-model="modalShow" id="modal-xl" :title=modalName ok-only ok-title="닫기"><p> 홀더 인증 하시길 원하는 NFT를 선택해주세요. </p>
      <PRE> {{ emailId }}</pre>

      <!--      {{ modalAdd }}-->
        <Verify :nft-data="modalResult" @close-parent-modal="closeParentModal"/>
<!--      <Verify :nftData="nftData" @close-parent-modal="closeParentModal"></Verify>-->
    </b-modal>
  </div>

</template>

<script>
import axios from "@/common/AxiosInterceptors";
import useAxiosPrivate from "@/common/AxiosPrivate";
// import {reactive} from 'vue';
import {useRoute} from 'vue-router';
import {reactive} from "vue";
import qrCode from "../views/qrCode.vue";
import {onMounted} from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
//import '@ckeditor/ckeditor5-build-classic/build/translations/ko';
import CKEditor from '@ckeditor/ckeditor5-vue'
import Verify from "@/views/Verify.vue";
import store from "@/scripts/store";
import reply from '@/components/reply.vue';
import { mapState } from 'vuex'

export default {

  name: 'pages',
  components: {
    Verify,
    ckeditor: CKEditor.component,
    reply
  },
  computed: {
    ...mapState(['loading'])
  },

  props: {
    id: {
      type: String,
      default: ""
    },
    apiKey: {
      type: String,
      default: "https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=16&q="
    }
  },
  urlrul() {
    const urlvalue = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=16&q=";
    console.log(urlvalue);
    return urlvalue
  }
  ,
  data() {
    return {
      tables: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],
      replyType : "SHOP",
      shopId : "",
      isShopNft1: false,
      isShopNft2: false,
      isShopNft3: false,
      isNftOwner1: false,
      isNftOwner2: false,
      isNftOwner3: false,
      shopManager: "",
      shopManagerPfp: "",
      items: [],
      nftCounts: {},
      results: [],
      userData: [],
      nft_results: [],
      nftShopContractResult: [false, ''],
      nftData: [
        {
          name: "Dokomon",
          contractAddresses: "0x1340ef28363754a9bb49400bb717b5ff81077a9e",
          imgUrl:
              "https://i.seadn.io/gcs/files/1481aaebf8163dd2136f81704bd0b8d9.png?w=500&auto=format",
        },
      ],
      modalShow: false,
      modalName: "",
      modalAdd: "",
      modalResult: [],
      msg: "This is demo net work",
      emailId : "",
      editor: ClassicEditor,
      editorData: 'results[0]?.data[0]?.shopContent',
      editorConfig: {
        // The configuration of the editor.
        height: '1000px;',
        //language: 'ko',
        readonly: true,
        enableReadOnlyMode: true,
        toolbar: {
          readonly: true,
          enableReadOnlyMode: true,
          items: []
        }
      }
      ,isLoading: true
    }
  },

  created() {
    this.getData();
  },
  methods: {
    closeParentModal() {
      this.modalShow = false;
    },
    formatDate(date) {
      const diff = new Date() - new Date(date);
      const diffMinutes = Math.round(diff / 60000);
      const diffHours = Math.round(diff / 3600000);
      if( date ){
          if (diffMinutes < 60) {
            return `${diffMinutes}분 전`;
          } else if (diffHours < 24) {
            return `${diffHours}시간 전`;
          } else {
            //const newDate = new Date(date.replace(/-/g, "/"));
            // const newDate = date;
            return date.substring(0, date?.lastIndexOf("."));
          }
      }
      
    },
    itemClick1(imgName, contractAddresses, results, shopId, emailId) {
      console.log(contractAddresses);
      console.log(results.data.ownedNfts);
      const nftData = [];
      const addr = [contractAddresses];
      console.log();
      addr.forEach(address => {
        const nft = results?.data?.ownedNfts.filter(item => item.contract.address === address);


        nft.forEach(item => {
          nftData.push({
            name: item.metadata?.name,
            contractAddresses: item.contract?.address,
            imgUrl: item.metadata?.image,
            shopId: shopId,
            emailId: emailId
          });
        });
      });
      if (nftData.length == 0) {
        window.alert("홀더가아니야");
        return;
      }


      this.modalName = imgName;
      this.modalAdd = contractAddresses;
      this.modalResult = nftData;

      console.log(nftData);
      this.modalShow = true;
    },
    async getData() {
      const route = useRoute();
      const paramValue = route.params.id;

      const state = reactive({
        items: []
      });

      const nftData = [];

      console.log("사용자 비로그인 단계");
      // walletAddress 값이 할당된 이후에 호출
      const response1 = await axios.get('/home/pages/' + paramValue);
      console.log("paramValue--->" + paramValue);
      this.shopId = paramValue;
      this.replyType = "SHOP";
      const data1 = response1;
      state.items = data1.data;
      this.results = [data1];
      this.editorData = this.results[0]?.data[0]?.shopContent;

      const contractAddresses = [this.results[0]?.data[0]?.shopNft1, this.results[0]?.data[0]?.shopNft2, this.results[0]?.data[0]?.shopNft3].filter(address => address);
      // contractAddresses 배열에서 값이 있는 인덱스만 필터링한 배열을 생성합니다.
      const contractAddressesWithValues = contractAddresses.filter(address => address);
      // 요청 결과를 저장할 배열을 선언합니다.
      const nftResults = [];
      const nftShopContract = [];

      // contractAddressesWithValues 배열에서 순서대로 요청을 보냅니다.
      for (let i = 0; i < contractAddressesWithValues.length; i++) {
        try {
          const response = await axios.get(`https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTMetadata?contractAddress=${contractAddressesWithValues[i]}&tokenId=1&tokenType=ERC721`)
          // 요청이 성공했다면 nftResults 배열에 데이터를 추가합니다.
          nftResults.push(response.data);

          // 각 인덱스에 대한 isNftOwner 변수에 값을 할당합니다.
          if (i === 0) {
            this.isShopNft1 = true
          } else if (i === 1) {
            this.isShopNft2 = true
          } else if (i === 2) {
            this.isShopNft3 = true
          }
        } catch (error) {
          console.error(error);
        }
      }

      // 요청 결과를 출력합니다.
      console.log(nftResults);
      console.log("nftShopContract===> " + this.nftShopContractResult[0]);

      // isNftOwner 변수에 대한 값을 출력합니다.
      console.log('isNftOwner1:', this.isNftOwner1);
      console.log('isNftOwner2:', this.isNftOwner2);
      console.log('isNftOwner3:', this.isNftOwner3);

      // nftResults 배열을 this.nft_results 변수에 할당합니다.
      this.nft_results = nftResults;


      for (let i = 0; i < this.nft_results.length; i++) {
        const result = this.nft_results[i];
        const imageUrl = result?.contractMetadata?.openSea?.imageUrl;
        const collectionName = result?.contractMetadata?.openSea?.collectionName;
        const name = result?.contractMetadata?.name;

        if (!imageUrl) {
          this.nft_results[i].contractMetadata.openSea.imageUrl = result?.metadata?.image;
        }

        if (!collectionName) {
          this.nft_results[i].contractMetadata.openSea.collectionName = name;
        }
      }

      console.log('nft_results:', this.nft_results);
      console.log('results:', this.results);
      console.log('results[1]:', this.results[1]);

      console.log("checkstart");

      if (store.state.account.email.length != 0) {
        console.log("사용자 로그인 단계");
        let walletAddress = "";
        this.emailId = store.state.account.email;
        // const {data} = await axios.get("/user/profile/" + store.state.account.email);
        const {data} = await useAxiosPrivate.get("/user/profile");
        console.log(data.walletEth);
        const walletEth = data.walletEth;
        walletAddress = walletEth; // walletAddress 변수에 데이터 할당

        if (walletAddress.length != 0) {
          console.log("지갑 주소가 있는 경우");
          const response2 = await axios.get(`https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTs?owner=${walletAddress}`);
          const data2 = response2;
          this.results[1] = data2;

          console.log(this.results[1]?.data?.ownedNfts);
          console.log(this.results[1].array);

          const addressToIndexMap = {
            [this.results[0]?.data[0]?.shopNft1]: 0,
            [this.results[0]?.data[0]?.shopNft2]: 1,
            [this.results[0]?.data[0]?.shopNft3]: 2,
          };

          const nftData = Array(3).fill(null);

          contractAddresses.forEach((address) => {
            const nft = this.results[1]?.data?.ownedNfts.filter(
                (item) => item.contract.address === address
            );

            const index = addressToIndexMap[address];
            if (nft.length && index !== undefined) {
              nftData[index] = {
                name: nft[0].contractMetadata.name,
                contractAddresses: nft[0].contract.address,
                imgUrl: nft[0].contractMetadata.openSea.imageUrl,
                count: nft.length,
              };
            }
          });

          this.nftData = nftData;
          this.isNftOwner1 = Boolean(nftData[0]);
          this.isNftOwner2 = Boolean(nftData[1]);
          this.isNftOwner3 = Boolean(nftData[2]);

          // isNftOwner 변수에 대한 값을 출력합니다.
          console.log('isNftOwner1:', this.isNftOwner1);
          console.log('isNftOwner2:', this.isNftOwner2);
          console.log('isNftOwner3:', this.isNftOwner3);

          // nft_results 변수에 대한 값을 출력합니다.
          console.log('nft_results:', this.nft_results);
          // nft_results 변수에 대한 값을 출력합니다.
          console.log('nftData:', this.nftData);

        } else {
          console.log("지갑 주소가 없는 경우");
        }
      }
    }
  }
}
</script>

<style scoped>
.card-img-top {
  width: 100%;
  max-height: 500px;
}


.pages {
  max-width: 100%;
  padding: 0px;
  margin: 0px auto;
}

.pagesButton {
  display: inline;
}

.card-img-to {
  position: center;
  object-fit: cover;
  object-position: 55%;
}

.b-card {
  padding: 0;
  margin: 0;
}

.card-img-to {
  padding: 0;
}

@media (max-width: 600px) {
  .card-img-top {
    width: 100%;
    height: 150px;
  }

  .card-deck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .card-text:last-child{
   font-size: 10px;
 }
  .card-body-top{
   padding: 0px;
 }
  h4, .h4 .card-title{
  font-weight:bold;
  font-size: 15px;

 }

}

</style>