<template>
  <spinner :loading="loading" />
  {{ ethWallet }}
  <div class="w-100">
    <div>Total floor price: {{ totalFloorPrice }} eth</div>
    <div>ToTal NFT Counts : {{ state.items.ownedNfts?.length }} </div>
<!--    <pre> {{ state }} </pre>-->
    <div class="row">
      <!--      <div v-for="nft in state.items.ownedNfts" :key="nft.id" class="col-md-2 mb-3">-->
      <div v-for="(nft, index) in state.items.ownedNfts" :key="nft.id" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
        <b-card class="shadow-sm h-100">
          <a :href="getOpenSeaLink(nft)" target="_blank">
            <b-img class="rounded-3 mb-3" v-if="nft.metadata.image" :src="getImageUrl(nft.metadata.image)"
                   style="width:100%; max-height: 200px; object-fit: cover;"/>
            <div v-else>No image available</div>
          </a>
          <!--          <div><b>{{ nft.metadata.name }}</b></div>-->
          <div><b>{{ index + 1 }}. {{ nft.metadata.name }}</b></div>
          <div
              v-if="nft.contractMetadata.openSea.floorPrice !== null && typeof nft.contractMetadata.openSea.floorPrice !== 'undefined'">
            Floor Price : {{ nft.contractMetadata.openSea.floorPrice }} eth
          </div>

        </b-card>
      </div>
    </div>
  </div>
</template>
<!--<template>-->
<!--  <spinner :loading="loading" />-->
<!--  {{ ethWallet }}-->
<!--  <div class="m-5 w-100">-->
<!--    <div>Total floor price: {{ totalFloorPrice }} eth</div>-->
<!--    <div class="row">-->
<!--      <div v-for="nft in state.items.ownedNfts" :key="nft.id" class="col-md-2 mb-3 mx-5">-->
<!--        <div><b>{{ nft.metadata.name }}</b></div>-->
<!--        <div-->
<!--            v-if="nft.contractMetadata.openSea.floorPrice !== null && typeof nft.contractMetadata.openSea.floorPrice !== 'undefined'">-->
<!--          Floor Price : {{ nft.contractMetadata.openSea.floorPrice }} eth-->
<!--        </div>-->
<!--        <a :href="getOpenSeaLink(nft)" target="_blank">-->
<!--          <img class="rounded-3" v-if="nft.metadata.image" :src="getImageUrl(nft.metadata.image)"-->
<!--               style="width:100%; max-height: 200px; object-fit: cover;"/>-->
<!--          <div v-else>No image available</div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
<script>
import axios from "@/common/AxiosInterceptors";
import { mapState } from 'vuex'
import {reactive, computed, ref} from "vue";
import useAxiosPrivate from "@/common/AxiosPrivate";
import router from "@/router";

export default {
  name: "wallet.vue",
  data() {
    return {
      isLoading: true,
      ethWallet: null
    }
  },
  computed: {
    ...mapState(['loading'])
  },
  setup() {

    const state = reactive({
      items: {data: {ownedNfts: []}}
    });
    const ethWallet = ref(null);
    const isLoading = ref(true); // 로딩 중임을 알리는 변수

    async function fetchData() {
      if (!ethWallet.value) {
        // 지갑주소가 없는 경우 경고 메시지 출력 후 profile 페이지로 이동
        alert('지갑주소가 등록되어 있지 않습니다. 프로필 페이지에서 등록해주세요.');
        router.push('/profile');
        return;
      }

      const res = await axios.get(`https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTs?owner=${ethWallet.value}`);
      state.items = res.data;
      isLoading.value = false; // 데이터 가져오기 완료 후 로딩 중임을 해제
    }

    useAxiosPrivate.get("/user/profile").then(({ data }) => {
      console.log("profile.vue");
      console.log(data);
      console.log(data.walletEth);
      ethWallet.value = data.walletEth;
      fetchData();
    });

    function getImageUrl(imageUrl) {
      if (!imageUrl) {
        return "/default.jpg"; // 대체 이미지 경로
      }
      return imageUrl.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/");
    }

    function getOpenSeaLink(nft) {
      const contractAddress = nft.contract.address;
      const tokenId = parseInt(nft.id.tokenId, 16); // 16진수를 10진수로 변환
      return `https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`;
    }

    // 계산된 총합을 반환하는 computed 프로퍼티 추가
    const totalFloorPrice = computed(() => {
      return state.items.ownedNfts?.reduce((total, nft) => {
        if (nft.contractMetadata.openSea.floorPrice !== null && typeof nft.contractMetadata.openSea.floorPrice !== 'undefined') {
          return total + Number(nft.contractMetadata.openSea.floorPrice);
        }
        return total;
      }, 0);
    });

    return {state, getImageUrl, getOpenSeaLink, totalFloorPrice};
  }
};
</script>
<style scoped>

/*
.spinner {
  position: center;
  top: 50%;
  left: 50%;
}
*/

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #fff;
  border-radius: 50%;
  border-top-color: #3f51b5;
  animation: spin 1s infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>