<template>
    <div>
        <input
            type="file"
            ref="imageInput"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.svg"
            :style="{display: none}"
            @change="fileChange"
        />
        <div class="d-flex align-items-center justify-content-center my-4 w-100 h-100">
            <img class="block" ref="img" :src="imageUrl" alt="image" style="width: 100%; height: 100%;" />
        </div>
        <button @click="handleCropImage" >이미지 자르기</button>
        <!-- <button @click="imageInput.click()" >select image</button> -->
        <!-- <div>
            <span>selected file:</span>
            <span v-if="selectedFile"  >{{ selectedFile.name }}</span>
        </div> -->
    </div>
  </template>

  <script>
  import { defineComponent, ref, watchEffect, onMounted, onUnmounted, watch } from "vue";
  import img500 from "@/assets/500x500.png";
  import Cropper from "cropperjs";
  import "cropperjs/dist/cropper.css";

  export default {
    name: "ImageCropper",
    events: ['imageCropped'],
    data() {
      return {
        imageUrl: img500,
        cropper: null,
        zoom: 1,
        rotate: 0,
      };
    },
    setup(_props, {emit}){
        const imageInput = ref(null);
        const selectedFile = ref(null);
        const imageSrc = ref(null);
        const img = ref(null);
        const fileReader = new FileReader();
        let cropper = null;

        fileReader.onload = (e) => {
            imageSrc.value = e.target.result;
        }

        const handleCropImage = () => {
            cropper
            .getCroppedCanvas({
                width: 256,
                height: 256,
                // imageSmoothingEnabled: false,
                imageSmoothingQuality: 'high',
            })
            .toBlob((blob) => {
                const file = new File([blob], selectedFile.value.name, {
                    type: 'image/png',
                    lastModified: Date.now(),
                });
                console.log(file);
                // this.$emit('imageCropped', file
                emit('imageCropped', file);
            });
        }

        const fileChange = (e) => {
            const file = e.target.files || e.dataTransfer.files;
            console.log("fileChange " , file);
            if(file.length) {
                selectedFile.value = file[0];
            }
        }

        onMounted(() => {
            console.log("onMounted " + img.value)
            console.log(img)
            cropper = new Cropper(img.value, {
                aspectRatio: 1,
                minCropBoxWidth: 256,
                minCropBoxHeight: 256,
                viewMode: 1,
                dragMode: 'move',
                background: false,
                cropBoxMovable: true,
                cropBoxResizable: false,
            });
        });

        onUnmounted(() => {
            cropper.destroy();
        });

        watchEffect(() => {
            if(selectedFile.value) {
                fileReader.readAsDataURL(selectedFile.value);
            } else {
                imageSrc.value = null;
            }
        });

        watch(imageSrc,
            () => {
            if(imageSrc.value) {
                cropper.replace(imageSrc.value);
            }
        },
        {
            flush: 'post',  // watch runs after component updates
        }
        );

        return{
            imageInput,
            selectedFile,
            fileChange,
            handleCropImage,
            imageSrc,
            img
        }
    },

    methods: {
      P() {
        const croppedCanvas = this.cropper.getCroppedCanvas({
          maxWidth: 500,
          maxHeight: 500,
        });
        const croppedImage = croppedCanvas.toDataURL();
        console.log(croppedImage);
      },
      reset() {
        this.cropper.reset();
        this.zoom = 1;
        this.rotate = 0;
      },
    },
  };
  </script>

  <style scoped>
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .range-controls {
    display: flex;
  }

  .buttons {
    display: flex;
  }

  button {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: #0077ff;
    color: #fff;
    cursor: pointer;
  }

  button:hover {
    background-color: #0053ba;
  }

.cropper-face,
.cropper-move {
  border-radius: 50%;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 600px) {


}

  </style>
