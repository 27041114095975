/*
*  UploadAdapter
*  이미지 업로드용 Adapter
*  == 23.01.20 ==
*  기본 기능 동작 확인
*
*/
export default class UploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }

    // Starts the upload process.
    upload() {

      return this.loader.file.then(

        (file) =>
          new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject, file);
            this._sendRequest(file);
          })
      );
    }

    // Aborts the upload process.
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
      const xhr = (this.xhr = new XMLHttpRequest());

      xhr.open("POST", process.env.VUE_APP_BASE_URL + "/data/uploadFile", true);
      //xhr.setRequestHeader("Content-Type", "multipart/form-data");
      //xhr.setRequestHeader("enctype", `multipart/form-data; charset=utf-8; boundary=${makeBoundary()}`);
      //xhr.setRequestHeader("Authorization", `Bearer ${token.getToken}`);

      xhr.responseType = "json";
    }

    // Initializes XMLHttpRequest listeners.
    // set urlinfo
    _initListeners(resolve, reject, file) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = `Couldn't upload file: ${file.name}.`;

      xhr.addEventListener("error", () => reject(genericErrorText));
      xhr.addEventListener("abort", () => reject());
      xhr.addEventListener("load", () => {
        const response = xhr.response;

        if (!response || response.error) {
          return reject(response && response.error ? response.error.message : genericErrorText);
        }
        resolve({
           default: response[0].downloadUrl
        });
      });

      if (xhr.upload) {
        xhr.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
      // Prepare the form data.
      const data = new FormData();

      data.append("file", file);
      data.append("use_org_name", "N");

      // Send the request.
      this.xhr.send(data);
    }
  }
