<template>
  <div>
    <button v-if="!isLoggedIn" @click="loginWithMetamask">Login with Metamask</button>
    <div v-else>
      환영합니다. {{ userAccount }}
    </div>
    <div>
      <button @click="logoutFromMetamask">Logout from Metamask</button>
    </div>
    <div>
      https://eth-mainnet.g.alchemy.com/v2/{{ apiKey }}/getNFTs?owner={{ userAccount }}
    </div>
    STORE VALUE -> {{ store.state.account.email }}

  </div>
</template>

<script>
import Web3 from 'web3'
import store from "../scripts/store";

export default {
  computed: {
    store() {
      return store
    }
  },
  data() {
    return {
      userAccount: null,
      isLoggedIn: false,
      apiKey: "0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF"
    };
  },
  methods: {
    async loginWithMetamask() {

      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask and try again.');
        return;
      }

      // Check if the user has Metamask installed and unlocked
      if (window.ethereum) {
        // Request account access if needed
        window.ethereum.enable()

        // Connect to Metamask provider
        const web3 = new Web3(window.ethereum)
        const accounts = await web3.eth.getAccounts()

        console.log('Logged in with Metamask account:', accounts[0])
        this.userAccount = accounts[0];
        this.isLoggedIn = true;
      } else {
        alert("metamask is not installed or unlocked.");
        console.error('Metamask is not installed or unlocked.111')
      }
    },
    logoutFromMetamask() {
      // Clear the connected account
      window.ethereum.enable()

      // Clear the web3 instance
      this.web3 = null
      alert("Logged out from Metamask.");
      console.log('Logged out from Metamask.')
    }
  }
}
</script>
