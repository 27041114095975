<template>
  <spinner :loading="loading"/>

  <!--  <pre> {{ results }} </pre>-->
  <div class="container w-100 m-auto">
    <div class="row align-top  g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
<!--        <h1 class="display-4 fw-bold lh-1 mb-3">MEETUP</h1>-->
<!--        <p class="col-lg-12 fs-4">CHECK INFOMATION.</p>-->


         <div class="card m-2" style="width: auto;">




          <div class="card-header m-0 p-0" style="position: relative;">
            <div class="image-wrapper">
              <img :src="results[0]?.data[0]?.thumbnail" class="card-img-top rounded-2 img-fluid" alt="..." style="width: 100%; height: 400px">
              <img :src="nft_results[0]?.contractMetadata?.openSea?.imageUrl" class="card-img-to rounded-4 overlay-image"
                   style="position: absolute; top: 0; right: 0; left: 0%; width: 100px;">
            </div>
          </div>
        </div>
        <div class="card m-2" style="width: auto;">
          <div class="card-header">
            밋업소개
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item m-2 p-2">
              <div id="targetContent"></div>
              <h5 class="card-main-title m-2">{{ results[0]?.data[0]?.title }}</h5>
              <p class="card-text m-2">{{ results[0]?.data[0]?.place }}</p>
              <div class="pagesButton d-flex justify-content-start">
                <a :href="results[0]?.data[0]?.mapUrl" class="btn btn-success m-2" target="_blank"> Naver 정보 </a>
              </div>

              <img :src="results[0]?.data[0]?.shopManagerPfp" class="rounded-circle img-fluid" style="max-width: 75px;">
              {{ results[0]?.data[0]?.shopManager }} ({{ results[0]?.data[0]?.email }})

              <DIV> 밋업 시작일자 : {{ formatDate(results[0]?.data[0]?.stdt) }}</DIV>
              <DIV> 밋업 종료일자 : {{ formatDate(results[0]?.data[0]?.eddt) }}</DIV>
              <DIV> 밋업 오픈톡 : {{ results[0]?.data[0]?.openTalk }}</DIV>
              <DIV> hashtag : {{ results[0]?.data[0]?.hashtag }}</DIV>
            </li>
          </ul>
        </div>
        <div class="card m-2" style="width: auto;">
          <div class="card-header">
            밋업소개
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item m-0 p-0">
              <div id="targetContent"></div>
              <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" :disabled="true"
                        name="testCK"></ckeditor>
            </li>
          </ul>
        </div>

      </div>
      <div class="col-md-10 mx-auto col-lg-5 text-lg-start">

        <div class="card m-2" style="width: auto;">
          <div class="card-header">
            구글지도
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">

              <iframe
                  width="100%"
                  height="300"
                  style="border:0"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=18&q=' + results[0]?.data[0]?.shopAddress + results[0]?.data[0]?.shopName">
              </iframe>

            </li>
          </ul>
        </div>

        <div class="card m-2" style="width: auto;">
          <div class="card-header">
            댓글
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">  <reply :item=shopId :type=replyType></reply></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "@/common/AxiosInterceptors";
import useAxiosPrivate from "@/common/AxiosPrivate";
// import {reactive} from 'vue';
import {useRoute} from 'vue-router';
import {reactive} from "vue";
import qrCode from "../views/qrCode.vue";
import {onMounted} from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
//import '@ckeditor/ckeditor5-build-classic/build/translations/ko';
import CKEditor from '@ckeditor/ckeditor5-vue'
import Verify from "@/views/Verify.vue";
import store from "@/scripts/store";
import reply from '@/components/reply.vue';
import {mapState} from 'vuex'

export default {

  name: 'pages',
  components: {
    // Verify,
    // ckeditor: CKEditor.component,
    reply
  },
  computed: {
    ...mapState(['loading'])
  },

  props: {
    id: {
      type: String,
      default: ""
    },
    apiKey: {
      type: String,
      default: "https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=16&q="
    }
  },
  urlrul() {
    const urlvalue = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&zoom=16&q=";
    console.log(urlvalue);
    return urlvalue
  }
  ,
  data() {
    return {
      isShopNft1: false,
      isShopNft2: false,
      isShopNft3: false,
      isNftOwner1: false,
      isNftOwner2: false,
      isNftOwner3: false,
      shopManager: "",
      shopManagerPfp: "",
      items: [],
      nftCounts: {},
      results: [],
      userData: [],
      nft_results: [],
      nftShopContractResult: [false, ''],
      modalShow: false,
      modalName: "",
      modalAdd: "",
      modalResult: [],
      msg: "This is demo net work",
      replyType: "MEETUP",
      shopId: "",
      emailId: "",
      editor: ClassicEditor,
      editorData: 'results[0]?.data[0]?.content',
      editorConfig: {
        // The configuration of the editor.
        height: '1000px;',
        //language: 'ko',
        readonly: true,
        enableReadOnlyMode: true,
        toolbar: {
          readonly: true,
          enableReadOnlyMode: true,
          items: []
        }
      }
      , isLoading: true
    }
  },

  created() {
    this.getData();
  },
  methods: {
    formatDate(date) {

      const dateObj = new Date(date);
      const formatted = dateObj.toLocaleString(undefined, {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      return formatted;

    },
    closeParentModal() {
      this.modalShow = false;
    },
    itemClick1(imgName, contractAddresses, results, shopId, emailId) {
      console.log(contractAddresses);
      console.log(results.data.ownedNfts);
      const nftData = [];
      const addr = [contractAddresses];
      console.log();
      addr.forEach(address => {
        const nft = results?.data?.ownedNfts.filter(item => item.contract.address === address);


        nft.forEach(item => {
          nftData.push({
            name: item.metadata?.name,
            contractAddresses: item.contract?.address,
            imgUrl: item.metadata?.image,
            shopId: shopId,
            emailId: emailId
          });
        });
      });
      if (nftData.length == 0) {
        window.alert("홀더가아니야");
        return;
      }


      this.modalName = imgName;
      this.modalAdd = contractAddresses;
      this.modalResult = nftData;

      console.log(nftData);
      this.modalShow = true;
    },
    async getData() {
      const route = useRoute();
      const paramValue = route.params.id;

      const state = reactive({
        items: []
      });

      const nftData = [];

      console.log("사용자 비로그인 단계");
      // walletAddress 값이 할당된 이후에 호출
      const response1 = await axios.get('/meetup/infos/' + paramValue);
      this.shopId = paramValue;
      const data1 = response1;
      state.items = data1.data;
      this.results = [data1];
      this.editorData = this.results[0]?.data[0]?.content;

      const contractAddresses = [this.results[0]?.data[0]?.nft1].filter(address => address);
      // contractAddresses 배열에서 값이 있는 인덱스만 필터링한 배열을 생성합니다.
      const contractAddressesWithValues = contractAddresses.filter(address => address);
      // 요청 결과를 저장할 배열을 선언합니다.
      const nftResults = [];
      const nftShopContract = [];

      // contractAddressesWithValues 배열에서 순서대로 요청을 보냅니다.
      for (let i = 0; i < contractAddressesWithValues.length; i++) {
        try {
          const response = await axios.get(`https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTMetadata?contractAddress=${contractAddressesWithValues[i]}&tokenId=1&tokenType=ERC721`)
          // 요청이 성공했다면 nftResults 배열에 데이터를 추가합니다.
          nftResults.push(response.data);

          // 각 인덱스에 대한 isNftOwner 변수에 값을 할당합니다.
          if (i === 0) {
            this.nft1 = true
          } else if (i === 1) {
            this.nft2 = true
          } else if (i === 2) {
            this.nft3 = true
          }
        } catch (error) {
          console.error(error);
        }
      }

      // 요청 결과를 출력합니다.
      console.log(nftResults);
      console.log("nftShopContract===> " + this.nftShopContractResult[0]);

      // isNftOwner 변수에 대한 값을 출력합니다.
      console.log('isNftOwner1:', this.isNftOwner1);
      console.log('isNftOwner2:', this.isNftOwner2);
      console.log('isNftOwner3:', this.isNftOwner3);

      // nftResults 배열을 this.nft_results 변수에 할당합니다.
      this.nft_results = nftResults;

      console.log('nft_results:', this.nft_results);
      console.log('results:', this.results);
      console.log('results[1]:', this.results[1]);

      console.log("checkstart");

      if (store.state.account.email.length != 0) {
        console.log("사용자 로그인 단계");
        let walletAddress = "";
        this.emailId = store.state.account.email;
        // const {data} = await axios.get("/user/profile/" + store.state.account.email);
        const {data} = await useAxiosPrivate.get("/user/profile");
        console.log(data.walletEth);
        const walletEth = data.walletEth;
        walletAddress = walletEth; // walletAddress 변수에 데이터 할당

        if (walletAddress.length != 0) {
          console.log("지갑 주소가 있는 경우");
          const response2 = await axios.get(`https://eth-mainnet.g.alchemy.com/v2/0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF/getNFTs?owner=${walletAddress}`);
          const data2 = response2;
          this.results[1] = data2;

          console.log(this.results[1]?.data?.ownedNfts);
          console.log(this.results[1].array);

          const addressToIndexMap = {
            [this.results[0]?.data[0]?.shopNft1]: 0,
            [this.results[0]?.data[0]?.shopNft2]: 1,
            [this.results[0]?.data[0]?.shopNft3]: 2,
          };

          const nftData = Array(3).fill(null);

          contractAddresses.forEach((address) => {
            const nft = this.results[1]?.data?.ownedNfts.filter(
                (item) => item.contract.address === address
            );

            const index = addressToIndexMap[address];
            if (nft.length && index !== undefined) {
              nftData[index] = {
                name: nft[0].contractMetadata.name,
                contractAddresses: nft[0].contract.address,
                imgUrl: nft[0].contractMetadata.openSea.imageUrl,
                count: nft.length,
              };
            }
          });

          this.nftData = nftData;
          this.isNftOwner1 = Boolean(nftData[0]);
          this.isNftOwner2 = Boolean(nftData[1]);
          this.isNftOwner3 = Boolean(nftData[2]);

          // isNftOwner 변수에 대한 값을 출력합니다.
          console.log('isNftOwner1:', this.isNftOwner1);
          console.log('isNftOwner2:', this.isNftOwner2);
          console.log('isNftOwner3:', this.isNftOwner3);

          // nft_results 변수에 대한 값을 출력합니다.
          console.log('nft_results:', this.nft_results);
          // nft_results 변수에 대한 값을 출력합니다.
          console.log('nftData:', this.nftData);

        } else {
          console.log("지갑 주소가 없는 경우");
        }
      }
    }
  }
}
</script>

<style scoped>
.card-img-top {
  width: 100%;
}


.pages {
  max-width: 100%;
  padding: 0px;
  margin: 0px auto;
}

.pagesButton {
  display: inline;
}


.b-card {
  padding: 0;
  margin: 0;
}

.card-img-to {
  padding: 0;
}

@media (max-width: 600px) {
  .card-img-top {
    width: 100%;
    height: 150px;
  }

  .card-deck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card-text:last-child {
    font-size: 10px;
  }

  .card-body-top {
    padding: 0px;
  }

  h4, .h4 .card-title {
    font-weight: bold;
    font-size: 15px;
  }


  .text-lg-start {
    align-self: flex-start;
  }

  .mx-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}

</style>