<template>
  <div class="container w-100 m-auto">
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
        <h1 class="display-4 fw-bold lh-1 mb-3">THEHOLDERZ.COM</h1>
        <p class="col-lg-12 fs-4">홀더의 사업을 성장 시켜드립니다.</p>
        <p class="col-lg-12 fs-4">프로젝트 커뮤니티의 활성화를 지원합니다.</p>
        <p class="col-lg-12 fs-4">NFT 소유자의 혜택을 알립니다.</p>
        <p class="col-lg-12 fs-4">WEB3 문화와 홀더의 가치를 존중합니다.</p>

      </div>
      <div class="col-md-10 mx-auto col-lg-5">
        <form class="p-4 p-md-5 border rounded-3 bg-light">
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                   v-model="state.form.email">
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                   v-model="state.form.userPwd">
            <label for="floatingPassword">Password</label>
          </div>
          <div class="checkbox mb-3">
            <label>
              <!--              <input type="checkbox" value="remember-me"> Remember me-->
              <input type="checkbox" v-model="rememberMe"/> Remember me
            </label>
          </div>

        </form>

        <P>

        </P>
        <div>
          <button class="w-100 btn btn-lg btn-primary" @click.prevent=logins()>로그인</button>
        </div>
        <small class="text-muted">By clicking Sign up, you agree to the terms of use.</small>
        <p></p>
        <div>
          <button class="w-100 btn btn-lg btn-success" @click.prevent=loginWithTwitter()>Login With Twitter</button>
        </div>

        <hr class="my-4">

        <div>
          <button class="w-100 btn btn-lg btn-primary" type="submit" @click=join()>회원가입</button>
        </div>
        <p></p>

        <div>
          <b-button size="lg" class="w-100" variant="outline-primary"  @click="showModal = true">아이디 / 비밀번호 찾기</b-button>
        </div>
        <b-modal v-model="showModal" title="아이디/비밀번호 찾기" @hide="showModal=false" ok-only ok-title="닫기">
          <findIdPassword/>
        </b-modal>


      </div>
    </div>
  </div>


</template>

<script>
import {reactive} from "vue";
import axios from "../common/AxiosInterceptors";
import router from "@/router";
import store from "@/scripts/store";
import {mapState} from 'vuex'
import findIdPassword from "@/views/findIdPassword.vue"

export default {
  components: {
    findIdPassword
  },
  name: 'app'
  ,
  data() {
    return {
      isLoading: true,
      rememberMe: false,
      showModal: false // showModal 변수를 추가합니다.
    }
  }
  ,
  computed: {
    ...mapState(['loading'])
  },
  mounted() {
    const cookieEmail = this.getCookie('email');
    this.state.form.email = cookieEmail;
    console.log("cookieEmail" + cookieEmail);
    if (cookieEmail.length >= 2) {
      this.rememberMe = true;
    }
  },
  setup() {
    const state = reactive({
      form: {
        email: "",
        userPwd: ""
      }
    })
    return {state}
  }
  ,
  methods:
      {    //test
        findPassword() {
          this.showModal = true;
        },
        loginWithTwitter() {
          // window.location.href = "/shop/twitter/login";
           window.location.href = process.env.VUE_APP_BASE_URL + "/twitter/login";
          //window.location.href = "http://localhost:8081/shop/twitter/login";
          // axios.get('/twitter/login').then((res) => {
          //   console.log(res);
          // }).catch((err) => {
          //   console.log(err);
          // });
        },
        join() {
          //alert("join-router");
          console.log(this.$route.params);
          console.log(this.$router);
          this.$router.push('/join');
        },
        getCookie(name) {
          console.log("getCooke");
          console.log(name);
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) {
            const cookieValue = parts.pop().split(';').shift();
            console.log(cookieValue);
            return cookieValue;
          }
          return '';
        },
        setCookie(name, value, days) {
          let expires = '';
          if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
          }
          document.cookie = `${name}=${value}${expires}; path=/`;
        },
        logins() {
          if (this.rememberMe) {
            console.log("rememeberMe");
            this.setCookie('email', this.state.form.email, 7); // 7일 동안 유효한 쿠키
          }

          axios.post('/user/login', this.state.form).then((res) => {

            if (res.status === 200) {
              //alert(res.data);
              store.dispatch('storeLogin', res.data);
              window.alert("로그인 성공하였습니다.");
              router.push({path: "/"});
            } else {
              window.alert("로그인 실패하였습니다.");
            }

          }).catch(function (error) {
            console.log(error);
            window.alert('로그인 정보가 일치하지 않습니다.');
          });
        },
      }
}
</script>

<style scoped>
.container{
  width: 1200px;
}
.form-signin {
  max-width: 100%;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
