import {createWebHistory, createRouter, createWebHashHistory} from "vue-router";
import Home from "@/views/Home.vue";
import Editor from "@/views/Editor.vue";
import ShopAdmin from "@/views/ShopAdmin.vue";
import Login from "@/views/Login.vue";
import Pages from "@/views/pages.vue";
import Join from "@/views/Join.vue";
import Profile from "@/views/Profile.vue";
import Metamask from "@/components/metamask.vue";
import Verify from "@/views/Verify.vue";
import QrCode from "@/views/qrCode.vue";
import CouponAdmin from "@/views/CouponAdmin.vue";
import replyBoard from "@/views/replyBoard.vue";
import ContactUs from "@/views/ContactUs.vue";
import homeCategory from "@/views/HomeCategory.vue";
import searchShop from "@/views/searchShop.vue";
import store from "@/scripts/store";
import wallet from "@/views/wallet.vue";
import tweet from "@/views/tweet.vue";
import scollMenu from "@/views/scollMenu.vue";
import navBar from "@/views/navBar.vue";
import sideBar from "@/views/sideBar.vue";
import adminNftList from "@/views/adminNftList.vue";
import Admin from "@/views/Admin.vue";
import findIdPassword from "@/views/findIdPassword.vue";
import Holder from "@/views/Holder.vue";
import editorTrip from "@/views/EditorTrip.vue";
import pagesMeetup from "@/views/pagesMeetup.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    // HomeCategory
    {
        path: "/homeCategory/:id",
        name: "homeCategory",
        component: homeCategory,
    },
    // 회원가입 여부 확인
    {
        path: "/editor",
        name: "Editor",
        component: Editor, meta: { authRequired: true }
    },
    {
        path: "/pages/:id",
        name: "Pages",
        component: Pages,
    },
    {
        path: "/meetup/:id",
        name: "pagesMeetup",
        component: pagesMeetup,
    },
    {
        path: "/login",
        name: "Login",
        component: Login, meta: { authRequired: true }
    },
    {
        path: "/couponAdmin",
        name: "CouponAdmin",
        component: CouponAdmin,
    },
    // 회원가입
    {
        path: "/join",
        name: "Join",
        component: Join,
    },
    // 프로필
    {
        path: "/profile",
        name: "Profile",
        component: Profile, meta: { authRequired: true }
    },
    // 메타마스크
    {
        path: "/metamask",
        name: "Metamask",
        component: Metamask,
    },
    // 홀더인증
    {
        path: "/verify",
        name: "Verify",
        component: Verify,
    },
    // QR코드
    {
        path: "/qrCode",
        name: "Qrcode",
        component: QrCode,
    },
    // Shop관리
    {
        path: "/shopAdmin",
        name: "ShopAdmin",
        component: ShopAdmin, meta: { authRequired: true }
    }
    ,
    // searchShop
    {
        path: "/searchShop",
        name: "searchShop",
        component: searchShop,
    }
    ,
    // TestPage
    {
        path: "/ContactUs",
        name: "ContactUs",
        component: ContactUs,
    },
    // wallet
    {
        path: "/wallet",
        name: "wallet",
        component: wallet,
    }
    ,
    // ReplyBoard
    {
        path: "/replyBoard",
        name: "replyBoard",
        component: replyBoard,
    }
    ,
    // tweet
    {
        path: "/tweet",
        name: "tweet",
        component: tweet,
    }
    ,
    // scollMenu
    {
        path: "/scollMenu",
        name: "scollMenu",
        component: scollMenu,
    }
    ,
    // sideBar
    {
        path: "/sideBar",
        name: "sideBar",
        component: sideBar,
    }
    ,
    // scollMenu
    {
        path: "/navBar",
        name: "navBar",
        component: navBar,
    }
    ,
    // AdminNftList
    {
        path: "/adminNftList",
        name: "adminNftList",
        component: adminNftList,
    }
    ,
    // Admin
    {
        path: "/admin",
        name: "Admin",
        component: Admin
        //component : Admin, meta: {authRequired: true}
    },
    // findIdPassword
    {
        path: "/findIdPassword",
        name: "findIdPassword",
        component: findIdPassword
    },
    // Holder
    {
        path: "/Holder",
        name: "Holder",
        component: Holder
    },
    // editorTrip
    {
        path: "/editorTrip",
        name: "editorTrip",
        component: editorTrip
    }


];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    // Routing 이동후 스크롤 처리
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0, behavior: 'instant' }
        }
    },
    // scrollBehavior() {
    //     return {
    //         left: 0,
    //         top: 0
    //     };
    // },
    routes,
});


// navigate guard 구현
router.beforeEach(async (to, from, next) => {

    if (to.matched.some(function (routeInfo) {
        return routeInfo.meta.authRequired;
    })) {
        
        // 페이지별 login 체크
        if (["wallet", "Editor", "ShopAdmin", "Profile", "Admin"].includes(to.name)) {
            if (store.getters.isLogin) {
                console.log("login please");
                window.alert("로그인을 해주세요.");
                router.push({ path: "/login" });
            }

        }
        if (["Login"].includes(to.name)) {
           
            if (!store.getters.isLogin) {                
                window.alert("이미 로그인 되어있습니다.");
                router.push({ path: "/" });
            }
        }
        next();
    } else {
        console.log(to);
        console.log('route go  + ' + to.path);
        next();
    }

});

export default router;