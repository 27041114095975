<template >

    <VueAwesomeSideBar
      v-model:collapsed="collapsed"
      :menu="nftMenu"
      closeOnClickOutSide=true
      paddingTop="10px"
      widthMiniMenu="25px"
      width="250px"
      vueRouterEnabel
    ></VueAwesomeSideBar>

</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import store from "@/scripts/store";
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies();

export default {
  name: 'sidebar',
  setup() {
    const collapsed = ref(true);
    const miniMenu = ref(false);
    const closeOnClickOutSide = ref(false);


    const updateMenu = () => {
     collapsed.value = !collapsed.value
    }

    const updateminiMenu = () => {
      miniMenu.value = !miniMenu.value
    }

    const nftMenu = reactive([
      {
        header: 'THEHODEREZ.COM'
      },
      {
        name: 'FAVORITE',
        href: '/homeCategory/0',
        icon: { text: 'favorite', class: 'material-icons-outlined' },
      },
      {
        name: 'ALL',
        href: '/',
        icon: { text: 'home', class: 'material-icons-outlined' },
      },
      {
        name: '미식',
        href: '/homeCategory/1',
        icon: { text: 'restaurant', class: 'material-icons-outlined' },
      },
      {
        name: '카페',
        href: '/homeCategory/2',
        icon: { text: 'local_cafe', class: 'material-icons-outlined' },
      },
      {
        name: '서비스',
        href: '/homeCategory/3',
        icon: { text: 'volunteer_activism', class: 'material-icons-outlined' },
      },
      {
        name: '스포츠',
        href: '/homeCategory/4',
        icon: { text: 'sports_martial_arts', class: 'material-icons-outlined' },
      },
      {
        name: '여행',
        href: '/homeCategory/6',
        icon: { text: 'tour', class: 'material-icons-outlined' },
      },
      {
        name: '의료',
        href: '/homeCategory/7',
        icon: { text: 'medication', class: 'material-icons-outlined' },
      },
      {
        name: '온라인',
        href: '/homeCategory/8',
        icon: { text: 'laptop_mac', class: 'material-icons-outlined' },
      },
      // {
      //   name: 'MENU',
      //   href: '',
      //   collapseOnClick: false,
      //   icon: { text: 'home', class: 'material-icons-outlined' },
      //   children: [
      //
      //   ]
      // },
      {
        header: '정보'
      },
      {
        name: 'WhitePaper',
        href: '/ContactUs',
        icon: { text: 'dashboard', class: 'material-icons-outlined' },
      },
      {
        header: 'USER'
      },
      {
        name: '로그인',
        href: '/login',
        icon: { text: 'add', class: 'material-icons-outlined' },
      },
    ])

    onMounted(() => {
      //console.log('Sidebar is mounted');
    })

    return { collapsed, closeOnClickOutSide, nftMenu, updateMenu, updateminiMenu }
  },

  methods: {
    // set sidebar's items
    toggleSidebar() {
      this.updateMenu();
      this.updateminiMenu();

      const profileAdd = {
        name: '내 정보',
        href: '/profile',
        icon: { text: 'settings_accessibility', class: 'material-icons-outlined' },
      };

      const shopAdd = {
        name: '상점등록',
        href: '/editor',
        icon: { text: 'add', class: 'material-icons-outlined' },
      };

      const meetupAdd = {
        name: '밋업등록',
        href: '/editorTrip',
        icon: { text: 'calendar_month', class: 'material-icons-outlined' },
      };

      const shopManage = {
        name: '상점관리',
        href: '/shopAdmin',
        icon: { text: 'store', class: 'material-icons-outlined' },
      };

      const myWalletAdd = {
        name: '내 지갑보기',
        href: '/wallet',
        icon: { text: 'web_asset', class: 'material-icons-outlined' },
      };

      if (store.getters.isLogin) {
        if (this.nftMenu[13]?.name === '로그아웃') { // 로그인 된 상태에서 로그아웃을 클릭시
          this.nftMenu.splice(13, 1, {
            name: '로그인',
            href: '/login',
            icon: { text: 'login', class: 'material-icons-outlined' },
          });
          // remove shop
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
          this.nftMenu.splice(14, 1);
        }
      }
      else {
        if (this.nftMenu[13]?.name === '로그인') { // 로그인 안된상태에서 로그인 성공시
          this.nftMenu.splice(13, 1, {
            name: '로그아웃',
            href: '/',
            icon: { text: 'logout', class: 'material-icons-outlined' },
          });
          // profileAdd
          this.nftMenu.splice(14, 0, profileAdd);
          // shopAdd
          this.nftMenu.splice(15, 0, shopAdd);
          // shopManage
          this.nftMenu.splice(16, 0, shopManage);
          // myWalletAdd
          this.nftMenu.splice(17, 0, myWalletAdd);
          // meetupAdd
          this.nftMenu.splice(18, 0, meetupAdd);


          // this.nftMenu.splice(16, 0, {header: 'Admin'} );
          // this.nftMenu.splice(17, 0, {
          //   name: '상점관리(admin)',
          //   href: '/admin',
          //   icon: { text: 'lock', class: 'material-icons-outlined' },} );
          // this.nftMenu.splice(18, 0, {
          //   name: 'NFT관리(admin)',
          //   href: '/adminNftList',
          //   icon: { text: 'lock', class: 'material-icons-outlined' },} );
        }
      }
      if(store.getters.getRole == "ROLE_ADMIN")
      {
        this.nftMenu.splice(18, 1, {header: 'Admin'} );
        this.nftMenu.splice(19, 1, {
          name: '상점관리(admin)',
          href: '/admin',
          icon: { text: 'lock', class: 'material-icons-outlined' },} );
        this.nftMenu.splice(20, 1, {
          name: 'NFT관리(admin)',
          href: '/adminNftList',
          icon: { text: 'lock', class: 'material-icons-outlined' },} );
      } else {
        // remove shop
        this.nftMenu.splice(18, 1);
        this.nftMenu.splice(18, 1);
        this.nftMenu.splice(18, 1);
      }
      return this.nftMenu;
    },

    // set logout
    logout() {
      store.dispatch('storeLogout');
      cookies.keys().forEach(element => {
        cookies.remove(element);
      });
      this.$router.push({ path: "/" });
    }
  },

}
</script>
