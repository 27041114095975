import {createStore} from 'vuex'
import {useCookies} from 'vue3-cookies'

const {cookies} = useCookies();
const store = createStore({
    state() {
        return {
            account: {
                email : '',
                wallet : '',
                userName : ''
            },
            loading: false,
            showSpinner: false,
            isRole : '',
            isHelp: false
        }
    },
    mutations: {
        setAccount(state, payload) {
            state.account.email = payload;
            //state.account = payload;

            return state;
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setRole(state, role)
        {
            state.userRole = role;
        },
        setUserInfo(state, userInfo){
            state.account.userName = userInfo.userNames;
        },
        setHelp(state, help){
            state.isHelp = help;
        }
    },
    actions: {
        // login 
        storeLogin({ commit }, user){
            console.log('login action => ');
            console.log('user store user store =>', user )

            commit('setAccount', user);
            localStorage.setItem('account', JSON.stringify(user));
        },
        // logout
        storeLogout({ commit }, user){
            console.log('logout action => ');
            
            const tempItem = localStorage.getItem('account');
            if( !tempItem == false && tempItem !== 'undefined' ) {
                alert('로그아웃 되었습니다.');
                commit('setAccount', '');
                commit('setRole', '');
                localStorage.removeItem('account');
            }
            //this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            cookies.keys().forEach(element => {
                cookies.remove(element, "/");
                cookies.remove(element,  {path:'/',domain: '.theholderz.com',secure: true, sameSite:"None"});
              });
        },
        // reset
        init({ commit } ){
            const tempItem = localStorage.getItem('account');
            if( !tempItem == false && tempItem !== 'undefined' ) {
                const user = JSON.parse(tempItem);
                console.log('init');
                console.log(user);
                if (user) {
                    commit('setAccount', user);
                }
            }
        }
    },
    getters: {
        // check login
        isLogin(state) {
          return !state.account.email;
        }
        // get Role
        , getRole(state)
        {
            return state.userRole;
        }
        // get UserName
        , getUserName(state){ 
            return state.account.userName;
        }
        // get Email
        , getRefreshValue(state){
            return state.account.email;
        }
        // get Help
        , isHelp(state){
            return state.isHelp;
        }
    }
})

export default store;