<template>
  <div>
    <b-card :title="'검색어: ' + q" :sub-title="'검색 결과 수: ' + shops.data.length">
      <div class="row">
        <div class="col-md-4" v-for="(shop, index) in shops.data" :key="index">
          <b-card :img-src="shop.thumbnailMain" img-alt="Card image" img-top class="mb-3">
            <b-card-text>
              <h5>{{ shop.shopName }}</h5>
              <p>{{ shop.shopContent }}</p>
            </b-card-text>
            <b-button :to="`/pages/${shop.shopId}`" variant="primary">상세보기</b-button>
          </b-card>
        </div>
      </div>
      <p v-if="shops.data.length === 0">검색 결과가 없습니다.</p>
    </b-card>
  </div>
</template>

<script>
import axios from "../common/AxiosInterceptors";

export default {
  data() {
    return {
      searchText: '',
      shops: { data: [] },
    };
  },
  watch: {
    '$route.query.q': {
      handler: 'search',
      immediate: true
    }
  },
  methods: {
    async search() {
      try {
        const { q } = this.$route.query;
        if (!q) return;
        const response = await axios.get('/data/searchShop', { params: { q } });
        this.shops.data = response.data;
        console.log(this.shops.data);
        this.shops.data.forEach(shop => {
          shop.shopContent = shop.shopContent.replace(/<[^>]*>?/g, '');
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    q() {
      return this.$route.query.q;
    },
  },
};
</script>