<template>
  <div class="card shadow-lg rounded-4 flex-column">
    <a :href="`/#/pages/${item.shopId}`">
      <span class="img" :style="{backgroundImage: `url(${item.thumbnailMain})`}"/>
    </a>
    <div class="card-body d-flex flex-column">
      <div class="align-items-end"><b> {{ item.shopCategory }} </b></div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="card-text" style="font-size: 14px;">  {{ item.shopName }} <i class="bi bi-patch-check-fill" style="color:dodgerblue"></i> </p>
        </div>
        <div class="align-items-center">
          <p style="font-size: 14px;"> {{ item.shopLocation }} </p>
        </div>

      </div>
      <B><p class="align-items-center" style="font-size: 14px;"> {{ item.itemName }} </p></B>
<!--      <div class="d-flex justify-content-between align-items-center">-->
<!--        <small class="price text-muted">{{ lib.getNumberFormatted(item.itemPrice) }} 원</small>-->
<!--        <small class="discount text-danger text-muted"> {{ item.itemDiscount }}%</small>-->
<!--        <small class="real text-danger "> {{-->
<!--            lib.getNumberFormatted(item.itemPrice - (item.itemPrice * item.itemDiscount / 100))-->
<!--          }} 원</small>-->
<!--      </div>-->
<!--      <br>-->
<!--      <div class="d-flex justify-content-between align-items-center">-->
<!--        <div class="btn-group">-->
<!--          <small class="text-muted">{{ item.shopHashTag }}</small>-->
<!--        </div>-->
<!--      </div>-->
<!--      <br>-->
      <div class="d-flex justify-content-end align-items-center">
        <div class="d-flex justify-content-end align-items-center">
          <div class="d-flex justify-content-between align-items-end flex-wrap" style="width: 100%;">
            <div class="img rounded-4" :style="{backgroundImage: `url(${item?.imageUrl1 || ''})`, width: '40px', height: '37px', marginRight: '5px'}"></div>
            <div class="img rounded-4" :style="{backgroundImage: `url(${item?.imageUrl2 || ''})`, width: '40px', height: '37px', marginRight: '5px'}"></div>
            <div class="img rounded-4" :style="{backgroundImage: `url(${item?.imageUrl3 || ''})`, width: '40px', height: '37px', marginRight: '5px'}"></div>
            <div class="d-flex align-items-end">
          </div>
          </div>
        </div>
      </div>
      <br>
<!--      <div>-->
<!--      로그인 여부 {{ store.state.account.email }}-->
<!--      </div>-->
      <div class="btn-group">
        <!--        <button type="button" class="btn btn-sm"><i class="bi bi-suit-heart" @click="heart()"> 5</i></button>-->
        <button class="like-button" @click="store.state.account.email ? heart(item.shopId, store.state.account.email, item) : redirectToLoginPage()">
          <i v-if="item.isLiked == 'Y'" class="bi bi-heart-fill" style="color:red;"></i>
          <i v-else class="bi bi-heart" style="color:red;"></i> {{ item.likeCount }}
        </button>
        <button class="like-button" @click="store.state.account.email ? favorite(item.shopId, store.state.account.email, item) : redirectToLoginPage()">
          <i v-if="item.isFavorited == 'Y'" class="bi bi-bookmark-check-fill" style="color:dodgerblue;"></i>
          <i v-else class="bi bi-bookmark-check" style="color:dodgerblue;"></i> {{ item.favoriteCount }}
        </button>
<!--        <button @click="heart(item.shopId, store.state.account.email)">{{ isLiked ? '좋아요 취소' : '좋아요' }}</button>-->
<!--        <button type="button" class="btn btn-sm" @click="heart(item.shopId, store.state.account.email)">-->
<!--          <i class="bi bi-suit-heart red"></i> {{ item.likeCount }}-->
<!--        </button>-->
<!--        <button type="button" class="btn btn-sm"><i class="bi bi-star" style="color: red; font-size:20px"> 1</i></button>-->
<!--        <i class="bi bi-star-fill" style="color: darkorange"> 1</i>-->
<!--        <button type="button" class="btn btn-sm"><i class="bi bi-exclamation-triangle"> 3</i></button>-->
        <button class="read-button" type="button" @click=myFunc(item.shopId)><i class="bi bi-bar-chart-line-fill"></i> {{ item.inqCount }} </button>
        <button class="read-button" type="button" @click=myFunc(item.shopId)><i class="bi bi-chat-text"></i> {{ item.replyCount }} </button>

      </div>
    </div>
  </div>
</template>

<script>
import lib from "@/scripts/lib";
import axios from "@/common/AxiosInterceptors";
import store from "@/scripts/store";

export default {
  name: "card",
  computed: {
    store() {
      return store
    }
  },
  data() {
    return {
      category: "N/A",
      isLiked: 'N',
      isFavorited: 'N',
      shopId: 'SHOP_ID', // 상점 ID
      email: 'EMAIL' // 사용자 이메일
    }
  },
  props: {
    item: Object
  },
  setup(props) {
    return {lib}
  },

  methods: {
    heart(id, email, item ) {
      axios.post(`/home/shopLike`, { id: id, email: email })
          .then(response => {
            // 서버에서 성공적으로 처리한 경우, 클라이언트 상태를 업데이트합니다.
            console.log("heart gogo-->y");
            item.isLiked = response.data.isLiked ? 'Y' : 'N';
            if (item.isLiked == 'Y') {
              item.likeCount++;
            } else {
              item.likeCount--;
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    favorite(id, email, item ) {
      axios.post(`/home/shopFavorite`, { id: id, email: email })
          .then(response => {
            // 서버에서 성공적으로 처리한 경우, 클라이언트 상태를 업데이트합니다.
            console.log("heart gogo-->y");
            item.isFavorited = response.data.isFavorited ? 'Y' : 'N';
            if (item.isFavorited == 'Y') {
              item.favoriteCount++;
            } else {
              item.favoriteCount--;
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    myFunc(id) {
      //alert("hello!");
      console.log(this.$route.params);
      console.log(this.$router);
      this.$router.push('/pages/' + id);
    },
    redirectToLoginPage() {
      this.$router.push({ path: '/login' }); // '/login'은 실제 로그인 페이지 경로로 변경하세요
    },
  },
}
</script>

<style scoped>
.card .img {
  width: 100%;
  display: inline-block;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.card .card-body .price {
  text-decoration: line-through;
}

 .bi{
   font-size :20px
 }

.like-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.read-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
}
/*.card:hover {*/
/*  transform: scale(1.03); !* 카드를 5% 확대 *!*/
/*  transition: transform .3s; !* 확대 효과에 0.3초의 전환 시간을 설정 *!*/
/*  box-shadow: 0 10px 5px rgba(0, 1, 1, 1);  !* 그림자 효과 추가 *!*/
/*}*/

.card:hover {
  transform: scale(1.03);
  transition: all .3s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.card:hover .card-text {
  color: #007bff; /* 텍스트 색상 변경 */
  font-weight: bold; /* 텍스트 강조 */
}

.card-body {
  line-height: 1; /* 원하는 줄 간격으로 조정합니다. */
}
</style>