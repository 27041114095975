<template>
  <div class="m-2">
    <header>
      <b-row class="align-items-center justify-content-center" style="margin-bottom: 10px;">
        <b-col cols="2" class="d-flex align-items-start">
          <img src="../assets/list.svg" id="v-step-0" @click="callSidebar" style="margin-left: 10%; "
               :style="{ width: sidebarWidth }"/>
        </b-col>
        <b-col cols="8" align-self="center">
          <b-navbar toggleable="lg" type="dark" variant="transparent"
                    style="text-align: center; align-self: center; align-items: center;">
            <b-navbar-nav class="mx-auto">
              <!--              <b-navbar-brand class="theholderz" href="/" @click="refreshPage">-->
              <!--&lt;!&ndash;                <img src="../assets/theholderz_logo.jpg" style="width: 80%;"/>&ndash;&gt;-->
              <!--                <B><H2>THEHOLDERZ</H2></B>-->
              <!--              </b-navbar-brand>-->
              <b-navbar-brand class="theholderz" href="/" @click="refreshPage">
                <span
                    style="font-family: 'Segoe UI Black', sans-serif; font-size: 28px; font-weight: bold; color: #333;"><img src="../assets/THEHOLDERZ_LOGO.png" height="50" width="50"/>THEHOLDERZ</span>
              </b-navbar-brand>
            </b-navbar-nav>

          </b-navbar>
        </b-col>
        <b-col cols="2" style="padding-top: 20px">
          <div class="dropdown text-end ">
            <div>
              <!-- data-bs-toggle="dropdown" aria-expanded="false"> -->
              <a class="d-block link-dark" >
                <img :src="thumbnailProfile ? thumbnailProfile : 'https://via.placeholder.com/70x70.png?text=Image'"
                     alt="User Profile" class="rounded-circle" id="profileImg"
                     :style="{ width: profileWidth}">
              </a>
<!--              <ul class="dropdown-menu">-->
<!--                <li><a class="dropdown-item" href="/#/profile"> Profile </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/wallet"> MyWallet </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/editor"> Shop Register </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/shopAdmin"> Shop Management </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/editorTrip"> Trip Register </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/admin"> admin </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                <li><a class="dropdown-item" href="/#/adminNftList"> adminNftList </a></li>-->
<!--                <li>-->
<!--                  <hr class="dropdown-divider">-->
<!--                </li>-->
<!--                &lt;!&ndash;              <li><a class="dropdown-item" href="#" @click="logout()">Sign out</a></li>&ndash;&gt;-->
<!--                <li v-if="isLoggedIn"><a class="dropdown-item" href="#" @click="logout()">Sign out</a></li>-->
<!--                <li v-else>-->
<!--                  <router-link to="/login" class="dropdown-item">Login</router-link>-->
<!--                </li>-->
<!--                &lt;!&ndash;              <li>&ndash;&gt;-->
<!--                &lt;!&ndash;                <hr class="dropdown-divider">&ndash;&gt;-->
<!--                &lt;!&ndash;              </li>&ndash;&gt;-->
<!--                &lt;!&ndash;              <li><a class="dropdown-item" href="#/ContactUs"> Contact us </a></li>&ndash;&gt;-->
<!--              </ul>-->
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div v-if!=$store.state.account.email class="align-items-end d-flex justify-content-end">
          <PRE><i class="bi bi-heart-fill" style="color:red;"></i> {{  point1 }}
              <i class="bi bi-star-fill" style="color:gold;"></i> {{ point2 }}
            {{ $store.state.account.email }}
          </pre>
        </div>
      </b-row>


      <b-row class="align-items-center justify-content-center">
        <b-col cols="11" class="d-flex">
          <b-button variant="light" @click="showHelpStep" class="me-0 border">
            <i class="bi bi-question-circle" style="margin-right: 0px;"></i>
          </b-button>
          <input type="search" v-model="searchText" class="form-control w-100" placeholder="검색어를 입력하세요..."
                aria-label="Search" @keyup.enter.prevent="search">
        </b-col>
        <b-col cols="1" class="d-flex" style="padding-left: 0px;">
          <b-button variant="light" @click="search" class="me-0 border">
            <i class="bi bi-search" style=""></i>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="align-items-center">
        <div class="container" id="navbar-container">
          <ul class="nav">
            <li class="nav-item" v-if="isLoggedIn"><a @click=myFunc(0) style="cursor:pointer;"
                                                      class="nav-link link-dark px-2">FAVORITE</a></li>
            <li class="nav-item" v-else>
              <router-link to="/login" class="nav-link link-dark px-2">FAVORITE</router-link>
            </li>
            <li class="nav-item"><a href="#" class="nav-link link-dark px-2 active" aria-current="page">ALL</a></li>
            <li class="nav-item"><a @click=myFunc(1) style="cursor:pointer;" class="nav-link link-dark px-2">미식</a></li>
            <li class="nav-item"><a @click=myFunc(2) style="cursor:pointer;" class="nav-link link-dark px-2">카페</a></li>
            <li class="nav-item"><a @click=myFunc(3) style="cursor:pointer;" class="nav-link link-dark px-2">서비스</a>
            </li>
            <li class="nav-item"><a @click=myFunc(4) style="cursor:pointer;" class="nav-link link-dark px-2">스포츠</a>
            </li>
            <li class="nav-item"><a @click=myFunc(5) style="cursor:pointer;" class="nav-link link-dark px-2">숙박</a></li>
            <li class="nav-item"><a @click=myFunc(6) style="cursor:pointer;" class="nav-link link-dark px-2">여행</a></li>
            <li class="nav-item"><a @click=myFunc(7) style="cursor:pointer;" class="nav-link link-dark px-2">의료</a></li>
            <li class="nav-item"><a @click=myFunc(8) style="cursor:pointer;" class="nav-link link-dark px-2">온라인</a>
            </li>
            <li class="nav-item"><a @click=myFunc(9) style="cursor:pointer;" class="nav-link link-dark px-2">기타</a></li>

<!--            <li class="nav-item">-->
<!--              <a href="#" class="nav-link link-dark px-2">-->
<!--                <router-link to="/editorTrip"> MEETUP </router-link>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a href="#" class="nav-link link-dark px-2">-->
<!--                <router-link to="/editorTrip"> HOLDER</router-link>-->
<!--              </a>-->
<!--            </li>-->
          </ul>
        </div>
      </b-row>


    </header>
  </div>
  <!-- begin SideBar-->
  <SideBar :closeOnClickOutSide="true" ref="sidebarRef" @item-click="callSidebar"/>
  <!-- endOf SideBar-->

  <!-- begin TourDiv -->
  <v-tour ref="myTour" name="myTour" v-if="showHelp" :steps="steps" :options="myOptions" @click="onSkip"></v-tour>
  <!-- endOf TourDiv -->

</template>

<script>
import store from "@/scripts/store";
import SideBar from '@/components/Sidebar.vue';
import {useCookies} from 'vue3-cookies'
import { useRoute } from 'vue-router';
import {onMounted, onBeforeUnmount, ref, reactive} from 'vue';
import axios from "../common/AxiosInterceptors";
import useAxiosPrivate from "@/common/AxiosPrivate";

const {cookies} = useCookies();
let currentPath = '';
let route = '';

export default {
  name: 'Header',

  components: {SideBar},
  computed: {
    isLoggedIn() {
      return this.$store.state.account.email !== "";
    },
    isMobile() {
      return window.innerWidth >= 650
    },
    profileWidth() {
      return this.isMobile ? '60px' : '60px'
    },
    sidebarWidth() {
      return this.isMobile ? '60px' : '60px'
    }
  },

  watch: {
    '$store.state.account.email': {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue || newValue === '') {
          this.fetchUserData();
        }
      },
    },
  },

  setup() {
    // set interval
    const intervalId = ref(null);

    //before redering
    onBeforeUnmount(() => {
      clearInterval(intervalId.value);
    });

    //after rendering
    onMounted(() => {
      store.dispatch('init');

      route = useRoute();
      currentPath = route.path;

      //checkCookieExpiration();
      // check cookie
      //intervalId.value = setInterval(checkCookieExpiration, 600000);
    });

    return {intervalId};
  },
  data() {
    return {
      searchText: '',
      // profile: this.$route.fullPath + "/shop/profile",
      profile: "/shop/profile",
      selectedImage: 'https://via.placeholder.com/50x50.png?text=Image',
      thumbnailProfile: 'https://via.placeholder.com/70x70.png?text=Image',
      point1: 0,
      point2: 0,
      showHelp : false,
      myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: '그만보기',
            buttonPrevious: '이전',
            buttonNext: '다음',
            buttonStop: '종료'
          }
        },
      steps: [ ]
    }
  },

  mounted() {

    this.fetchUserData();
    if( store.state.isHelp ){
      this.$tours['myTour'].start();
    }

  },

  methods: {
    async fetchUserData() {
      const state = reactive({
        items: [],
      })


      // Check if store.state.account.email is present
      if (store.state.account.email) {
        
        axios.get("/user/profile")
            .then(({data}) => {      
            store.commit('setRole', data.user_AUTH);
            store.commit('setUserInfo', data);
            //state.items = data;
            this.thumbnailProfile = data.thumbnailProfile;
            this.point1 = data.point1;
            this.point2 = data.point2;
          })
      }
    },

    // 도움말 next-step 존재여부
    isElement(){
      const element = document.querySelector('#v-step-1');
      return !element;
    },

    // 도움말 step create
    generateSteps() {

      // 현재 페이지에 맞는 도움말 스텝을 동적으로 생성하고 반환

      if( this.$store.getters.isLogin && !this.isElement() && route.path.includes("/") ){
          this.steps = [
           {
              target: '#v-step-0',
              header: {
                title: '처음이신가요?',
              },
              content: `메뉴를 누르고 <strong>로그인</strong>을 해주세요 :) `
            },
            {
              target: '#v-step-1',
              header: {
                title: 'Holderz Trends',
              },
              content: '핫한 NFT, Holder, Shop을 확인하실 수 있어요!'
              ,params: {
                placement: 'top'
              }
            },
            {
              target: '#v-step-2',
              header: {
                title: 'Shop`s Map',
              },
              content: '전국에 있는 상점들을 확인해보세요!'
            },
            {
              target: '#v-step-3',
              header: {
                title: 'Holder`s Shop',
              },
              content: '상점을 확인하고 좋아요+즐겨찾기를 해주세요!'
            }
          ]
      }else if( this.$store.getters.isLogin && this.isElement() ){
        this.steps = [
           {
              target: '#v-step-0',
              header: {
                title: '처음이신가요?',
              },
              content: `메뉴를 누르고 <strong>로그인</strong>을 해주세요 :) `
            }
          ]
      }else if( !this.$store.getters.isLogin && route.path.includes("editor") ){

        this.steps = [
            {
              target: '#v-step-1',
              header: {
                title: '상점 등록-1',
              },
              content: `점포명, 카테고리, 점포주소는 필수값이니 꼭 입력해주세요!`
              ,params: {
                placement: 'top'
              }
            },
             {
              target: '#v-step-2',
              header: {
                title: '상점 등록-2',
              },
              content: `어떤 NFT가 지원가능한지 콤마로 구분해서 넣어주세요~ <br> ex) 메타콩즈, Azuki, The Captainz`
              ,params: {
                placement: 'top'
              }
            },
            {
              target: '#v-step-3',
              header: {
                title: '상점 등록-3',
              },
              content: `혜택 대상 NFT를 입력해주세요 <br> 영문으로 입력했을때 <br> 상단에 주소값이 나오면 되요~ <br> P.S 안나올 경우, 직접 입력해야 해요 ㅠ`
            },
            {
              target: '#v-step-4',
              header: {
                title: '상점 등록-4',
              },
              content: `썸네일 사진을 등록하고 <br> 상점 내용 작성 후 <br> 저장하기 버튼을 누르면 끝! <br> P.S 상점은 관리자 승인후에 등록되요~ ^^`
            }
          ]
      }else{
        this.steps = [
             {
              target: '#v-step-0',
              header: {
                title: '상점은 등록하셨나요?',
              },
              content: `메뉴를 누르시면 <strong>상점등록</strong>이 가능해요! `
            }
          ]
      }


    },

    // 도움말 skip event
    onSkip(event){

      if( event.srcElement.textContent === '건너뛰기'){
        store.commit('setHelp', false);
      }
    },

    // 도움말 보여주기
    showHelpStep(){

      this.generateSteps();

      store.commit('setHelp', true);
      this.showHelp = true;
      //this.$tours['myTour'].start();
      this.$nextTick(() => {
        this.$tours['myTour'].start();
      });
      //this.$tours['myTour'].start();
    },

    myFunc(id) {
      this.$router.push('/homeCategory/' + id);
    },
    search() {

      console.log("searchText");
      console.log(this.searchText);

      // 새로운 라우팅 생성
      //router.push('/searchShop/q=?' + this.searchText );
      this.$router.push({name: 'searchShop', query: {q: this.searchText}});
    },
    // set logout
    logout() {
      store.dispatch('storeLogout');

      const cookies = document.cookie.split(';');

      cookies.forEach(cookie => {
        const [cookieName] = cookie.split('=').map(c => c.trim());
        const cookieDomain = '.theholderz.com';
        const cookiePath = '/';

        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cookiePath}; domain=${cookieDomain}; SameSite=None; Secure`;
      });

      this.$router.push({path: "/"});
      //this.$store.state.account = '';
      this.$store.state.account.email = ''; 
      this.thumbnailProfile = null;
    },
    checkExternalCookieExpiration() {
      const currentDate = new Date();
      const expiredDate = cookies.current_default_config.expireTimes;

      console.log('call checkCookieExpiration== ');

      if (currentDate > expiredDate || typeof cookies.get('x-auth-token') === 'undefined'
          || cookies.get('x-auth-token') === null || cookies.get('x-auth-token') === 'undefined') {
        this.logout();
      }
    },

    refreshPage() {
      this.$router.push({path: "/"});
    },

    callSidebar(event) {

      if (typeof event.name === 'string') {
        if (event.name.trim() === '로그아웃') {
          this.logout();
        } else if (event.name.trim() === 'Category') {
          return;
        }
      }
      this.$refs.sidebarRef.toggleSidebar();

    },

  }
}
</script>

<style scoped>
div {
  border: 0px solid black;
}

.row {
  margin: 0px;
}

.b-nav-item-dropdown .dropdown-menu li {
  display: none;
}

.navbar-expand-lg .navbar-collapse {
  flex-direction: row-reverse;
}

li.nav-item {
  text-align: right;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 500,
  'GRAD' 200,
  'opsz' 48
}

.theholderz {
  /*font-size: 2rem;*/
  /*font-weight: 999;*/
}

@media only screen and (max-width: 650px) {
  #profileImg {
    width: 100%;
  }
}

@media (max-width: 750px) {
  #navbar-container {
    display: none;
    justify-content: center;
  }
}

/* 추가한 부분 */
/*#profileImg:hover {*/
/*  !* 마우스 오버 시 원하는 효과를 적용하세요 *!*/
/*  !* 예시: 테두리 추가와 배경색 변경 *!*/
/*  !*border: 2px solid #333;*!*/
/*  !*background-color: #f0f0f0;*!*/
/*  transform: scale(1.03);*/
/*  transition: all .3s;*/
/*  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);*/
/*}*/
</style>
