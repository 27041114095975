<template>
  <div class="spinner-overlay" v-if="loading">
    <div class="spinner">
      <div class="spinner-dot-1"></div>
      <div class="spinner-dot-2"></div>
      <div class="spinner-dot-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-dot-1 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #5b7ea5;
  animation: pulse 1.5s ease-in-out infinite;
}

.spinner-dot-2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #0c6cda;
  animation: pulse 1.5s ease-in-out infinite;
}

.spinner-dot-3 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #0940f1;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
</style>
