import axios from './AxiosInstance';
import { useCookies } from 'vue3-cookies';
import store from "@/scripts/store";

/**
 *  axiosRefresh
 *  refresh toekn 처리를 위한 axios 별도구현
 *
 */
//use cookie
const { cookies } = useCookies();

const axiosRefreshToken = async (request) => {
    console.log( "=== call axiosRefreshToken ");

    //request.headers['x-auth-token'] = cookies.get('x-auth-token');
    try {
        const response = await axios.post('/user/refreshToken', {
            withCredentials: true
        },{
            headers: {
                'x-auth-token' : cookies.get('x-auth-token'),
                'refresh-id' : store.getters.getRefreshValue
            }
        }).then(response => {
            return response.headers['x-auth-token'];
        }).catch(error => {
            console.error(error);
        });
        return response;
    } catch(error){
        return error;
    }
}

export default axiosRefreshToken;