import axios from 'axios';

const instance = axios.create({
    //baseURL: "http://localhost:8081/shop",
    baseURL: process.env.VUE_APP_BASE_URL ,

    headers: {
      "content-type": "application/json;charset=UTF-8"
    },
    timeout: 10000,
    withCredentials: true
  });

export default instance;

