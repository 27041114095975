import axios from 'axios';
import { useCookies } from 'vue3-cookies'
import store from "@/scripts/store";
import router from "@/router";
import jwt_decode from 'jwt-decode';
import axiosRefreshToken from './AxiosRefresh';

/**
 *  AxiosPrivate
 *  access toekn 처리를 위한 axios interceptor 별도구현
 *
 */
//api객체로 관리
const privateInstance = axios.create({
  //baseURL: "http://localhost:8081",
  baseURL: process.env.VUE_APP_BASE_URL,

  headers: {
    "content-type": "application/json;charset=UTF-8"
  },
  timeout: 10000,
  withCredentials: true
});

const { cookies } = useCookies();

// REQUEST
const requestInterceptor = privateInstance.interceptors.request.use( (config) => {
  console.log('=== start private request' );
  store.commit('setLoading', true) // loading 값을 true로 설정

  // cookie hedaer setting
   if (typeof cookies.get('x-auth-token') === 'undefined' || cookies.get('x-auth-token') === null){
    return config;
  } else{
    config.headers['x-auth-token'] = cookies.get('x-auth-token');
    return config;
  }

}, async (error) => {
  // 2xx 외 에러 리턴
  store.commit('setLoading', false) // loading 값을 false로 설정
  return Promise.reject(error);
});

// RESPONSE
const responseInterceptor =  privateInstance.interceptors.response.use(
  (response) => {
    response
    store.commit('setLoading', false) // loading 값을 false로 설정
    return response;
  }
  ,
  async( error ) => {
    const prevRequest = error?.config;

    if( error?.response?.status === 401 && !prevRequest?.sent){
      if( isTokenExpired ){
        console.log('refresh g z a ');
        console.log(prevRequest);

        prevRequest.sent = true;
        const newAccessToken = await axiosRefreshToken();
        cookies.set('x-auth-token', newAccessToken );
        prevRequest.headers['x-auth-token'] = newAccessToken;

        store.commit('setLoading', false) // loading 값을 false로 설정
        return privateInstance(prevRequest);
      }
    }else{
      console.log('로그인은 필수입니다. (axiosError from response)');
      const errObj = {
          status : error?.response?.status,
          message : error?.response?.data.message,
          data : error?.response?.data
        }
      store.commit('setLoading', false) // loading 값을 false로 설정
      throw new Error(JSON.stringify(errObj));
      //return error?.response;
    }

return privateInstance;
}, async (error) => {  
  console.log(error);
  // 2xx 외 에러 리턴
  store.commit('setLoading', false) // loading 값을 false로 설정
  return Promise.reject(error);

});

// 토큰 만료 여부 체크 함수
const isTokenExpired = () => {
  // 토큰 만료일자와 현재 시간을 비교하여 만료된 경우 true 반환
  const tokenExpireDate = getTokenExpireDate();
  return tokenExpireDate && new Date() > tokenExpireDate;
}

// 토큰 만료일자를 가져와서 체크
const getTokenExpireDate = () => {

  const options = { timeZone: 'Asia/Seoul' };
  try{
    const tokenExpireDateString = cookies.get('x-auth-token').replace('Bearer ', '');
    let decodedJwt, expireDate = '';
    if( tokenExpireDateString ){
      decodedJwt = jwt_decode(tokenExpireDateString);
      expireDate = new Date(decodedJwt.exp * 1000) ;
    }

    console.log(expireDate);

    return new Date() > expireDate ? true : false;
  }catch (error){
    console.error(error);
    //alert('다시 로그인 부탁 드립니다 :) ' + error.message);
  }
}

// ejct request/ => refactory later - 23.02.26
if( requestInterceptor ){
  privateInstance.interceptors.request.eject(requestInterceptor);
}
if( responseInterceptor ){
  privateInstance.interceptors.respones.eject(responseInterceptor);
}

export default privateInstance;
