<template>
  <nav>
    <div class="nav-left">
      <a class="nav-item">
        <img src="logo.png">aaa
      </a>

      <ul class="nav">
        <li class="nav-item"><a href="#" class="nav-link link-dark px-2">
          <router-link to="/wallet"> my wallet </router-link>
        </a></li>

        <li class="nav-item"><a href="#" class="nav-link link-dark px-2">
          <router-link to="/replyBoard"> replyBoard </router-link>
        </a></li>


        <li class="nav-item"><a href="#" class="nav-link link-dark px-2">
          <router-link to="/tweet"> tweet </router-link>
        </a></li>

        <li class="nav-item"><a href="#" class="nav-link link-dark px-2">
          <router-link to="/scollMenu"> scollMenu </router-link>
        </a></li>

      </ul>
    </div>

    <div class="nav-right">
      <a class="nav-item" @click="showSidebar = !showSidebar">
        <span class="icon">
          <i class="fa fa-bars"> saefsafe</i>
        </span>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showSidebar: false,
    };
  },
};
</script>