<template>
  <div class="container">
    <footer class="py-0">
      <div class="row">
        <div class="col-6 col-md-2 mb-3">
          <div style="padding-top : 10px">
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-1517974331250342"
                 data-ad-slot="4956901256"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
          </div>

        </div>


        <!--        <div class="col-6 col-md-2 mb-3">-->
        <!--          <h5>Section</h5>-->
        <!--          <ul class="nav flex-column">-->
        <!--            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>-->
        <!--            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>-->
        <!--            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>-->
        <!--            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>-->
        <!--            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>-->
        <!--          </ul>-->
        <!--        </div>-->


      </div>

      <div>
        <ins class="kakao_ad_area" style="display:none;width:100%;"
             data-ad-unit    = "DAN-OyI4oydzEFfbq39R"
             data-ad-width   = "320"
             data-ad-height  = "100"></ins>
      </div>

      <div class="col-md-4 offset-md-1 mb-3 align-items-end">
        <p></p>
        <form>
          <h5>THEHOLDERZ.COM </h5>
          <p>홀더샵을 통한 새로운 정보를 메일로 받아보세요!</p>
          <div class="d-flex flex-column flex-sm-row w-100 gap-2">
            <label for="newsletter1" class="visually-hidden">Email address</label>
            <input id="newsletter1" type="email" class="form-control"  v-model="email" @input="validateEmail" placeholder="Email address">
            <button class="btn btn-primary" type="button" @click="onSubscribe" >Subscribe</button>
          </div>
        </form>
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between py-10 my-4 border-top">
        <p>&copy; 2023 THE HOLDERZ.COM Made by Holder's Community Company, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3"><a class="link-dark" href="#">twitter</a></li>
          <li class="ms-3"><a class="link-dark" href="#">instagram</a></li>
          <li class="ms-3"><a class="link-dark" href="#">facebook</a></li>
        </ul>
      </div>




    </footer>
  </div>
</template>


<script>
import useAxiosPrivate from "../common/AxiosPrivate";
import {mapState} from "vuex";

export default {
  components: {
  },
  name: 'Footer'
  , data(){
    return{
      email: '',
      isValidEmail: false
    }
  }
  , mounted() {
    if (typeof window !== 'undefined') {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
  methods: {

    onSubscribe() {
      // check email
      if( this.isValidEmail === false ){
        alert('이메일 형식이 올바르지 않습니다.');
        return;
      }
      if (confirm('구독 등록을 하시겠습니까?')) {
        const url = '/home/subEmail';
        const params = {
          email: this.email
        }
        useAxiosPrivate.post(url, params)
          .then(response => {
            console.log(response);
            if(response.data === "True"){
              alert('구독신청이 완료되었습니다. :)');
            }else{
              alert('이미 구독이 되어있거나, 잘못된 접근입니다. :(');
            }

          })
          .catch(error => {
            console.error(error);
          })


      }
    },
    validateEmail() {
      // 이메일 유효성 검사 로직
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.email);
    }
  }
}
</script>

<style scoped>
</style>