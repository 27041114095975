<template>
  <div class="table-responsive" v-if="topShops.length > 0">
    <b-tabs v-model="activeTab">
      <b-tab :key="'nft'">
        <template #title>
<!--          <b-spinner type="grow" small></b-spinner>-->
          <i> <strong> NFT </strong> </i>
        </template>
        <div class="scroll-container">
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">NFT명</th>
                  <th scope="col" class="sticky-header">활성이용수</th>
                  <th scope="col" class="sticky-header">탑 사용자</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in topShops.slice(0, 5)" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td class="align-middle">

<!--                    <router-link :to="`/pages/${shop.tabHolderProfile}`" class="bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">-->
<!--                      <img :src=shop.tabNftImage alt="OpenSea" style="width: 50px; height: 50px;">-->
<!--                    </router-link>-->

<!--                    <a :href="'https://opensea.io/collection/' + shop.tabNftEngName" target="_blank" class="bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">-->
                      <img :src="shop.tabNftImage" alt="OpenSea" style="width: 50px; height: 50px;">
<!--                    </a>-->
                  </td>
                  <!--                  <td class="align-middle">-->
                  <!--                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>-->
                  <!--                  </td>-->
                  <td class="align-middle">{{ shop.tabNftName }}</td>
                  <td class="align-middle">{{ shop.tabNftCnt }}</td>
                  <td class="align-middle">{{ shop.tabNftToken }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">NFT명</th>
                  <th scope="col" class="sticky-header">활성이용수</th>
                  <th scope="col" class="sticky-header">탑 사용자</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(shop, index) in topShops.slice(5, 10)" :key="index">
                  <th scope="row">{{ index + 6 }}</th>
                  <td class="align-middle">
<!--                    <a :href="'https://opensea.io/collection/' + shop.tabNftEngName" target="_blank" class="bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">-->
                      <img :src="shop.tabNftImage" alt="OpenSea" style="width: 50px; height: 50px;">
<!--                    </a>-->
                  </td>
                  <!--                  <td class="align-middle">-->
                  <!--                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>-->
                  <!--                  </td>-->
                  <td class="align-middle">{{ shop.tabNftName }}</td>
                  <td class="align-middle">{{ shop.tabNftCnt }}</td>
                  <td class="align-middle">{{ shop.tabNftToken }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </b-tab>

      <b-tab :key="'holder'">
        <template #title>
<!--          <b-spinner type="grow" small></b-spinner>-->
          <i> <Strong> Holder </Strong> </i>
        </template>

        <div class="scroll-container">
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">홀더명</th>
                  <th scope="col" class="sticky-header">이용수</th>
                  <th scope="col" class="sticky-header">최근사용</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in topShops.slice(0, 5)" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td class="align-middle">

<!--                    <router-link :to="`/pages/${shop.tabHolderProfile}`" class="rounded-1 bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">-->
                      <img :src=shop.tabHolderProfile alt="OpenSea" style="width: 50px; height: 50px;">
<!--                    </router-link>-->
                  </td>
<!--                  <td class="align-middle">-->
<!--                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>-->
<!--                  </td>-->
                  <td class="align-middle">{{ shop.tabHolderUserName }}</td>
                  <td class="align-middle">{{ shop.tabHolderCnt }}</td>
                  <td class="align-middle">{{ shop.tabHolderDate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">홀더명</th>
                  <th scope="col" class="sticky-header">이용수</th>
                  <th scope="col" class="sticky-header">최근사용</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in topShops.slice(5, 10)" :key="index">
                  <th scope="row">{{ index + 6 }}</th>
                  <td class="align-middle">
<!--                    <router-link :to="`/pages/${shop.tabShopId}`" class="rounded-1 bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">-->
                      <img :src=shop.tabThumbnailMain alt="OpenSea" style="width: 50px; height: 50px;">
<!--                    </router-link>-->
                  </td>
                  <td class="align-middle">
<!--                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>-->
                  </td>
                  <td class="align-middle">{{ shop.tabTotalUsage }}</td>
                  <td class="align-middle">{{ shop.tabUniqueUsers }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </b-tab>

      <b-tab :key="'shop'">
        <template #title>
<!--          <b-spinner type="grow" small></b-spinner>-->
          <i> <strong> Shop </strong> </i>
        </template>

        <div class="scroll-container">
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">상점명</th>
                  <th scope="col" class="sticky-header">혜택이용</th>
                  <th scope="col" class="sticky-header">홀더수</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in topShops.slice(0, 5)" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td class="align-middle">
                    <router-link :to="`/pages/${shop.tabShopId}`" class="rounded-1 bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">
                      <img :src=shop.tabThumbnailMain alt="OpenSea" style="width: 50px; height: 50px;">
                    </router-link>
                  </td>
                  <td class="align-middle">
                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>
                  </td>
                  <td class="align-middle">{{ shop.tabTotalUsage }}</td>
                  <td class="align-middle">{{ shop.tabUniqueUsers }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless table-fixed">
                <thead>
                <tr>
                  <th scope="col" class="sticky-header">순위</th>
                  <th scope="col" class="sticky-header"></th>
                  <th scope="col" class="sticky-header">상점명</th>
                  <th scope="col" class="sticky-header">혜택이용</th>
                  <th scope="col" class="sticky-header">홀더수</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in topShops.slice(5, 10)" :key="index">
                  <th scope="row">{{ index + 6 }}</th>
                  <td class="align-middle">
                    <router-link :to="`/pages/${shop.tabShopId}`" class="rounded-1 bg-light d-flex align-items-center justify-content-center" style="width: 50px; height: 50px;">
                      <img :src=shop.tabThumbnailMain alt="OpenSea" style="width: 50px; height: 50px;">
                    </router-link>
                  </td>
                  <td class="align-middle">
                    <router-link :to="`/pages/${shop.tabShopId}`" class="text-decoration-none link-hover">{{ shop.tabShopName }}</router-link>
                  </td>
                  <td class="align-middle">{{ shop.tabTotalUsage }}</td>
                  <td class="align-middle">{{ shop.tabUniqueUsers }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>


      </b-tab>


    </b-tabs>
  </div>
</template>
<script>
import axios from "@/common/AxiosInterceptors";

export default {
  name: "trends",
  data() {
    return {
      activeTab: 0,
      topShops: [],
      cachedData: {},
    };
  },
  created() {
    this.fetchTopShops('nft');
  },
  watch: {
    activeTab(newValue) {
      let tabType;

      switch (newValue) {
        case 0:
          tabType = "nft";
          break;
        case 1:
          tabType = "holder";
          break;
        case 2:
          tabType = "shop";
          break;
        default:
          return;
      }

      this.fetchTopShops(tabType);
    },
  },
  methods: {
    async fetchTopShops(tabType) {
      console.log("fetchTopShops");
      // 캐시를 사용하지 않도록 주석 처리
      // if (this.cachedData[tabType]) {
      //   this.topShops = this.cachedData[tabType];
      //   return;
      // }

      let endpoint;
      switch (tabType) {
        case 'nft':
          endpoint = '/home/nftTab';
          break;
        case 'shop':
          endpoint = '/home/shopTab';
          break;
        case 'holder':
          endpoint = '/home/holderTab';
          break;

        default:
          return;
      }

      try {
        const response = await axios.get(endpoint);
        this.topShops = response.data;
        // 캐시를 사용하지 않도록 주석 처리
        // this.cachedData[tabType] = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    onTabChanged(activeTab) {
      this.activeTab = activeTab;
      let tabType;

      switch (activeTab) {
        case 0:
          tabType = 'nft';
          break;
        case 1:
          tabType = 'shop';
          break;
        case 2:
          tabType = 'holder';
          break;

        default:
          return;
      }

      this.fetchTopShops(tabType);
    },
  },
}
</script>


<style scoped>

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
}

/* .table-wrapper {
  width: 90%;
} */

@media (min-width: 700px) {
  .table-wrapper {
    width: 50%;
  }
}

th {
  font-weight: normal;
  color: #707070;
}

th:first-child, td:first-child {
  text-align: center;
}

td img {
  border-radius: 30%;
  width: 30px;
  height: 30px;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}
.link-hover:hover {
  color: #007bff; /*링크에 마우스를 올렸을 때 텍스트 색상 변경*/
  text-decoration: none; /*링크에 밑줄 제거*/
  cursor: pointer; /*링크에 마우스 포인터 모양 변경*/
}
</style>