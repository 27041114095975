<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card class="text-center mb-4">
          <b-card-img :src="user.image" alt="User Image" top />
          <b-card-body>
            <b-card-title>{{ user.name }}</b-card-title>
            <b-card-text>{{ user.bio }}</b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card class="mb-4">
          <b-card-title>뱃지 리스트</b-card-title>
          <b-card-body>
            <b-row>
              <b-col md="4" v-for="(badge, index) in user.badges" :key="index">
                <b-card :img-src="badge.image" img-alt="Badge Image" img-top class="mb-4">
                  <b-card-title>{{ badge.name }}</b-card-title>
                  <b-card-text>{{ badge.description }}</b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        image: '', // 사용자 이미지 URL
        name: '', // 사용자 이름
        bio: '', // 사용자 소개
        badges: [] // 사용자 뱃지 리스트
      }
    };
  },
  mounted() {
    // 여기에서 사용자 데이터를 가져와서 user 객체에 할당하세요.
  }
}
</script>