<template>
  <div>
    <b-form @submit="onSubmit" @submit.stop.prevent>
      <label for="input-default">쿠폰사용여부</label>
      <b-form-checkbox
          id="checkbox-1"
          v-model="coupon.status"
          name="checkbox-1"
          value="Y"
          unchecked-value="N"
      >
        쿠폰 사용 등록 여부
      </b-form-checkbox>
      <div v-if="coupon.status === 'Y'">
        <label for="input-default">쿠폰이름</label>
        <b-form-input
            id="input-1"
            v-model="coupon.name"
            type="text"
            placeholder="쿠폰이름"
            required
        ></b-form-input>
        <label for="input-default">쿠폰혜택</label>
        <b-form-input
            id="input-2"
            v-model="coupon.id"
            type="text"
            placeholder="쿠폰혜택"
            required
        ></b-form-input>
        <label for="input-default">쿠폰발급가능횟수</label>
        <b-form-input
            id="input-3"
            v-model="coupon.limitCnt"
            type="number"
            required
        ></b-form-input>
        0을 입력할 경우 무제한
      </div>

      <br>
      <b-button type="submit" variant="primary">쿠폰 등록</b-button>
      <b-button @click="loginTest" variant="primary">loginTest</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from "../common/AxiosInterceptors";
import router from "../router/index";
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies();


export default {
 name: 'app'
    ,data() {
      return {
        coupon: {
          shopId: '',
          email: '',
          name: '',
          benefit: '',
          limitCnt: '',
          status: ''
        }
      }

  },
  methods: {
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.coupon));
        //
        //const url = axios.defaults.baseURL + '/user/login';
        const url = axios.defaults.baseURL + '/data/couponInfo';

        // 임시 데이터 설정
        this.coupon.shopId = '1';
        this.coupon.email  = '1';

        axios.post(url, this.coupon, { headers: { "Content-Type": 'application/json'} } )
            .then(function(response){
              alert('쿠폰 등록이 완료 되었습니다.');


              // home으로 이동
              //router.push('/');

              console.log(response);

            }).catch(function(error){

              console.log(error);
            });
      },
      loginTest(event){
          event.preventDefault;
          alert('로그인테스트.');

          const url = axios.defaults.baseURL + '/user/login';

          axios.post(url, this.coupon, { headers: { "Content-Type": 'application/json'} } )
            .then(function(response){
              alert('로그인테스트.');


              // home으로 이동
              //router.push('/');

              cookies.set('accessToken', '1');
              console.log(response);

            }).catch(function(error){

              console.log(error);
            });



      }
  }
}
</script>