<template>
  <div v-for="(reply, index) in replies" :key="index" class="tweet">
    <div class="tweet-inner">
      <img src="https://github.com/mdo.png" alt="Avatar" class="avatar">
      <div class="content">
        <div class="header">
          <!--<span class="replyNumber">{{ reply.replyNumber }}</span>-->
          <span class="name">{{ reply.userName }}</span>
          <div class="info-row">
            <span class="email">({{ reply.email }})</span>
            <span class="dot">·</span>
            <span class="createdAt">{{ formatDate(reply.createdAt) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text reply-content">{{ reply.content }}</div>
    <div class="footer">
      <div class="stats">
        <span class="stat"><strong>2</strong> Likes</span>
        <span class="stat"><strong>1</strong> Dislikes </span>
      </div>
      <div class="actions">
        <button @click="like" class="like-btn">
          <i class="fa fa-heart"></i>
        </button>
        <button @click="retweet" class="retweet-btn">
          <i class="fa fa-retweet"></i>
        </button>
        <button v-if="isLoggedIn && reply.email === $store.state.account.email" class="btn btn-sm btn-danger" @click="deleteReply(reply.replyNumber)">
          삭제
        </button>
      </div>
    </div>
  </div>
  <div v-if="isLoggedIn">
    <h5>댓글 작성</h5>
    <form>
      <div class="form-group">
        <label for="email">이메일</label>
        <input type="email" class="form-control" id="email" v-model="$store.state.account.email" disabled>
      </div>
      <div class="form-group">
        <label for="content">내용</label>
        <textarea class="form-control" id="content" rows="5" v-model="newReply.content" placeholder="내용을 입력하세요"></textarea>
      </div>
      <button type="submit" class="btn btn-primary" @click.prevent="createReply()">등록</button>
    </form>
  </div>
  <div v-else>
    <p>로그인 후에 댓글을 작성할 수 있습니다.</p>
  </div>
</template>

<script>
import axios from "../common/AxiosInterceptors";
import store from "@/scripts/store";
export default {
  data() {
    return {
      replies: [],
      sortBy: 'replyNumber',
      sortDesc: false,
      shopId: '1',
      newReply: {
        shopId: '1',
        email: '',
        userName: '',
        content: '',
      }
    };
  },
  methods: {
    fetchReplies() {
      axios.get(`/reply/${this.shopId}`)
          .then((response) => {
            this.replies = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    onRowClicked(item) {
      // 특정 댓글 클릭 시 동작하는 함수
      console.log(item);
    },
    formatDate(date) {
      const diff = new Date() - new Date(date);
      const diffMinutes = Math.round(diff / 60000);
      const diffHours = Math.round(diff / 3600000);
      if (diffMinutes < 60) {
        return `${diffMinutes}분 전`;
      } else if (diffHours < 24) {
        return `${diffHours}시간 전`;
      } else {
        return new Date(date).toLocaleDateString();
      }
    },
    createReply() {
      this.newReply.email = this.$store.state.account.email; // $store.state.account.email 값을 newReply.email에 대입
      axios.post('/reply/', this.newReply)
          .then((response) => {
            console.log(response);
            this.$router.go(); // 페이지 새로고침
          })
          .catch((error) => {
            console.error(error);
          });
    },
    deleteReply(replyNumber) {
      console.log("replyNumber->" + replyNumber);
      if (confirm('정말로 삭제하시겠습니까?')) {
        axios.get(`/reply/delete/${replyNumber}`)
            .then((response) => {
              console.log(response);
              this.$router.go(); // 페이지 새로고침
            })
            .catch((error) => {
              console.error(error);
            });
      }
    },
  },
  computed: {
    isLoggedIn() {
      return !!store.state.account.email;
    }
  },
  mounted() {
    this.fetchReplies();
  },
};
</script>

<style scoped>
.tweet {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s;
}

.tweet-inner {
  display: flex;
}


.tweet:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: -50px;
  margin-right: 15px;
}

.reply-content {

}


.content {
 flex: 1;
}

.header {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 1rem;
  font-weight: bold;
}

.email {
  font-size: 0.8rem;
  margin-left: 5px;
  color: #999;
}

.dot {
  margin: 0 5px;
  font-size: 1.2rem;
  color: #999;
}

.createdAt {
  font-size: 0.8rem;
  color: #999;
}

.text {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.stat {
  font-size: 0.8rem;
  margin-right: 10px;
  color: #999;
}

.like-btn,
.retweet-btn {
  background-color: #fff;
  border: none;
  padding: 5px;
  margin-right: 10px;
  font-size: 1.2rem;
  color: #999;
  cursor: pointer;
  transition: color 0.2s;
}

.info-row {
  display: block;
}
. {
  border : 1px;
}
</style>