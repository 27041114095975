<template>
  <spinner :loading="loading" />
  <div class="container">
    <div class="row align-items-center m-1">
      <div id="myProfile" class="w-100 m-auto">

        <div class="d-block link-dark text-decoration-none img-container" aria-expanded="false">
          <b-img class="rounded-circle img-responsive" thumbnail fluid
            v-bind:src="state.items?.thumbnailProfile || thumbSrc" alt="Fluid image" id="thumbImg"></b-img>
        </div>

        <!-- 이미지 업로드 버튼 -->
        <b-button @click="isModalVisible = true">프로필 이미지 수정</b-button>

        <b-modal v-model="isModalVisible" hide-footer>
          <ImageCropper @close="isModalVisible = false" @imageCropped="handleImageCropped" />
        </b-modal>

        <b-form>

          <input class="form-control" type="file" ref="fileInput" id="formFileMultiple" style="visibility: hidden;"
            @change.prevent="handleFileChange($event)" />
          <input type="text" id="thumbnailProfile" name="thumbnailProfile" v-model="state.items.thumbnailProfile"
            style="visibility: hidden;">

          <div class="d-flex justify-content-between align-items-center">
            <div class="form-floating">
              <h1 class="h3">내 프로필</h1>
            </div>
            <div>
              <!-- <b-button class="btn btn-primary align-items-center"  @click="$refs.fileInput.click()" style="align-items:center" plain>사진변경</b-button> -->
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <div class="form-floating">
              <input type="email" class="form-control" id="email" v-model="state.items.email" DISABLED readonly>
              <label for="floatingInput">Email address</label>
            </div>

            <div>
<!--              <button class="btn btn-primary align-items-center"> 중복체크</button>-->
              <div>
                <b-button size="lg" class="w-100" variant="outline-primary"  @click="showModal = true">패스워드 변경</b-button>
              </div>
              <b-modal v-model="showModal" title="패스워드 변경" @hide="showModal=false" ok-only ok-title="닫기">
                <findIdPassword/>
              </b-modal>
            </div>
          </div>

          <p></p>
<!--          <div class="form-floating">-->
<!--            <input type="password" class="form-control" id="userPwd" placeholder="Password">-->
<!--            <label for="floatingPassword">Password</label>-->
<!--          </div>-->


          <p></p>

          <div class="d-flex justify-content-between align-items-center">
            <div class="form-floating">
              <input type="text" class="form-control" id="userName" v-model="state.items.username">
              <!--               v-model="state.form.userName">-->
              <label for="floatingInput">Nickname</label>
            </div>
<!--            <div>-->
<!--              <button class="btn  btn-primary align-items-center"> 중복체크</button>-->
<!--            </div>-->
          </div>

          <p></p>
          <div class="form-floating">
            <input type="userLink" class="form-control" id="userLink" placeholder="userLink"
              v-model="state.items.userLink">
            <label for="floatingPassword">SNS LINK</label>
          </div>
          <p></p>

          <p></p>
          <div class="form-floating">
            <select v-model.number="state.items.userJob" class="form-select" id="floatingSelect" aria-label="Job"
              placeholder="Job">
              <option selected>Job Position</option>
              <option value="0">홀더</option>
              <option value="1">파운더</option>
              <option value="2">디자이너</option>
              <option value="3">개발자</option>
              <option value="4">아티스트</option>
              <option value="5">기획자</option>
              <option value="6">투자자</option>
              <option value="7">마케터</option>
              <option value="8">기타</option>
            </select>
            <label for="floatingSelect">My Job</label>
<!--            <div> value : {{ state.items.userJob }}</div>-->
          </div>
          <p></p>
          <div class="form-floating">
            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 300px"
              v-model="state.items.userDescription"></textarea>
            <label for="floatingTextarea2">내 프로필 소개</label>
          </div>

          <p></p>

          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
              v-model="state.items.userProfileVisibleYn" :true-value="'Y'" :false-value="'N'">
            <label class="form-check-label" for="flexSwitchCheckChecked">Profile 공개여부 {{ state.items.userProfileVisibleYn }}
            </label>
          </div>


          <div class="w-100 h-auto d-flex justify-content-end">
            <button class="btn  btn-primary" @click=updateSummit()>저장하기</button>
          </div>


          <p></p>

          <p></p>
          <br>

          <h1 class="h3 mb-3 fw-normal">내 지갑정보 </h1>
          <div>
            <div class="form-floating">
              <input type="metaMask" class="form-control" id="walletEth" placeholder="Metamask"
                v-model="state.items.walletEth" disabled>
              <label for="floatingPassword">Metamask Wallet Address</label>
            </div>
          </div>
          <div>
            <p></p>
<!--          {{isWalletLoggedin1}}-->
            <button v-if="!isWalletLoggedin1" class="btn btn-primary align-items-center" @click="loginWithMetamask">
              Connect Wallet
            </button>
            <button v-else class="btn btn-outline-danger align-items-center" @click="logoutFromMetamask"> Disconnect Wallet
            </button>
          </div>

          <p></p>


          <!--        <div>-->
          <!--          <div class="form-floating">-->
          <!--            <input type="kaikas" class="form-control" id="walletKaikas" placeholder="Kaikas"-->
          <!--                   v-model="state.items.walletKaikas" disabled>-->
          <!--            <label for="floatingPassword">Kaikas Wallet Address</label>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <div>-->
          <!--          <button class="btn btn-primary d-flex justify-content-end"> Connect Wallet</button>-->
          <!--        </div>-->


          <div class="d-flex justify-content-end align-items-end">
            <b-button size="lg"  variant="danger">회원탈퇴</b-button>
          </div>

        </b-form>





        <p class="mt-5 mb-3 text-muted">&copy; 2017–2023 </p>

      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, ref } from "vue";
import axios from "../common/AxiosInterceptors";
import useAxiosPrivate from "../common/AxiosPrivate";
import store from "@/scripts/store";
import router from "@/router";
import profile from "@/assets/profile.png";
import { mapState } from 'vuex';
import { useCookies } from 'vue3-cookies';
// import 'vue-croppa/dist/vue-croppa.css';
// import Croppa from 'vue-croppa-next'
// import VueCroppie from 'vue-croppie'
// import 'croppie/croppie.css'
import ImageCropper from "@/components/ImageCropper.vue";
import findIdPassword from "@/views/findIdPassword.vue";


const { cookies } = useCookies();

export default {
  name: "Profile",
  components: { ImageCropper, findIdPassword },
  data() {
    return {
      showModal: false,
      // form upload
      formObject: {
        thumbnailProfile : ''
        ,email : ''
        ,username : ''
        ,userName : ''
        ,userLink : ''
        ,userJob : ''
        ,userDescription : ''
        ,userProfileVisibleYn : ''
        ,walletEth : ''
      },
      // file upload
      editorInfo: {
        isThumbUploaded: false
      },
      userAccount: null,
      // isWalletLoggedin1: null,
      isWalletLoggedin2: null,
      apiKey: "0lcakJMu1vL4fvm0eE6vw1Ni3TzQAqDF",
      thumbSrc: profile,
      //myCroppa: null,
      imgUrl: '',
      imageUrl: null,
      croppedImage: null,
      isModalVisible: false,


    };
  },
  computed: {
    ...mapState(['loading'])
  },
  mounted() {
    // radio data 초기 set
    // this.picked = this.radioList[0];
  },
  setup() {
    const state = reactive({
      items: [],
    })

    const isWalletLoggedin1 = ref(false); // 추가: isWalletLoggedin1을 ref로 선언

    const logout = () => {
      store.dispatch('storeLogout');
      cookies.keys().forEach(element => {
        cookies.remove(element);
      });
    }

    useAxiosPrivate.get("/user/profile").then(({ data }) => {
      state.items = data;

      if (state.items.walletEth) {
        isWalletLoggedin1.value = true; // 수정: walletEth 값이 있으면 isWalletLoggedin1 값을 true로 설정
      }


    }).catch((error) => {
      console.log(error);
      window.alert("프로필을 수정하시려면 로그인을 해주세요.");
      logout();
      router.push({ path: "/login" });
    });

    // set Croppa

    return {
      state, isWalletLoggedin1
    }
  },
  methods: {

    // update profile
    updateSummit() {
      // 임시 set
      this.state.items.userNames = this.state.items.username;

      useAxiosPrivate.post('/user/profileUpdate/' + store.state.account.email, this.state.items,  { headers: { "Content-Type": 'application/json' } })
        .then((res) => {
          alert("회원정보가 수정되었습니다.");
          this.refreshPage();

        }).catch((error) => {
          alert('수정중 에러가 발생했습니다.');
          console.log(error);
        })
    },
    handleImageCropped(file) {
      // crop된 이미지 파일을 처리하는 코드
      console.log("handleImageCropped");
      console.log(file);
      this.corpedFileUpload(file);
    },

    // 이더리움 지갑 업데이트
    ethWalletChange() {
      console.log(store.state.account.email);
      console.log(this.state.items);

      axios.post('/user/walletUpdate/' + store.state.account.email, this.state.items).then((res) => {
        window.alert("Metamask 지갑주소가 수정되었습니다.");
        router.push('/profile');
        console.log(res);

      }).catch(function (error) {
        alert('수정중 에러가 발생했습니다.');
        console.log(error);
      })
    },
    async loginWithMetamask() {
      console.log("====================");
      console.log(window.ethereum);

      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask and try again.');
        return;
      }

      // Check if the user has Metamask installed and unlocked
      if (window.ethereum) {
        // Request account access if needed
        window.ethereum.enable()
          .then((accounts) => {

            if (accounts.length > 0) {
              console.log('Logged in with Metamask account:', accounts[0])
              this.userAccount = accounts[0];

              this.isWalletLoggedin1 = true;
              this.state.items.walletEth = accounts[0];

              this.ethWalletChange(); // 이더리움 지갑 업데이트
            }
          }).catch((error) => {
            console.error(error);
          })

        // Connect to Metamask provider
        // const web3 = new Web3(window.ethereum);'
        // const accounts = web3.eth.getAccounts();

      } else {
        alert("metamask is not installed or unlocked.");
        console.error('Metamask is not installed or unlocked.');
      }
    },
    logoutFromMetamask() {
      // Clear the connected account
      window.ethereum.enable();

      // Clear the web3 instance
      this.web3 = null
      this.isWalletLoggedin1 = false;
      this.state.items.walletEth = "";
      alert("Logged out from Metamask.");
      console.log('Logged out from Metamask.');
    },
    // 썸네일 파일 업로드 이벤트
    handleFileChange(event) {
      event.preventDefault;
      const url = '/data/uploadThumbFile';
      if (event.target.files && event.target.files[0]) {
        // 썸네일 이미지 검증 후, 서버 전송
        const file = event.target.files[0],
          fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          fileExtention = file.name.split(".").pop(),
          fileName = file.name.split(".").shift(),
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

        console.log(fileSize, fileExtention, fileName, isImage);

        if (fileSize > 1 || !isImage) {
          alert('파일사이즈가 너무 크거나 이미지 파일이 아닙니다. (파일 사이즈 제한: 1MB) \n 현 파일 사이즈 : '
            + fileSize + " MB");
          return false;
        } else {
          // update thumb file
          const form = new FormData();

          form.append("file", file);
          form.append("Filetype", "PROFILE_");
          useAxiosPrivate.post(url, form, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((res) => {

            this.editorInfo.isThumbUploaded = true;
            this.thumbSrc = res.data[0].downloadUrl;
            this.state.items.thumbnailProfile = res.data[0].downloadUrl;

          }).catch((error) => {
            this.editorInfo.isThumbUploaded = false;
            console.log(error);
          })

        }
      }

    },
    // Corpped 파일 업로드 이벤트
    corpedFileUpload(file) {
      const url = '/data/uploadThumbFile';
      if (file) {
        // 썸네일 이미지 검증 후, 서버 전송
        const fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          fileExtention = file.name.split(".").pop(),
          fileName = file.name.split(".").shift(),
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

        console.log(fileSize, fileExtention, fileName, isImage);

        if (fileSize > 1 || !isImage) {
          alert('파일사이즈가 너무 크거나 이미지 파일이 아닙니다. (파일 사이즈 제한: 1MB) \n 현 파일 사이즈 : '
            + fileSize + " MB");
          return false;
        } else {
          // update thumb file
          const form = new FormData();

          form.append("file", file);
          form.append("Filetype", "PROFILE_");
          useAxiosPrivate.post(url, form, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((res) => {

            this.editorInfo.isThumbUploaded = true;
            this.thumbSrc = res.data[0].downloadUrl;
            this.state.items.thumbnailProfile = res.data[0].downloadUrl;
            alert('선택이 완료 되었습니다. ');
            // 모달 창 닫기
            this.isModalVisible = false;

          }).catch((error) => {
            this.editorInfo.isThumbUploaded = false;
            alert('선택에 실패 했습니다.\n관리자에게 문의해주세요. ');
            // 모달 창 닫기
            this.isModalVisible = false;
          })

        }

      }

    },
    refreshPage() {
      this.$router.push({ path: "/Profile" });
    },
    handleFileUpload(event) {
      this.imageUrl = URL.createObjectURL(event.target.files[0])
    },
    cropImage() {
      this.$refs.croppie.result({ type: 'blob' }).then(blob => {
        this.croppedImage = URL.createObjectURL(blob)
      })
    }
  }
}
</script>

<style scoped>
/* .container {
  width: 1200px;
} */

#myProfile {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  padding-left: 0%;
}

.img-thumbnail {
  width: 35%;
  height: auto;
}

/* @media only screen and (max-width: 600px) {
  #myProfile {
    width: 100%;
  }
} */

/* .form-signin {
  max-width: 1000px;
  padding: 0px;
  margin: 0px auto;
  /*border: solid 0.5px;
} */
</style>
