<template>

  <div class="album py-5 bg-light">
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        <div class="col" v-for="(item, idx) in state.items" :key="idx">
          <card :item="item"/>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Card from '../components/card.vue';
import axios from "../common/AxiosInterceptors";
// import useAxiosPrivate from "../common/AxiosPrivate";
import {reactive, watch} from "vue";
import {useRoute} from "vue-router";
import store from "@/scripts/store";

export default {
  name: 'app',
  data() {
    return {
      msg: "This is demo net work"
    }
  },
  components: {
    Card,
  },

  setup() {
    const categoryMap = {
      1: "미식",
      2: "카페",
      3: "서비스",
      4: "스포츠",
      5: "숙박",
      6: "여행",
      7: "의료",
      8: "온라인",
      9: "기타",
    };

    const state = reactive({
      items: [],
    });

// fetchData() 함수를 만들어서 비동기적으로 데이터를 요청
    const fetchData = async () => {
      let url = '/home/homeCategory/' + route.params.id;

      if (store.state.account.email) {
        url += `?email=${store.state.account.email}`;
      }
      console.log("urlurl->" + url)
      const { data } = await axios.get(url);
      state.items = data;
    };

    const route = useRoute();
    console.log("HomeCategoryssssssss" + route.params.id);
    fetchData();

    // route.params.id가 변경될 때마다 fetchData()를 호출하여 데이터를 다시 불러오도록 watch 사용
    watch(
        () => route.params.id,
        async (newVal) => {
          fetchData();
        }
    );

    // watch()를 사용하여 state.items가 변경될 때마다 categoryMap을 이용하여 item.shopCategory를 변환
    watch(() => state.items, () => {
      for (const item of state.items) {
        const category = item.shopCategory;
        item.shopCategory = categoryMap[category];
      }
    });

    return { state };
  }
}

</script>

<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 80%) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

img {
  display: block;
  margin: 0px auto;
}

@media (max-width: 600px) {
  .card-img-top {
    position: center;
    width: 100%;
    height: 100px;
  }
}

.card-img-top {
  position: center;
  width: 100%;
  height: 100%;
  max-height: 500px;

  object-fit: cover;
  object-position: 55%;
}
</style>