<template>
         <div id="Admin" class="w-100 m-auto">
            <div>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                    <a class="nav-link" :class="{ active: activeTab === 1 }" @click="changeTab(1)">상점관리</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" :class="{ active: activeTab === 2 }" @click="changeTab(2)">템플릿관리</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane" :class="{ active: activeTab === 1 }" role="tabpanel">
                        <!-- 1번탭의 내용 및 기능 구현 -->
                        <div>
                            <b-table
                                :items="tbItems"
                                :fields="fields"
                                responsive="sm"
                                ref="selectableTable"
                                selectable
                                @row-selected="onRowSelected"
                                @row-unselected="onRowUnselected"
                                >

                            </b-table>
                        </div>
                    <button @click="approveShop('S')">승인</button>
                    <button @click="approveShop('P')">보류</button>
                    <button @click="approveShop('D')">삭제</button>
                    </div>
                        <!-- END OF 1번탭  -->

                    <div class="tab-pane" :class="{ active: activeTab === 2 }" role="tabpanel">
                        <!-- BEGIN 2번탭  -->

                        <b-form-group
                            label="Select Template:"
                            label-for="table-select-mode-select"
                            label-cols-md="4"
                            >
                            <b-form-select
                                id="table-select-mode-select"
                                v-model="selectMode"
                                :options="modes"
                                v-on:change="getShopInfo"
                                class="mb-3"
                            ></b-form-select>
                            </b-form-group>
                        <b-form-group id="input-group-3" label=" Template Title" label-for="input-1" >
                            <b-form-input id="tempTitle" v-model="formObject.tempTitle" placeholder="title을 입력하세요"></b-form-input>
                        </b-form-group>

                        <ckeditor :editor="editor" v-model="editorData" @ready="onReady" :config="config" name="shopCK"></ckeditor>
                        <div style="text-align: center;">
                            <b-button @click="onSubmit" >저장하기</b-button>
                        </div>

                        <!-- END OF 2번탭  -->

                    </div>
                </div>
            </div>
        </div>
  </template>

  <script>

  import useAxiosPrivate from "../common/AxiosPrivate";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import CKEditor from '@ckeditor/ckeditor5-vue';
  import router from "../router/index";
  import store from "@/scripts/store";

  const sampleText = '<p>==== 신규상점 ====</p>';
  export default {
    components: { 'ckeditor': CKEditor.component },

    data() {
      return {
        value: [ ],
      editor: ClassicEditor,
      editorData: sampleText,
      config: {
        //language: 'ko',
        toolbar: {
          items: [
            'heading',
            '|',
            'fontFamily',
            'fontColor',
            'fontSize',
            '|',
            'bold',
            'italic',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            //'todoList',
            //'link',
            //'blockQuote',
            //'imageUpload',
            'insertTable',
            '|',
            'undo',
            'redo'
          ]
        }
      },
            modes: ['email', 'kakao']
            ,
            activeTab: 1 // 현재 선택된 탭 번호
            ,
            fields: ['state', 'shopName', 'email', 'createdAt', 'shopId']
            ,
            tbItems: []
            ,
            selected : [] // 선택된 항목을 관리할 배열
            ,
            selectMode: 'email'

            ,
            formObject: {
                  tempCode: ''
                , tempTitle: ''
                , tempContent: ''
                , tempSender: ''
                , inppId: ''
            }
          };
    },
    mounted() {
        this.getShopList();
    },
    setup(){
    }
    ,
    methods: {
           onReady(editor) {
            // UploadAdapter 생성자호출
            // editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                // return new UploadAdapter(loader)
            // }
          },
          onSubmit(event) {
              event.preventDefault();
              const url = '/admin/templateMessage';

              // set TemplateContent
              this.formObject.tempContent = this.editorData;
              this.formObject.tempCode = this.selectMode;
              this.formObject.tempSender = 'theholderz.com';
              this.formObject.inppId = store?.state?.account?.email || '';

              console.log('-====final ');
              console.log(this.selectMode);
              console.log(this.formObject);

              if (this.formObject.tempTitle && this.formObject.tempContent) {
                  useAxiosPrivate.put(url, this.formObject, { headers: { "Content-Type": 'application/json' } })
                      .then((response) => {
                          alert('저장 되었습니다.');
                          console.log(response);
                          // refreshPage
                          this.refreshPage();
                      }).catch((error) => {
                          alert('저장 중 오류가 발생했습니다. 관리자에게 문의해주세요')
                          console.error(error);
                          // refreshPage
                          this.refreshPage();
                      })
              } else {
                  alert('필수값이 비어있습니다.');
              }

          },

          approveShop(state) {
              // 1번탭에서 상점 승인 기능 구현
              const url = 'data/shopCondtion';

              switch (state) {
                  case 'S':
                      this.selected.state = 'S';
                      break;
                  case 'P':
                      this.selected.state = 'P';
                      break;
                  case 'D':
                      this.selected.state = 'D';
                      break;
                  default:
                      break;
              }

              useAxiosPrivate.put(url, this.selected)
                  .then((response) => {
                      alert('성공-reload\n' + response);
                      this.refreshPage();
                  })
                  .catch((error) => {
                      alert('실패-관리자권한 확인\n' + error);
                  });
          },
          // select박스 선택 (shop데이터)
          getShopInfo(event) {
              // clearData
              this.clear();

              if (event !== null) {
                  const url = '/admin/templateMessage/' + event;
                  this.selectedId = event;

                  useAxiosPrivate.get(url)
                      .then(response => {
                        console.log(response.data);
                          // clearData
                          this.clear();
                          // set formObject
                          this.formObject = response.data;
                          this.editorData = response.data.tempContent;
                          //this.thumbSrc = response.data.thumbnailMain;
                      })
                      .catch(error => {
                          console.error(error);
                      })
              }
          },
          getShopList() {
              const url = 'data/shopList';

              useAxiosPrivate.get(url)
                  .then((response) => {
                      console.log('getShopList');
                      console.log(response);

                      if (response.data.length > 0) {
                          for (let i = 0; i < response.data.length; i++) {
                              let data = response.data[i];
                              this.tbItems.push({
                                  state: data.state,
                                  shopName: data.shopName,
                                  email: data.email,
                                  createdAt: data.createdAt,
                                  shopId: data.shopId

                              });
                          }
                      }
                      this.shopList = response.data;
                  })
                  .catch((error) => {
                      console.log(error);
                  });
          },
          changeTab(tabNumber) {
              this.activeTab = tabNumber;
              if( tabNumber === 2 ) {
                  this.getShopInfo('email');
              }
          },
          clearSelected() {
              this.$refs.selectableTable.clearSelected()
              this.selected = [];
          },
          onRowSelected(items) {
              console.log(items);
              this.selected = items;
          },
          onRowUnselected(items) {
              console.log(items);
              this.selected = [];
          },
          /**************************************************
                * 사용자 이벤트 함수 ( 버튼클릭, 탭 이동 등)
          *************************************************/
          //clear display
          clear() {
              this.editorData = '';
              this.formObject = {};
          },
          //페이지 새로고침 메서드
          refreshPage() {
              window.location.reload();
          },

        }
  };
  </script>

  <style scoped>
  #Admin {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: left;
      width: 1200px;
      color: #2c3e50;
      padding-left: 0%;
  }

  </style>