<template>
<!--  <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">-->
  <metainfo></metainfo>
  <Header/>
  <router-view>
  </router-view>
  <Footer/>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { useMeta } from 'vue-meta'

export default {
  // head() {
  //   return {
  //     script: [
  //       {
  //         async: true,
  //         src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
  //         dataAdClient: "1517974331250342"
  //       },
  //       // 다른 스크립트 태그
  //     ]
  //   };
  // },
  name: 'app',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      // title: 'THEHOLDERZ',
      isLoading : false,
    }
  },
  // 생성 시 실행될 함수 추가
  // created: function() {
    // document.title 속성 변경
    // document.title = this.title;
  // },
  setup() {
    useMeta(
        {
          script: [
            {
              async: true,
              src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
              dataAdClient: "1517974331250342"
            },
            {
              async: true,
              src: '//t1.daumcdn.net/kas/static/ba.min.js'
            },
            // {
            //   async: true,
            //   src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAnzP1vekWK5s9uK51dUwYy2y-mvtgcmqY&callback=initMap'
            // }
            // {
            //   async: true,
            //   src: 'https://www.googletagmanager.com/gtag/js?id=G-4SDNNTJ3JC'
            // }
            // 다른 스크립트 태그
          ]
        }
    )
  },
}
</script>
<style>
  body {
        display: flex;
        justify-content: center;
          align-items: center;
  }

#app {
  overflow: hidden;
  width: 1200px;
}

.element {
  touch-action: none;
}
</style>
