<template>
  <div class="align-items-center m-2">

    <div>
      <!--      <div class="text-center text-lg-start">-->
      <!--        <h1 class="display-4 fw-bold lh-1 mb-3 text-center">ABOUT : 더홀더즈 </h1>-->
      <!--&lt;!&ndash;        <p class="fs-4 text-center">프로젝트 커뮤니티의 활성화를 지원합니다.</p>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p class="fs-4 text-center">홀더의 사업을 성장 시켜드립니다.</p>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p class="fs-4 text-center">NFT 소유자의 혜택을 알립니다.</p>&ndash;&gt;-->
      <!--      </div>-->
      <br>
      <h4 class="m-2"><B> THEHODLERZ : 더홀더즈 </B></h4>
      <hr class="featurette-divider">
      <p class="fs-5 text-center">NFT 프로젝트 커뮤니티의 활성화를 지원하는 플랫폼을 지향합니다.</p>
      <p class="fs-5 text-center">IP를 활용한 홀더의 자발적 오프라인 유틸리티 제공을 도와드립니다.</p>
      <p class="fs-5 text-center">NFT 소유자의 다양한 혜택과 WEB3문화와 즐거움 번영시키고자 합니다.</p>

      <br>

      <h4 class="m-2"><B> HOLDERZ TEAM </B></h4>
      <hr class="featurette-divider">
      <div class="row">
        <a href="#" class="d-block link-dark text-decoration-none" aria-expanded="false">
          <img src="https://data.quadhash.io/images/reveal/1.png" alt="mdo" width="200" height="200"
               class="rounded-circle">
        </a>
        <p class="text-center"><b>BigSuh.eth</b></p>
        <!--      <p class="text-center">Developer </p>-->
        <div>
          <a href="#" class="d-block link-dark text-decoration-none" aria-expanded="false">
            <img src="https://data.quadhash.io/images/reveal/1.png" alt="mdo" width="200" height="200"
                 class="rounded-circle">
          </a>
          <p class="text-center"><b>IMS5987</b></p>
          <!--      <p class="text-center">Developer </p>-->

          더 홀더즈와 함께 하실 분을 모십니다. @theholderz
        </div>

        <p></p>

      </div>

      <h4 class="m-2"><B> HOLDERZ FUTURE </B></h4>
      <hr class="featurette-divider">

      <div class="row custom-row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        <div class="col-lg-4">
          <!--      <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">-->
          <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-people-fill"
               viewBox="0 0 16 16">
            <path
                d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>

          </svg>
          <h2 class="fw-bold">HOLDER</h2>
          <p>홀더의 활동과 웹3 생태계를 촉신시키고 응원합니다. 웹3의 문화와 홀더의 개인의 가치와 자산을 존중합니다.</p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-shop"
               viewBox="0 0 16 16">
            <path
                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>


          </svg>
          <h2 class="fw-bold">SHOP</h2>
          <p>홀더의 사업과 비즈니스를 성장 시켜드립니다. 간편한 홀더인증 서비스로 NFT 홀더 혜택을 제공하고 누릴 수 있습니다.</p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-images"
               viewBox="0 0 16 16">
            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
            <path
                d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>


          </svg>
          <h2 class="fw-bold">NFT</h2>
          <p>프로젝트의 커뮤니티 활성화를 지원하고 후원합니다.</p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-coin"
               viewBox="0 0 16 16">
            <path
                d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
          </svg>


          <h2 class="fw-bold">ALPHA</h2>
          <p> WHITELIST / RAFFLE EVENT 제공합니다.</p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-bank"
               viewBox="0 0 16 16">
            <path
                d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
          </svg>

          <h2 class="fw-bold">GOVERNANCE</h2>
          <p>프로젝트의 거버넌스와 DAO 활성화를 위한 VOTING 플랫폼을 지원하고 독려합니다.</p>
        </div><!-- /.col-lg-4 -->
      </div><!-- /.row -->

    </div>


    <p></p>
    <!--    <div>-->
    <!--      <a href="#" class="d-block link-dark text-decoration-none" aria-expanded="false">-->
    <!--        <img src="https://i.seadn.io/gae/l0IJVuPoVdkLqk8nhi6Xcliid1qFuAbYWUQQay7VCEz6u_YNUS6NozGl46AKQquLN3nHsZ8aNFoDrNFq1P0defurWPhTVflKVRA_2h8?auto=format&w=1000" alt="mdo" width="200" height="200" class="rounded-circle">-->
    <!--      </a>-->
    <!--      <p class="text-center"><b>StormBringer</b></p>-->
    <!--      <p class="text-center">Cheif Technology Officer </p>-->
    <!--    </div>-->
  </div>


</template>

<script>
export default {
  name: "test.vue",
  data() {
    return {
      modalShow: false
    }
  }
}
</script>

<style scoped>

</style>

