<template>
  <!-- <VueDaumPostcode :options="options" /> -->

   <div>
      <template v-if="!isOpen">
        <!-- <b-button size="sm" @click="isOpenEvent">우편번호검색</b-button> -->
      </template>
      <template v-else>
        <VueDaumPostcode @complete="onComplete" />
      </template>
    </div>
    <!-- <div>
      <pre v-if="result && !isOpen">{{ result }}</pre>
    </div> -->

</template>
<script>
import { VueDaumPostcode } from 'vue-daum-postcode';

export default {
  name: "KakaoAddr",
  data(){
    return {
      result : '',
      isOpen : '',
      // address object
      addrObject: {
        address : '',       // 지번주소
        roadAddress: '',    // 도로명주소
        buildingName : '',  // 빌딩명
        zonecode: ''
      }
    }
  },
  components: {
    VueDaumPostcode, // export VueDaumPostcode is component
  },
  methods: {
      isOpenEvent(){
        if( !this.isOpen ){
          this.isOpen = true;
        }
      },
      onComplete(result){
        this.addrObject = result;
        this.isOpen = false;
        this.$emit("setAddr", this.addrObject, 'from kakao');
      },
  }
}
</script>