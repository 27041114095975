

<template>
  {{ $store?.state?.account.email }}
  <div class="table-container">
    <!--    striped
    hover-->
    <b-table
        striped
        fixed responsive
        :items="replies"
        :fields="fields"
        v-model:sort-by="sortBy"
        v-model:sort-desc="sortDesc"
        @row-clicked="onRowClicked"
        :sticky-header="true"
    >
      <template #cell(createdAt)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(delete)="data">
        <button v-if="isLoggedIn && data.item.email === $store.state.account.email" class="btn btn-sm btn-danger" @click="deleteReply(data.item.replyNumber)">
          삭제
        </button>
      </template>
<!--      <template #cell(delete)="data">-->
<!--        <button class="btn btn-sm btn-danger" @click="deleteReply(data.item.replyNumber)">-->
<!--          삭제-->
<!--        </button>-->
<!--      </template>-->
    </b-table>
  </div>

  <div v-if="isLoggedIn">
    <h5>댓글 작성</h5>
    <form>
      <div class="form-group">
        <label for="email">이메일</label>
        <input type="email" class="form-control" id="email" v-model="$store.state.account.email" disabled>
      </div>
<!--      <div class="form-group">-->
<!--        <label for="userName">작성자</label>-->
<!--        <input type="text" class="form-control" id="userName" v-model="newReply.userName" placeholder="작성자를 입력하세요">-->
<!--      </div>-->
      <div class="form-group">
        <label for="content">내용</label>
        <textarea class="form-control" id="content" rows="5" v-model="newReply.content" placeholder="내용을 입력하세요"></textarea>
      </div>
      <button type="submit" class="btn btn-primary" @click.prevent="createReply()">등록</button>
    </form>
  </div>
  <div v-else>
    <p>로그인 후에 댓글을 작성할 수 있습니다.</p>
  </div>


</template>

<script>
import axios from "../common/AxiosInterceptors";
import store from "@/scripts/store";
export default {
  data() {
    return {
      replies: [],
      fields: [
        {key: 'replyNumber', label: '', sortable: false},
        // {key: 'email', label: '이메일', sortable: true},
        {key: 'userName', label: '', sortable: false},
        {key: 'content', label: ''},
        {key: 'like', label: '', sortable: false},
        {key: 'dislike', label: '', sortable: false},
        {key: 'createdAt', label: '', sortable: false},
        // {key: 'updatedAt', label: '수정일자', sortable: true},
        {key: 'delete', label: '', sortable: false}
      ],
      sortBy: 'replyNumber',
      sortDesc: false,
      shopId: '1',
      newReply: {
        shopId: '1',
        email: '',
        userName: '',
        content: '',
      }
    };
  },
  methods: {
    fetchReplies() {
      axios.get(`/reply/${this.shopId}`)
          .then((response) => {
            this.replies = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    onRowClicked(item) {
      // 특정 댓글 클릭 시 동작하는 함수
      console.log(item);
    },
    // formatDate(date) {
    //   // 날짜 포맷팅 함수
    //   return new Date(date).toLocaleDateString();
    // },
    formatDate(date) {
      const diff = new Date() - new Date(date);
      const diffMinutes = Math.round(diff / 60000);
      const diffHours = Math.round(diff / 3600000);
      if (diffMinutes < 60) {
        return `${diffMinutes}분 전`;
      } else if (diffHours < 24) {
        return `${diffHours}시간 전`;
      } else {
        const newDate = new Date(date.replace(/-/g, "/"));
        return newDate.toLocaleDateString("ko-KR");
        //return new Date(date).toLocaleDateString();
      }
    },
    createReply() {
      this.newReply.email = this.$store.state.account.email; // $store.state.account.email 값을 newReply.email에 대입
      axios.post('/reply/', this.newReply)
          .then((response) => {
            console.log(response);
            this.$router.go(); // 페이지 새로고침
          })
          .catch((error) => {
            console.error(error);
          });
    },
    deleteReply(replyNumber) {
      console.log("replyNumber->" + replyNumber);
      if (confirm('정말로 삭제하시겠습니까?')) {
        axios.get(`/reply/delete/${replyNumber}`)
            .then((response) => {
              console.log(response);
              alert('삭제 되었습니다.');
              this.$router.go(); // 페이지 새로고침
            })
            .catch((error) => {
              console.error(error);
            });
      }
    },
  },
  computed: {
    isLoggedIn() {
      return !!store.state.account.email;
    }
  },
  mounted() {
    this.fetchReplies();
  },
};
</script>