<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar bg-light">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar" aria-controls="sidebar" @click="showSidebar = !showSidebar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse d-none d-md-block">
          <ul class="navbar-nav flex-row">
            <li class="nav-item"><a @click=myFunc(1) style="cursor:pointer;" class="nav-link link-dark px-2">미식</a></li>
            <li class="nav-item"><a @click=myFunc(2) style="cursor:pointer;" class="nav-link link-dark px-2">카페</a></li>
            <li class="nav-item"><a @click=myFunc(3) style="cursor:pointer;" class="nav-link link-dark px-2">서비스</a></li>
            <li class="nav-item"><a @click=myFunc(4) style="cursor:pointer;" class="nav-link link-dark px-2">스포츠</a></li>
            <li class="nav-item"><a @click=myFunc(5) style="cursor:pointer;" class="nav-link link-dark px-2">숙박</a></li>
            <li class="nav-item"><a @click=myFunc(6) style="cursor:pointer;" class="nav-link link-dark px-2">여행</a></li>
            <li class="nav-item"><a @click=myFunc(7) style="cursor:pointer;" class="nav-link link-dark px-2">의료</a></li>
            <li class="nav-item"><a @click=myFunc(8) style="cursor:pointer;" class="nav-link link-dark px-2">온라인</a></li>
            <li class="nav-item"><a @click=myFunc(9) style="cursor:pointer;" class="nav-link link-dark px-2">기타</a></li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Sidebar -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel" :class="{ 'show': showSidebar }">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="sidebarLabel"> NFT-HOLDERS.SHOP </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="showSidebar = false"></button>
      </div>

      <div class="offcanvas-body">
        <ul class="list-unstyled ps-0">
          <li class="mb-1">
            <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false" @click="activeItem = 'home'">
              CATEGORY
            </button>

            <div class="collapse show" id="home-collapse" :class="{ 'show': activeItem === 'home' }">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><a href="#" class="link-dark rounded">미식</a></li>
                <li><a href="#" class="link-dark rounded">카페</a></li>
                <li><a href="#" class="link-dark rounded">서비스</a></li>
                <li><a href="#" class="link-dark rounded">스포츠</a></li>
                <li><a href="#" class="link-dark rounded">숙박</a></li>
                <li><a href="#" class="link-dark rounded">여행</a></li>
                <li><a href="#" class="link-dark rounded">의료</a></li>
                <li><a href="#" class="link-dark rounded">온라인</a></li>
                <li><a href="#" class="link-dark rounded">기타</a></li>
              </ul>
            </div>
          </li>

          <li class="mb-1">
            <a href="#" class="btn btn-toggle align-items-center rounded collapsed">
              로그아웃
            </a>
          </li>
          <li class="mb-1">
            <a href="#" class="btn btn-toggle align-items-center rounded collapsed">
              샵 등록
            </a>
          </li>
          <li class="mb-1">
            <a href="#" class="btn btn-toggle align-items-center rounded collapsed">
              화이트 페이퍼
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSidebar: false,
      activeItem: '',
    };
  },
};
</script>

<style scoped>
.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-collapse {
  justify-content: center;
}

.offcanvas {
  width: 230px !important;
}
</style>
