<template>
  <spinner :loading="loading"/>

  <div class="form-signin w-75 m-auto">
    <!--    <img class="mb-4" src="/src/assets/logo.png" alt="" width="72" height="57">-->
    <h1 class="h3 mb-3 fw-normal">회원가입</h1>

<!--      <pre>-->
<!--      emailExists {{ emailExists }}-->
<!--      emailFormat {{ emailFormat }}-->
<!--      passwordsMatch {{ passwordsMatch }}-->
<!--      </pre>-->

    <form @submit.prevent="login">

      <div class="form-floating">
        <input type="nickname" class="form-control" id="userName" placeholder="NickName"
               v-model="state.form.userName">
        <label for="floatingInput">Nickname</label>
      </div>
      <p></p>

      <div class="form-floating">
        <!--        <input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="state.form.email" @change="checkEmail"  :class="{ 'is-invalid': emailExists === true, 'is-valid': emailExists === false }">-->
        <input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="state.form.email"
               @change="checkEmail"
               :class="{ 'is-invalid': emailFormat === true || (emailExists !== null && emailExists === true), 'is-valid': emailFormat === false && emailExists === false }"
               required>
        <label for="floatingInput">Email address</label>
        <!--       <div class="invalid-feedback">-->
        <!--          이메일 형식이 올바르지 않습니다.-->
        <!--        </div>-->
      </div>
      <div v-if="emailFormat !== null"></div>
      <div v-if="emailFormat" style="color: red;">이메일 형식이 올바르지 않습니다</div>
      <div v-if="emailExists !== null">
        <div v-if="emailExists" style="color: red;">중복된 이메일입니다.</div>
        <div v-else style="color: green;">회원가입 가능한 이메일입니다.</div>
      </div>
      <p></p>
      <div class="form-floating">
        <input type="password" class="form-control" id="userPwd" placeholder="Password"
               v-model="state.form.userPwd"
               :class="{ 'is-invalid': !isPasswordValid, 'is-valid': isPasswordValid }"
               required>
        <label for="floatingPassword">Password</label>
        <div v-if="isPasswordValid !== null">
          <div v-if="isPasswordValid" style="color: green;">올바른 형식의 패스워드입니다.</div>
          <div v-else style="color: red;">패스워드는 최소 8자리 문자열 이상이어야 하며, 특수문자, 알파벳문자, 숫자를 반드시 1개이상 포함해야 합니다.</div>
        </div>
      </div>
      <p></p>
      <div class="form-floating">
        <input type="password" class="form-control" id="userPwdConfirm" placeholder="Confirm Password"
               v-model="state.form.userPwdConfirm"
               :class="{ 'is-invalid': passwordsMatch  === false , 'is-valid': passwordsMatch  === true }"
               required>
        <label for="floatingPasswordConfirm">Confirm Password</label>
      </div>
      <div v-if="passwordsMatch !== null">
        <div v-if="passwordsMatch === true" style="color: green;">패스워드가 일치합니다.</div>
        <div v-if="passwordsMatch === false" style="color: red;">패스워드가 일치하지 않습니다.</div>
      </div>




      <p></p>
      <div class="d-flex justify-content-between align-items-center w-auto">
        <div class="w-100 h-auto">
          <button class="btn btn-lg btn-primary" type="submit" @click=submit() :disabled="!isFormValid">회원가입</button>
        </div>
      </div>
    </form>

    <p class="mt-5 mb-3 text-muted">&copy; 2017–2023</p>

  </div>

</template>

<script>
import {reactive, ref, watch} from "vue";
import axios from "../common/AxiosInterceptors";
import router from "@/router";
import {mapState} from 'vuex'

export default {
  name: 'app',
  data() {
    return {
      isLoading: true
    }
  }
  ,

  setup() {
    const state = reactive({
      form: {
        email: "",
        userPwd: "",
        userName: "",
        userPwdConfirm: ""
      }
    })

    const emailExists = ref(null);
    const emailFormat = ref(null);
    const passwordsMatch = ref(null); // Add a ref to track if passwords match
    const isPasswordValid = ref(null); // Add a ref to track if the password is valid

    const submit = () => {
      if (passwordsMatch.value === false) { // Check if the passwords match before submitting
        window.alert("패스워드가 일치하지 않습니다.");
        return;
      }

      if (emailExists.value === true) { // Check if the passwords match before submitting
        window.alert("중복된 이메일입니다.");
        return;
      }

      axios.post("/user/join", state.form).then((res) => {
        console.log(res);

        // API 응답 데이터를 확인해 이메일 중복 여부를 판별합니다.
        if (res.data.emailExists) {
          emailExists.value = true;
        } else {
          window.alert("회원가입이 되었습니다. 로그인페이지로 이동합니다.");
          router.push("/login");
        }
      });
    };

    let timerId = null;
    const checkEmail = async () => { // 이메일 중복 체크를 위한 비동기 함수
      // 이메일 유효성 검사
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(state.form.email)) {
        emailFormat.value = true;
        emailExists.value = null; // emailExists의 값을 null로 초기화
        return;
      }

      // 이메일 중복 검사
      try {
        const response = await axios.get(`/user/check-email?email=${state.form.email}`);
        emailExists.value = response.data;
        emailFormat.value = false; // emailFormat의 값을 false로 초기화
      } catch (error) {
        console.error(error);
      }
    };

    // watch(() => emailExists, (newVal, oldVal) => {
    //   console.log(`emailExists 값이 변경되었습니다: ${oldVal} -> ${newVal}`);
    // });

    // watchEffect(() => {
    //   checkEmail();
    // });

    /*
      최소 8자 이상의 문자열: 만족 (12자 이상)
      최소한 하나의 알파벳 문자: 만족 (N 포함)
      최소한 하나의 숫자: 만족 (1 포함)
      최소한 하나의 특수 문자: 만족 (! 포함)
      알파벳, 숫자, 특수 문자로만 구성된 문자열: 부적합 (^ 문자 포함)
     */
    watch(
        () => state.form.userPwd,
        (newValue) => {
          isPasswordValid.value = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#?&])[A-Za-z\d@$!%*^#?&]{8,}$/.test(newValue);
          console.log("watch -> isPasswordValid.value");
          console.log(isPasswordValid.value);
        }
    );


    watch(
        () => state.form.email,
        (newValue) => {
          clearTimeout(timerId); // 이전의 타이머를 제거합니다.

          if (newValue) { // 입력이 있는 경우에만 타이머를 설정합니다.
            timerId = setTimeout(() => {
              checkEmail();
            }, 2000);
          }
        }
    );

    watch(
        () => [state.form.userPwd, state.form.userPwdConfirm],
        ([userPwd, userPwdConfirm]) => {
          // Check if the passwords match
          console.log(userPwd);
          console.log(userPwdConfirm);
          passwordsMatch.value = userPwd === userPwdConfirm;
        }
    );

    return {state, isPasswordValid, emailExists, emailFormat, passwordsMatch, submit}
  },
  computed: {
    chkState() {
      return this.state.form.email && this.state.form.userPwd && this.state.form.userName && !this.state.emailExists;
    },
    isFormValid() {
      return (
          this.state.form.email &&
          this.state.form.userPwd &&
          this.state.form.userName &&
          !this.emailExists &&
          this.passwordsMatch &&
          this.isPasswordValid // Include the password validation check
      );
    },
    ...mapState(['loading'])
  }
};
</script>

<style scoped>
.form-signin {
  max-width: 80%;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.is-invalid {
  color: red;
}

.is-valid {
  color: green;
}
</style>
