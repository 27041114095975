import {createApp, ref} from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import App from '@/App.vue'
import router from '@/router'
import VueCookies from 'vue3-cookies';
import store from '@/scripts/store';
import VueDaumPostcode from 'vue-daum-postcode'
import Vue3TouchEvents from "vue3-touch-events";
import Spinner from './components/Spinner.vue'

import VueDatePicker from '@vuepic/vue-datepicker';
import {createMetaManager} from 'vue-meta'
import VueGtag from 'vue-gtag-next'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Vue3Tour from 'vue3-tour'

//import { ValidationProvider } from 'vee-validate';
//import { required } from 'vee-validate/dist/rules';

// 부트스트랩 설정
import BootstrapVue3 from 'bootstrap-vue-3'
import FormPlugin from 'bootstrap-vue-3'
import VueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'material-icons/iconfont/material-icons.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-tour/dist/vue3-tour.css'
import io from 'socket.io-client';
import { useCookies } from 'vue3-cookies'

const app = createApp(App)
    .use(BootstrapVue3, FormPlugin)
    .use(CKEditor)
    .use(router)
    .use(store)
    .use(VueCookies, {  // 쿠키 만료일 1일
        expireTimes: '1d',
        secure: true,
        httpOnly: false,
        sameSite: 'None',
        path: "/",
        domain: '.theholderz.com'
    })
    .use(VueDaumPostcode)
    .use(Vue3TouchEvents)
    .use(VueAwesomeSidebar)
    .component('spinner', Spinner)
    .component('VueDatePicker', VueDatePicker)
    .use(createMetaManager())
    .use(VueGtag, {
        property: {
            id: 'G-4SDNNTJ3JC',
        },
    }, router)
     .use(VueGoogleMaps, {
         load:
             {
                 key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
             },
     })
     .use(Vue3Tour)

app.config.globalProperties.$loading = true; // true로 설정
app.mount('#app')