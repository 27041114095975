<template>
    <div id="myEditor" class="w-90 m-auto">

        <div>
            <b-form>
                <b-form-group id="v-step-1" label="점포명 :" label-for="input-1" ref="shopName"
                    description="점표명은 메인에 표기됩니다." valid-feedback="입력 되었습니다." required :state="isValid(formObject.shopName)">

                    <b-form-input id="input-1" v-model="formObject.shopName" placeholder="점포명을 입력하세요"
                        :state="isValid(formObject.shopName)"></b-form-input>
                </b-form-group>


                <b-form-group id="input-group-2" label="카테고리" label-for="input-2" description="" valid-feedback="입력 되었습니다."
                    required ref="" :state="isValid(formObject.shopCategory)">

                    <b-form-select id="input-2" v-model="formObject.shopCategory" placeholder="상점 카테고리를 선택해주세요."
                        :state="isValid(formObject.shopCategory)">
                        <option value="" selected> 선택해주세요.</option>
                        <option value="1">미식</option>
                        <option value="2">카페</option>
                        <option value="3">서비스</option>
                        <option value="4">스포츠</option>
                        <option value="5">숙박</option>
                        <option value="6">여행</option>
                        <option value="7">의료</option>
                        <option value="8">온라인</option>
                        <option value="9">기타</option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="input-group-3" label="점포 주소(실제 지도주소)" label-for="input-3" valid-feedback="입력 되었습니다."
                    required :state="isValid(formObject.shopAddress)">
                    <div class="d-flex">
                        <b-form-input id="input-3" v-model="formObject.shopAddress" placeholder="점포 주소를 입력하세요"
                            :state="isValid(formObject.shopAddress)" class="flex-grow-1"></b-form-input>
                        <b-button @click="onFindAddr" size="sm">주소찾기</b-button>
                    </div>
                    <address-vue ref="addressVue" @setAddr="handleAddr" />


                </b-form-group>

                <b-form-group id="input-group-4" label="점포 주소URL :" label-for="input-4">

                    <b-form-input id="input-4" v-model="formObject.shopLocation" placeholder="주소URL을 입력하세요"></b-form-input>
                    <b-form-valid-feedback id="input-feedback-2" class="valid-feedback">
                        입력 되었습니다.
                    </b-form-valid-feedback>
                </b-form-group>

                <b-form-group id="input-group-5" label="홈페이지 주소 :" label-for="input-5">
                    <b-form-input id="input-5" v-model="formObject.urlHomepage"
                        placeholder="매장 홈페이지 주소를 입력하세요"></b-form-input>
                </b-form-group>


                <b-form-group id="input-group-6" label="매장 네이버 URL 주소 :" label-for="input-6">
                    <b-form-input id="input-6" v-model="formObject.urlMap"
                        placeholder="매장 네이버 URL 주소를 입력하세요"></b-form-input>
                </b-form-group>

                <div id="v-step-2">
                    <label for="tags-separators">NFT 지원태그 </label>
                    <b-form-tags input-id="tags-separators" v-model="hashValue" separator=" ,;"
                        placeholder="태그를 입력후 스페이스 또는 콤마, 세미콜론을 입력하면 추가됩니다." no-add-on-enter></b-form-tags>
                </div>


                <b-form-group id="input-group-8" label="매장 대표메뉴 :" label-for="input-8" valid-feedback="입력 되었습니다." required>
                    <b-form-input id="input-8" v-model="formObject.itemName"
                        placeholder="예) 아메리카노, 리버 뷰 숙박권, 한식 반상, 헤어 펌, 헬스 등록권 등"></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-9" label="대표메뉴 가격" label-for="input-9" valid-feedback="입력 되었습니다.">
                    <b-form-input id="input-9" v-model="formObject.itemPrice" placeholder="예) 4500"></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-10" label="대표메뉴 할인율" label-for="input-10" valid-feedback="입력 되었습니다.">
                    <b-form-input id="input-10" v-model="formObject.itemDiscount"
                        placeholder="예) 10% -> 10 입력"></b-form-input>
                </b-form-group>


                <div id="v-step-3">
                    지원하는 NFT컨트랙 주소 (최대 3개 입력 가능)
                    <div>
                        <div class="pl-1" >
                            Selected NFT1's addr: <span v-if="selectednft1">{{ selectednft1.nftAddr }}</span>
                        </div>
                        <vue-typeahead-bootstrap class="mb-4" v-model="nftInput" :data="nftTempList"
                            :serializer="items => items.nftEng" placeholder="NFT Contract #1"
                            highlightClass="special-highlight-class" @hit="onClickNft($event, 1)"
                            @input="searchNft($event, 1)" inputClass="special-input-class" @keyup.enter="handleEnter">
                            <template v-slot="{ nftTempList }">
                                <div class="d-flex align-items-center">
                                    <p>{{ nftTempList.nftEng }} {{ nftTempList.nftAddr }}</p>
                                </div>
                            </template>

                        </vue-typeahead-bootstrap>
                    </div>
                </div>

                <div>
                    <div id="nft2" class="form-floating">
                        <div class="pl-1">
                            Selected NFT2's addr: <span v-if="selectednft2">{{ selectednft2.nftAddr }}</span>
                        </div>
                        <VueTypeaheadBootstrap class="mb-4" v-model="nftInput" :data="nftTempList"
                            :serializer="items => items.nftEng" placeholder="NFT Contract #2" prepend="" inputName="sdfsdf"
                            @hit="onClickNft($event, 2)" @input="searchNft($event, 2)" @keyup.enter="handleEnter">
                            <template v-slot="{ nftTempList }">
                                <div class="d-flex align-items-center">
                                    <p>{{ nftTempList.nftEng }} {{ nftTempList.nftAddr }}</p>
                                </div>
                            </template>

                        </VueTypeaheadBootstrap>
                    </div>
                </div>

                <div>
                    <div class="form-floating">
                        <div class="pl-1">
                            Selected NFT3's addr: <span v-if="selectednft3">{{ selectednft3.nftAddr }}</span>
                        </div>
                        <VueTypeaheadBootstrap id="nft3" class="mb-4" v-model="nftInput" :data="nftTempList"
                            :serializer="items => items.nftEng" placeholder="NFT Contract #3" prepend=""
                            @hit="onClickNft($event, 3)" @input="searchNft($event, 3)" @keyup.enter="handleEnter">
                            <template v-slot="{ nftTempList }">
                                <div class="d-flex align-items-center">
                                    <p>{{ nftTempList.nftEng }} {{ nftTempList.nftAddr }}</p>
                                </div>
                            </template>

                        </VueTypeaheadBootstrap>
                    </div>
                </div>


                <b-form-group id="v-step-4" label="대표 이미지 :" label-for="input-6">
                    <b-img class="uploadimg" thumbnail v-bind:src="thumbSrc" alt="Fluid image" id="thumbImg"></b-img>
                    <input class="form-control" type="file" id="formFileMultiple"
                        @change.prevent="handleFileChange($event)" />
                    <div class="valid-feedback" v-if="editorInfo.isThumbUploaded">
                        썸네일 업로드 완료!!
                    </div>
                    <p v-if="editorInfo.isThumbUploaded" style="color: red;">썸네일 업로드 완료!!</p>

                </b-form-group>
            </b-form>
        </div>


    </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-bootstrap-typeahead';
import AddressVue from "../components/kakaoAddress.vue"
import useAxiosPrivate from "../common/AxiosPrivate";
import { reactive, watch, debounce } from "vue";
import logo from "@/assets/no-pictures.png";

export default {
    name: 'editorFileds',
    components: {
        VueTypeaheadBootstrap, AddressVue
    },
    setup() {
        const nftList = reactive({
            items: []
        });

        const fetchData = async () => {
            console.log("editor fetchdata--->");
            let url = '/data/nftlist';

            const { data } = await useAxiosPrivate.get(url);

            nftList.items = data;
            console.log(nftList);

        };

        fetchData().catch((e) => {
            console.log(e);
        });

        return { nftList };
    },

    // props: {
    //     formObject : {
    //         type: Object,
    //         required: true
    //     }
    // },

    data() {
        return {
            // file upload
            editorInfo: {
                isThumbUploaded: false
            }
            , hashValue: []

            // form upload
            , formObject: {
                shopId: ''
                , email: ''
                , shopCategory: ''
                , shopName: ''
                , shopContent: ''
                , shopLocation: ''
                , shopAddress: ''
                , urlHomepage: ''
                , urlMap: ''
                , thumbnailMain: ''
                , thumbnailSub: ''
                , itemName: ''
                , itemPrice: ''
                , itemDiscount: ''
                , shopHashtag: ''
                , inppId: ''
                , shopNft1: ''
                , shopNft2: ''
                , shopNft3: ''
                , shopNft4: ''
                , shopNft5: ''
            }
            , thumbSrc: logo

            , nftTempList: []
            , nftInput: ''
            , selectednft1: []
            , selectednft2: []
            , selectednft3: []
        }
    },
    methods: {
        /**************************************************
        * 서버 이벤트 함수
        *************************************************/
        // 썸네일 파일 업로드 이벤트
        handleFileChange(event) {
            event.preventDefault;
            const url = '/data/uploadThumbFile';
            if (event.target.files && event.target.files[0]) {
                // 썸네일 이미지 검증 후, 서버 전송
                const file = event.target.files[0],
                    fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
                    fileExtention = file.name.split(".").pop(),
                    fileName = file.name.split(".").shift(),
                    isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

                console.log(fileSize, fileExtention, fileName, isImage);

                if (fileSize > 1 || !isImage) {
                    alert('파일사이즈가 너무 크거나 이미지 파일이 아닙니다. (파일 사이즈 제한: 1MB) \n 현 파일 사이즈 : '
                        + fileSize + " MB");
                    return false;
                } else {
                    // update thumb file
                    const form = new FormData();

                    form.append("file", file);
                    form.append("Filetype", "THUMB_");
                    useAxiosPrivate.post(url, form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then((res) => {

                        this.editorInfo.isThumbUploaded = true;
                        this.formObject.thumbnailMain = res.data[0].downloadUrl;
                        this.thumbSrc = res.data[0].downloadUrl;

                        console.log(res.data[0].downloadUrl);

                    }).catch((error) => {
                        this.editorInfo.isThumbUploaded = false;
                        console.log(error);
                    })

                }
            }

        },

        /**************************************************
        * 사용자 이벤트 함수 ( 버튼클릭, 탭 이동 등)
        *************************************************/
        // validate form data
        validateInput() {

            const tartgetArr = ['shopName', 'shopCategory', 'shopContent', 'shopAddress']

            for (let key in this.formObject) {
                if (tartgetArr.includes(key)) {
                    if (!this.isValid(this.formObject[key])) {
                        console.log(`no value for ${key}`);
                        return false;
                    }
                }
            }

            // All is good
            return true;
        },

        isValid(value) {
            return value.length > 0;
        },
        // call kakaoAddr
        onFindAddr() {
            this.$refs.addressVue.isOpenEvent();
        },
        // set address
        handleAddr(addrObj) {
            console.log(addrObj);
            this.formObject.shopAddress = `${addrObj.roadAddress} ${addrObj.buildingName}`;
        },

        onComplete(result) {
            this.addrObject = result;
            this.isOpen = false;
            this.$emit("setAddr", this.addrObject, 'from kakao');
        },

        getChildObject() {
            let returnObj = {};
            returnObj.hashValue = [];
            returnObj.hashValue = this.hashValue;
            returnObj.formObject = this.formObject;
            this.$emit("setChildObject", returnObj, 'from child');
        },

        handleEnter() {
            console.log("handleEnter");
        },
        // search nft
        searchNft(event, value) {
            this.nftTempList = this.nftList.items.filter((item) => item.nftEng.includes(this.nftInput));
            console.log(value);
            console.log(event.target.value);
            if (value === 1) {
                this.formObject.shopNft1 = event.target.value;
                this.selectednft1.nftAddr = event.target.value;
            } else if (value === 2) {
                this.formObject.shopNft2 = event.target.value;
                this.selectednft2.nftAddr = event.target.value;
            } else if (value === 3) {
                this.formObject.shopNft3 = event.target.value;
                this.selectednft3.nftAddr = event.target.value;
            }
            // after filtering, set the temp list to the original list
            this.nftTempList = this.nftList.items;

        },
        // onclick event
        onClickNft(item, value) {
            console.log('item' + " " + value);
            console.log(item);
            if (value === 1) {
                this.formObject.shopNft1 = item.nftAddr;
                this.selectednft1.nftAddr = item.nftAddr;
            } else if (value === 2) {
                this.formObject.shopNft2 = item.nftAddr;
                this.selectednft2.nftAddr = item.nftAddr;
            } else if (value === 3) {
                this.formObject.shopNft3 = item.nftAddr;
                this.selectednft3.nftAddr = item.nftAddr;
            }
        }

    }
}
</script>

<style scoped>


@media (max-width: 600px) {
    .img-thumbnail {
    width: 60%;
    height: auto;
  }
}
@media (max-width: 1300px) {
    .img-thumbnail {
    width: 30%;
    height: auto;
  }

}

.img-thumbnail {
    width: 40%;
    height: auto;
}
.pl-1{
    font: bold;
    color: coral;
}

</style>