<template>

  <div v-for="(reply, index) in limitedReplies" :key="index" class="tweet">
<!--    <div v-for="(reply, index) in replies" :key="index" class="tweet">-->
    <div class="tweet-inner">
      <img v-if=reply.thumbnailProfile :src=reply.thumbnailProfile alt="Avatar" class="avatar">
      <img v-else :src="'https://github.com/mdo.png'" alt="Avatar" class="avatar">
      <div class="content">
        <div class="header">
          <!--<span class="replyNumber">{{ reply.replyNumber }}</span>-->
          <span class="name">{{ reply.userName }}</span>
          <span class="email">({{ reply.email }})</span>
<!--          <span class="name" @click="showEmail(reply.email)" @mouseover="showEmail(reply.email)" @mouseleave="hideEmail()">{{ reply.userName }}</span>-->
<!--          <span class="name" v-b-tooltip.hover="'이메일 주소: ' + reply.email">{{ reply.userName }}</span>-->
          <div class="info-row">
            <span class="email">({{ reply.email }})</span>
          </div>
          <span class="createdAt">{{ formatDate(reply.createdAt) }}</span>
        </div>
      </div>
    </div>
    <div class="text reply-content">{{ reply.content }}</div>
    <div class="footer">
<!--      <div class="stats">-->
<!--        <span class="stat"><strong>2</strong> Likes</span>-->
<!--        <span class="stat"><strong>1</strong> Dislikes </span>-->
<!--      </div>-->
      <div class="actions">
<!--        <button @click="like" class="like-btn">-->
<!--          <i class="fa fa-heart"></i>-->
<!--        </button>-->
<!--        <button @click="retweet" class="retweet-btn">-->
<!--          <i class="fa fa-retweet"></i>-->
<!--        </button>-->
        <button v-if="isLoggedIn && reply.email === $store.state.account.email" class="btn btn-sm btn-danger" @click="deleteReply(reply.replyNumber, reply.replyType)">
          삭제
        </button>
      </div>
    </div>
  </div>
  <!-- 더 보기 버튼 -->
  <p> <button class="btn btn-sm btn-success" v-if="showMoreButton" @click="showMore">더 보기</button> </p>
  <br>
  <div v-if="isLoggedIn">
    <h5>댓글 작성</h5>
    <form>
<!--      <div class="form-group">-->
<!--        <label for="email"></label>-->
<!--        <input type="userName" class="form-control" id="userName" v-model="$store.state.account.email" disabled>-->
<!--        <input type="email" class="form-control" id="email" v-model="$store.state.account.email" disabled>-->
<!--      </div>-->
      <div class="form-group">
        <label for="content"></label>
        <textarea class="form-control" id="content" rows="5" v-model="newReply.content" placeholder="내용을 입력하세요"></textarea>
      </div>
      <br>
      <button type="submit" class="btn btn-primary" @click.prevent="createReply()">등록</button>
    </form>
  </div>
  <div v-else>
    <a href="#" class="nav-link link-dark px-2">
      <router-link to="/login">로그인</router-link> 후에 댓글을 작성할 수 있습니다.
    </a>
  </div>
</template>

<script>
import axios from "../common/AxiosInterceptors";
import store from "@/scripts/store";
import { BTooltip } from 'bootstrap-vue-3'

export default {

  props: {
    item: {
      type: String,
      required: true
    },
    type : {
      type: String,
      required: true
    },
  },
  data() {
    return {
      replies: [],
      numDisplayed: 5,
      fields: [
        //{key: 'replyNumber', label: '', sortable: false},
        // {key: 'email', label: '이메일', sortable: true},
        {key: 'userName', label: '홀더', sortable: false},
        {key: 'content', label: '리뷰내용'},
        // {key: 'like', label: '', sortable: false},
        // {key: 'dislike', label: '', sortable: false},
        {key: 'createdAt', label: '입력시간', sortable: false, thStyle: { width: '100px' } },
        // {key: 'updatedAt', label: '수정일자', sortable: true},
        {key: 'delete', label: '비고', sortable: false, thStyle: { width: '70px' }  }
      ],
      sortBy: 'replyNumber',
      sortDesc: false,
      shopId: '',
      replyType : '',
      newReply: {
        shopId: '',
        replyType: this.type,
        email: '',
        userName: '',
        content: '',
      }
    };
  },
  methods: {
    showEmail(reply) {
      reply.showEmail = true;
    },
    hideEmail(reply) {
      reply.showEmail = false;
    },
    showMore() {
      // numDisplayed 증가
      this.numDisplayed += 5;
    },
    fetchReplies() {
      //this.shopId = this.$route.params.item; // item props 값을 shopId에 할당
      this.shopId = this.item;
      this.replyType = this.type;

      if( this.shopId ){
         axios.get(`/reply/${this.shopId}/${this.replyType}`)
          .then((response) => {
            this.replies = response.data;
          })
          .catch((error) => {
            //console.log("============= error fetchReplies()");
            console.error(error);
          });
      }
    },
    onRowClicked(item) {
      // 특정 댓글 클릭 시 동작하는 함수
      console.log(item);
    },
    // formatDate(date) {
    //   // 날짜 포맷팅 함수
    //   return new Date(date).toLocaleDateString();
    // },
    formatDate(date) {
      const diff = new Date() - new Date(date);
      const diffMinutes = Math.round(diff / 60000);
      const diffHours = Math.round(diff / 3600000);
      if (diffMinutes < 60) {
        return `${diffMinutes}분 전`;
      } else if (diffHours < 24) {
        return `${diffHours}시간 전`;
      } else {
        // const newDate = new Date(date);
        //return newDate.toLocaleDateString("ko-KR");
        // return new Date(date).toLocaleDateString();
        return date.substring(0, date.lastIndexOf("."));
      }
    },
    deleteReply(replyNumber, replyType) {
      console.log("replyNumber->" + replyNumber);
      console.log("replyType->" + replyType);
      if (confirm('정말로 삭제하시겠습니까?')) {
        axios.get(`/reply/delete/${replyNumber}/${replyType}`)
            .then((response) => {
              console.log(response);
              alert('삭제 되었습니다.');
              //this.$router.go(); // 페이지 새로고침
              this.refreshPage();
            })
            .catch((error) => {
              console.error(error);
            });
      }
    },
    async createReply() {
      this.newReply.email = this.$store.state.account.email; // $store.state.account.email 값을 newReply.email에 대입
      this.newReply.shopId = this.item;
      this.newReply.replyType = this.replyType;

      try{
        const response = await axios.post('/reply/', this.newReply);
        console.log(response);
        alert('댓글이 등록 되었습니다.');
        this.refreshPage();
      }catch(error){
        console.log(error);
      }

      // axios.post('/reply/', this.newReply)
      //     .then((response) => {
      //       console.log(response);
      //       alert('댓글이 등록 되었습니다.');
      //       //this.$router.go(); // 페이지 새로고침

      //     })
      //     .catch((error) => {
      //       console.error(error);
      //     });
    },
    setDefaultProfileImg(event) {
      this.thumbnailProfile = 'https://github.com/mdo.png';
    },
    //페이지 새로고침 메서드
    refreshPage() {
      window.location.reload();
    },
  },
  computed: {
    limitedReplies() {
      // 최근 numDisplayed개의 댓글만 반환
      return this.replies.slice(0, this.numDisplayed);
    },
    showMoreButton() {
      // 더 보기 버튼이 필요한지 확인
      return this.replies.length > this.numDisplayed;
    },
    isLoggedIn() {
      return !!store.state.account.email;
    }
  },
  created() {
    // replies 불러오기
    this.fetchReplies();
    //this.fetchReplies();
  },
  watch: {
    item(newVal, oldVal) {
      this.fetchReplies();
    }
  },
};
</script>


<style scoped>
.tweet {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s;
  background-color: #ffffff;
}

.tweet-inner {
  display: flex;
}


.tweet:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: -50px;
  margin-right: 15px;
}

.content {
  flex: 1;
}

.header {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 1rem;
  font-weight: bold;
}

.email {
  font-size: 0.8rem;
  margin-left: 5px;
  color: #999;
}

.dot {
  margin: 0 5px;
  font-size: 1.2rem;
  color: #999;
}

.createdAt {
  font-size: 0.8rem;
  color: #999;
}

.text {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.stat {
  font-size: 0.8rem;
  margin-right: 10px;
  color: #999;
}

.like-btn,
.retweet-btn {
  background-color: #fff;
  border: none;
  padding: 5px;
  margin-right: 10px;
  font-size: 1.2rem;
  color: #999;
  cursor: pointer;
  transition: color 0.2s;
}

.info-row {
  display: block;
}

.email {
  display: none;
}

.name:hover + .email,
.email:hover {
  display: inline-block;
}
</style>