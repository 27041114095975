<template>
<spinner :loading="loading" />

  <div id="myEditor" class="w-70 m-auto">
    <span style="text-align: center;">
      <h1>상점 등록</h1>
    </span>
    <!-- import EditorFileds -->
    <EditorFileds ref="getChildData" @setChildObject="onChildData" :formObject="formObject" />

    <ckeditor :editor="editor" v-model="editorData" @ready="onReady" :config="config" name="shopCK"></ckeditor>
    <div style="text-align: center;">
      <b-button @click.once="onSubmit" >저장하기</b-button>
    </div>
  </div>
</template>


<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';
import UploadAdapter from '../common/UploadAdapter';
import axios from "../common/AxiosInterceptors";
import useAxiosPrivate from "../common/AxiosPrivate";
import EditorFileds from "../components/editorFileds.vue";
import router from "../router/index";
import { mapState } from 'vuex'

const sampleText = '<p>1. 특전 : <br>2. 이용조건 : <br> 3. 사용가능 횟수 : </p>';

export default {
  name: 'app',
  setup() {
  },

  components: { EditorFileds,  'ckeditor': CKEditor.component },

  data() {
    return {
      value: [ ],
      editor: ClassicEditor,
      editorData: sampleText,
      config: {
        //language: 'ko',
        toolbar: {
          items: [
            'heading',
            '|',
            'fontFamily',
            'fontColor',
            'fontSize',
            '|',
            'bold',
            'italic',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            //'todoList',
            'link',
            'blockQuote',
            'imageUpload',
            'insertTable',
            '|',
            'undo',
            'redo'
          ]
        },
        image: {
          resize: true,
          toolbar: ['imageStyle:alignLeft', 'imageStyle:alignRight', 'imageStyle:inline', 'imageStyle:side']
        },
      },
      extraPlugins: [UploadAdapter],
      // file upload
      editorInfo: {
        isThumbUploaded: false
      },
      hashValue: []
      ,
      // form upload
      formObject: {
        shopId: ''
        , email: ''
        , shopCategory: ''
        , shopName: ''
        , shopContent: ''
        , shopLocation: ''
        , shopAddress: ''
        , urlHomepage: ''
        , urlMap: ''
        , thumbnailMain: ''
        , thumbnailSub: ''
        , itemName: ''
        , itemPrice: ''
        , itemDiscount: ''
        , shopHashtag: ''
        , inppId: ''
        , shopNft1: ''
        , shopNft2: ''
        , shopNft3: ''
        , shopNft4: ''
        , shopNft5: ''
      },
      show: true
      ,isLoading: true
      //, thumbSrc: process.env.VUE_APP_BASE_URL + '/images/logo.png'
      // end of form upload

    }
  },
  computed: {
    data() {
      return {
        isLoading: true
      }
    },
    state() {
      console.log(this)
      return this.formObject.shopName.length > 0
    },
    invalidFeedback() {
      if (this.formObject.shopName.length > 0) {
        return "감사합니다"
      } else {
        return "점포명을 입력해주세요"
      }
    },
    ...mapState(['loading'])
  }
  ,
  methods: {
    onReady(editor) {
      // UploadAdapter 생성자호출
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader)
      }
    },
    onSubmit(event) {
      event.preventDefault();
      const url = '/data/shopInfo';
      const prefix = '#';

      // setData from Child
      this.$refs.getChildData.getChildObject();
      //console.log( this.EditorFields.localFormObject );

      console.log(this.hashValue);
      console.log(this.hashValue.length);
      // // ckeditor textData, hashtag
      this.formObject.shopContent = this.editorData;
      if( this.hashValue.length === 1 ){
        this.formObject.shopHashtag = prefix + this.hashValue.join("");
      }else if( this.hashValue.length > 1 ){
        let tempArr = this.hashValue.map(item => prefix + item);
        this.formObject.shopHashtag = tempArr.join(", ");
      }

      console.log('-====final ');
      console.log(this.formObject);

      if (this.validateInput()) {
        useAxiosPrivate.post(url, this.formObject, { headers: { "Content-Type": 'application/json' } })
          .then((response) => {
            alert('저장 되었습니다.');
            console.log(response);
            // home으로 이동
            router.push({ path: '/' });
          }).catch((error) => {
            alert('저장 중 오류가 발생했습니다. 관리자에게 문의해주세요')
            console.error(error);
            // home으로 이동
            router.push('/');
          })
      }else{
        alert('필수값이 비어있습니다.');
      }

    },
    /**************************************************
    * 사용자 이벤트 함수 ( 버튼클릭, 탭 이동 등)
    *************************************************/
    onFindAddr() {
      this.$refs.addressVue.isOpenEvent();
    },
    // set address
    handleAddr(addrObj) {
      console.log(addrObj);
      this.formObject.shopAddress = `${addrObj.roadAddress} ${addrObj.buildingName}`;
    },
    // validate form data
    validateInput() {

      const tartgetArr = ['shopName', 'shopCategory', 'shopContent', 'shopAddress']

      for (let key in this.formObject) {
        if (tartgetArr.includes(key)) {
          if (!this.isValid(this.formObject[key])) {
            console.log(`no value for ${key}`);
            return false;
          }
        }
      }

      // All is good
      return true;
    },

    isValid(value) {
      return value.length > 0;
    },
    // set childData
    onChildData(Obj) {
      console.log(Obj.formObject);
      this.formObject = Obj.formObject;
      this.hashValue = Obj.hashValue;
    }


  }// end of method

}
</script>

<style>
#myEditor {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  width: 80%;
  color: #2c3e50;
  padding-left: 0%;
}

.ck-editor__editable_inline {
  min-height: 200px
}

.uploadimg {
  position: center;
  /*width: 100%;*/
  height: 100px;

  object-fit: cover;
  object-position: 85%;
}

@media only screen and (max-width: 600px) {
  #myEditor {
    width: 95%;
  }
}
</style>